import React from 'react';

// Import CSS & assets
import ico_map_layers from '../../../../assets/ico/ico_map_layers.svg';
import ico_risk_pipe from '../../../../assets/risks/ico_risk_pipe.svg';
import ico_risk_pollution from '../../../../assets/risks/ico_risk_pollution.svg';
import ico_risk_earthquake from '../../../../assets/risks/ico_risk_earthquake.svg';
import ico_risk_clay from '../../../../assets/risks/ico_risk_clay.svg';
import './Risks.css';

const Risks = ({ capacity, mapLayers, setMapLayers }) => {

    // LAYER : Toggle
    const layer_Toggle = (layer) => {
        console.log("TOGGLE LAYER", layer);
        // Get state
        var mapLayersState = mapLayers;
        // Update state
        mapLayersState[layer]["isVisible"] = !mapLayersState[layer]["isVisible"];
        // Update original
        setMapLayers({ ...mapLayersState });
    }

    if (capacity?.risks?.basias) {

        return (
            <>
                {/* NATURELS */}
                {(capacity?.risks?.natural && capacity.risks.natural !== "error") &&
                    <>
                        <div className="app__sectiondata_widget_title">Risques naturels et industriels (à l'échelle de la commune)</div>
                        <div className="app__sectiondata__widget">
                            <div className="app__sectiondata_widget_title">Risques naturels</div>
                            {(capacity.risks.natural.length === 0 || (capacity.risks.natural.length === 1 && capacity.risks.natural[0].code === "1300000")) &&
                                <div className="app__sectiondata_widget_value_text_xsmall">Aucun risque naturel répertorié sur la commune</div>
                            }
                            {capacity.risks.natural.map((item, index) => {
                                if (item.code === "1300000") {
                                    return
                                }
                                return (
                                    <div className="app__sectiondata_list_item" key={index}>
                                        {index > 0 &&
                                            <div className="app__sectiondata_list_line_separator"></div>
                                        }
                                        <div className="app__sectiondata_list_item_body">
                                            <div style={{ display: 'flex', gap: 14, alignContent: 'center' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}><img src={item.icon} alt="" style={{ height: 30 }} /></div>
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                    <div className="app__risk_value_main">{item.libelle}</div>
                                                    {item.detail.map((iteam_detail, index) => {
                                                        return (
                                                            <div className="app__risk_value_second" key={"detail_" + index}>{iteam_detail.libelle}</div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="app__sectiondata_widget_spacer_10"></div>
                    </>
                }
                {/* INDUSTRIELS */}
                {(capacity?.risks?.industrial && capacity.risks.industrial !== "error") &&
                    <>
                        <div className="app__sectiondata__widget">
                            <div className="app__sectiondata_widget_title">Risques industriels</div>
                            {capacity.risks.industrial.length === 0 &&
                                <div className="app__sectiondata_widget_value_text_xsmall">Aucun risque industriel répertorié sur la commune</div>
                            }
                            {capacity.risks.industrial.map((item, index) => {
                                return (
                                    <div className="app__sectiondata_list_item" key={index}>
                                        {index > 0 &&
                                            <div className="app__sectiondata_list_line_separator"></div>
                                        }
                                        <div className="app__sectiondata_list_item_body">
                                            <div style={{ display: 'flex', gap: 14, alignContent: 'center' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}><img src={item.icon} alt="" style={{ height: 30 }} /></div>
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                    <div className="app__risk_value_main">{item.libelle}</div>
                                                    {item.detail.map((iteam_detail, index) => {
                                                        return (
                                                            <div className="app__risk_value_second" key={"detail_" + index}>{iteam_detail.libelle}</div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="app__sectiondata_widget_spacer_10"></div>
                        <div className="app__sectiondata_widget_info_container">
                            <div className="app__sectiondata_widget_info_text">
                                Source : Géorisques
                            </div>
                            <div className="app__sectiondata_widget_info_icon">i</div>
                        </div>
                        <div className="app__sectiondata_widget_spacer"></div>
                    </>
                }
                {/* ZONE SISMIQUE */}
                {(capacity?.risks?.earthquake && capacity.risks.earthquake !== "error") &&
                    <>
                        <div id="section_2_earthquake" className="app__sectiondata_widget_title">Risque sismique (à l'échelle de la commune)</div>
                        <div className="app__sectiondata__widget">
                            <div className="app__sectiondata_widget_title">Zone de sismicité</div>
                            <div className="app__sectiondata_widget_layout_B" style={{ gap: 10 }}>
                                <div style={{ display: 'flex', gap: 14 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}><img src={ico_risk_earthquake} alt="" style={{ height: 40 }} /></div>
                                    <div className="app__sectiondata_widget_value_container">
                                        <div className="app__sectiondata_widget_value_text_small">Zone {capacity?.risks?.earthquake[0].code}</div>
                                        <div className="app__sectiondata_widget_value_legend">Risque sismique : {capacity?.risks?.earthquake[0].label}</div>
                                    </div>
                                </div>
                                <div className="app__sectiondata_widget_layout_A_auto_flex1 app__sectiondata_widget_bottom">
                                    <div className="app__sectiondata_widget_scale">
                                        <div className={`app__sectiondata_widget_scale_item ${capacity?.risks?.earthquake[0].code === 1 ? "app__sectiondata_widget_scale_item_main" : ""}`} style={{ backgroundColor: '#F0F0F0' }}>
                                            <div className="app__sectiondata_widget_scale_item_title">Zone 1</div>
                                            <div className="app__sectiondata_widget_scale_item_description">Très faible</div>
                                        </div>
                                        <div className={`app__sectiondata_widget_scale_item ${capacity?.risks?.earthquake[0].code === 2 ? "app__sectiondata_widget_scale_item_main" : ""}`} style={{ backgroundColor: '#FBD95E' }}>
                                            <div className="app__sectiondata_widget_scale_item_title">Zone 2</div>
                                            <div className="app__sectiondata_widget_scale_item_description">Faible</div>
                                        </div>
                                        <div className={`app__sectiondata_widget_scale_item ${capacity?.risks?.earthquake[0].code === 3 ? "app__sectiondata_widget_scale_item_main" : ""}`} style={{ backgroundColor: '#FE942E', color: '#FFF' }}>
                                            <div className="app__sectiondata_widget_scale_item_title">Zone 3</div>
                                            <div className="app__sectiondata_widget_scale_item_description">Modéré</div>
                                        </div>
                                        <div className={`app__sectiondata_widget_scale_item ${capacity?.risks?.earthquake[0].code === 4 ? "app__sectiondata_widget_scale_item_main" : ""}`} style={{ backgroundColor: '#E52E2A', color: '#FFF' }}>
                                            <div className="app__sectiondata_widget_scale_item_title">Zone 4</div>
                                            <div className="app__sectiondata_widget_scale_item_description">Moyen</div>
                                        </div>
                                        <div className={`app__sectiondata_widget_scale_item ${capacity?.risks?.earthquake[0].code === 5 ? "app__sectiondata_widget_scale_item_main" : ""}`} style={{ backgroundColor: '#710100', color: '#FFF' }}>
                                            <div className="app__sectiondata_widget_scale_item_title">Zone 5</div>
                                            <div className="app__sectiondata_widget_scale_item_description">Fort</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="app__sectiondata_widget_spacer_10"></div>
                        <div className="app__sectiondata_widget_info_container">
                            <div className="app__sectiondata_widget_info_text">
                                Source : Géorisques
                            </div>
                            <div className="app__sectiondata_widget_info_icon">i</div>
                        </div>
                        <div className="app__sectiondata_widget_spacer"></div>
                    </>
                }
                {/* ARGILES */}
                {(capacity?.risks?.clay && capacity.risks.clay !== "error") &&
                    <>
                        <div id="section_2_clay" className="app__sectiondata_widget_title">Risque lié au retrait-gonflement des sols argileux (à l'échelle du terrain)</div>
                        <div className="app__sectiondata__widget">
                            <div className="app__sectiondata_widget_title">Zone d'aléa de retrait-gonflement des sols argileux</div>
                            <div className="app__sectiondata_widget_layout_B" style={{ gap: 10 }}>
                                <div style={{ display: 'flex', gap: 14 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}><img src={ico_risk_clay} alt="" style={{ height: 40 }} /></div>
                                    <div className="app__sectiondata_widget_value_container">
                                        <div className="app__sectiondata_widget_value_text_small">Niveau {capacity?.risks?.clay[0].code}</div>
                                        <div className="app__sectiondata_widget_value_legend">Aléa : {capacity?.risks?.clay[0].label}</div>
                                    </div>
                                </div>
                                <div className="app__sectiondata_widget_layout_A_auto_flex1 app__sectiondata_widget_bottom">
                                    <div className="app__sectiondata_widget_scale">
                                        <div className={`app__sectiondata_widget_scale_item ${capacity?.risks?.clay[0].code === 0 ? "app__sectiondata_widget_scale_item_main" : ""}`} style={{ backgroundColor: '#F0F0F0' }}>
                                            <div className="app__sectiondata_widget_scale_item_title">Niveau 0</div>
                                            <div className="app__sectiondata_widget_scale_item_description">A priori nul</div>
                                        </div>
                                        <div className={`app__sectiondata_widget_scale_item ${capacity?.risks?.clay[0].code === 1 ? "app__sectiondata_widget_scale_item_main" : ""}`} style={{ backgroundColor: '#FBD95E' }}>
                                            <div className="app__sectiondata_widget_scale_item_title">Niveau 1</div>
                                            <div className="app__sectiondata_widget_scale_item_description">Faible</div>
                                        </div>
                                        <div className={`app__sectiondata_widget_scale_item ${capacity?.risks?.clay[0].code === 2 ? "app__sectiondata_widget_scale_item_main" : ""}`} style={{ backgroundColor: '#FE942E', color: '#FFF' }}>
                                            <div className="app__sectiondata_widget_scale_item_title">Niveau 2</div>
                                            <div className="app__sectiondata_widget_scale_item_description">Moyen</div>
                                        </div>
                                        <div className={`app__sectiondata_widget_scale_item ${capacity?.risks?.clay[0].code === 3 ? "app__sectiondata_widget_scale_item_main" : ""}`} style={{ backgroundColor: '#E52E2A', color: '#FFF' }}>
                                            <div className="app__sectiondata_widget_scale_item_title">Niveau 3</div>
                                            <div className="app__sectiondata_widget_scale_item_description">Fort</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="app__sectiondata_widget_spacer_10"></div>
                        <div className="app__sectiondata_widget_info_container">
                            <div className="app__sectiondata_widget_info_text">
                                Source : Géorisques et BRGM
                            </div>
                            <div className="app__sectiondata_widget_info_icon">i</div>
                        </div>
                        <div className="app__sectiondata_widget_spacer"></div>
                    </>
                }
                {/* CANALISATION */}
                {(capacity?.risks?.pipe && capacity.risks.pipe !== "error") &&
                    <>
                        {capacity.risks.pipe.length === 0 ?
                            <>
                                <div id="section_2_pipes" className="app__sectiondata_widget_title">Risque de présence de canalisations de matières dangereuses (dans un rayon de 1000m autour du terrain)</div>
                                <div className="app__sectiondata_widget_value_legend">Aucune canalisation de matières dangereuses recensée dans un rayon de 1000 m</div>
                            </>
                            :
                            <>
                                <div className="app__sectiondata_widget_title_container">
                                    <div id="section_2_pipes" className="app__sectiondata_widget_title">Risque de présence de canalisations de matières dangereuses (dans un rayon de 1000m autour du terrain)</div>
                                    <div className="app__sectiondata_widget_layer">
                                        <img src={ico_map_layers} alt="ico_map_layers" />
                                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_risk_pipe" checked={mapLayers.risks_pipes.isVisible} onChange={() => { layer_Toggle("risks_pipes") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_risk_pipe">Toggle</label></div>
                                    </div>
                                </div>
                                <div className="app__sectiondata__widget">
                                    <div className="app__sectiondata_widget_title">Liste des canalisations recensées</div>
                                    <div className="app__risks_list_title_container">
                                        <div className="app__risks_title app__risks_list_col_0"></div>
                                        <div className="app__risks_title app__risks_list_col_1">Type de matière</div>
                                        <div className="app__risks_title app__risks_list_col_2">Opérateur</div>
                                        <div className="app__risks_title app__risks_list_col_3">Longueur</div>
                                        {/* <div className="app__risks_title app__risks_list_col_4">Distance</div> */}
                                    </div>
                                    {capacity.risks.pipe.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                {index > 0 &&
                                                    <div className="app__sectiondata_list_line_separator"></div>
                                                }
                                                <div className="app__sectiondata_list_item_body">
                                                    <div style={{ display: 'flex', gap: 14, alignContent: 'center' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}><img src={ico_risk_pipe} alt="" style={{ height: 30 }} /></div>
                                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: '1' }}>
                                                            <div className="app__risks_list_item_container">
                                                                <div className="app__risk_value_main app__risks_list_col_1">{item.type}</div>
                                                                <div className="app__risk_value_main app__risks_list_col_2">{item.operator}</div>
                                                                <div className="app__risk_value_second app__risks_list_col_3">{item.length.toFixed(2)} m</div>
                                                                {/* <div className="app__risk_value_second app__risks_list_col_4">Distance</div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                        }
                        <div className="app__sectiondata_widget_spacer_10"></div>
                        <div className="app__sectiondata_widget_info_container">
                            <div className="app__sectiondata_widget_info_text">
                                Source : Géorisques et BRGM
                            </div>
                            <div className="app__sectiondata_widget_info_icon">i</div>
                        </div>
                        <div className="app__sectiondata_widget_spacer"></div>
                    </>
                }
                {/* BASIAS */}
                {(capacity?.risks?.basias && capacity.risks.basias !== "error") &&
                    <>
                        {capacity.risks.basias.length === 0 ?
                            <>
                                <div id="section_2_pollution" className="app__sectiondata_widget_title">Risque de pollution du sol (dans un rayon de 1000m autour du terrain)</div>
                                <div className="app__sectiondata_widget_value_legend">Aucune site pollué recensé dans un rayon de 1000 m</div>
                            </>
                            :
                            <>
                                <div className="app__sectiondata_widget_title_container">
                                    <div id="section_2_pollution" className="app__sectiondata_widget_title">Risque de pollution du sol (dans un rayon de 1000m autour du terrain)</div>
                                    <div className="app__sectiondata_widget_layer">
                                        <img src={ico_map_layers} alt="ico_map_layers" />
                                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_risk_basias" checked={mapLayers.risks_basias.isVisible} onChange={() => { layer_Toggle("risks_basias") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_risk_basias">Toggle</label></div>
                                    </div>
                                </div>
                                <div className="app__sectiondata__widget">
                                    <div className="app__sectiondata_widget_title">Liste des sites pollués recensés</div>
                                    <div className="app__risks_list_title_container">
                                        <div className="app__risks_title app__risks_list_col_0"></div>
                                        <div className="app__risks_title app__risks_list_col_1">Nom du site</div>
                                        <div className="app__risks_title app__risks_list_col_5">Superficie</div>
                                        {/* <div className="app__risks_title app__risks_list_col_5">Distance</div> */}
                                        <div className="app__risks_title app__risks_list_col_5">Fiche détaillée</div>
                                    </div>
                                    {capacity.risks.basias.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                {index > 0 &&
                                                    <div className="app__sectiondata_list_line_separator"></div>
                                                }
                                                <div className="app__sectiondata_list_item_body">
                                                    <div style={{ display: 'flex', gap: 14, alignContent: 'center' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}><img src={ico_risk_pollution} alt="" style={{ height: 30 }} /></div>
                                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: '1' }}>
                                                            <div className="app__risks_list_item_container">
                                                                <div className="app__risk_value_main app__risks_list_col_1">{item.nom === null ? "Non communiqué" : item.nom}</div>
                                                                <div className="app__risk_value_second app__risks_list_col_5">{item.superficie.toFixed(0)} m²</div>
                                                                {/* <div className="app__risk_value_second app__risks_list_col_5">Distance</div> */}
                                                                <div className="app__risk_value_second app__risks_list_col_5">
                                                                    <div className="app__risk_data_button"><a href={item.fiche_risque} target="_blanck">Géorisque</a></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                        }
                        <div className="app__sectiondata_widget_spacer_10"></div>
                        <div className="app__sectiondata_widget_info_container">
                            <div className="app__sectiondata_widget_info_text">
                                Source : Géorisques et InfoSols
                            </div>
                            <div className="app__sectiondata_widget_info_icon">i</div>
                        </div>
                    </>
                }
            </>
        )
    }
    else {
        return (
            <div className="app__sectiondata_loading_container">
                <div className="app__sectiondata_loading"></div>
                <div className="app__sectiondata_loading_text">Nous analysons les risques</div>
                <div className="app__sectiondata_loading_text_small_container">
                    <div className="app__sectiondata_noaccess_text_small">• Risques naturels et industriels</div>
                    <div className="app__sectiondata_noaccess_text_small">• Sismicité</div>
                    <div className="app__sectiondata_noaccess_text_small">• Sols argileux</div>
                    <div className="app__sectiondata_noaccess_text_small">• Canalisations de matière dangereuse</div>
                    <div className="app__sectiondata_noaccess_text_small">• Pollution du sol</div>
                </div>
            </div>
        )
    }
};

export default Risks;