import * as turf from '@turf/turf';
import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import mapboxgl from 'mapbox-gl';

import tree_branched from '../../../assets/3D/tree_branched.gltf';
import tree_oval from '../../../assets/3D/tree_oval.gltf';

const tree_original_height = {
    tree_oval: {
        height: 8.4,
    },
    tree_branched: {
        height: 8.6,
    },
}

const sources_ids = ['sourceTrees'];
const layers_ids = ['layerTrees_Points', 'layerTrees_3D'];

export const get_ids = () => {
    var sources = [];
    sources_ids.forEach(source_id => {
        sources.push({ id: source_id });
    })

    var layers = [];
    layers_ids.forEach(layer_id => {
        layers.push({ id: layer_id });
    })

    var sources_layers = {
        sources: sources,
        layers: layers
    }

    return sources_layers
}

export const get_data = (capacity) => {

    var map_data = {
        sources: [],
        layers: []
    }

    if (!capacity?.landBase?.trees?.items || !capacity.landBase.trees.items.length > 0) {
        return map_data
    }

    // Preset layer data
    var layer_data = {
        type: 'FeatureCollection',
        features: [],
    };

    // Add points
    for (var i = 0; i < capacity.landBase.trees.items.length; i++) {
        if (capacity.landBase.trees.items[i]?.coords && capacity.landBase.trees.items[i].coords.length === 2) {
            layer_data.features.push({
                type: "Feature",
                properties: capacity.landBase.trees.items[i]?.properties,
                geometry: {
                    type: "Point",
                    coordinates: capacity.landBase.trees.items[i].coords,
                }
            });
        }
    }
    if (!layer_data.features.length > 0) {
        return map_data
    }

    // Create sources
    map_data.sources.push(
        {
            id: sources_ids[0],
            value: {
                type: 'geojson',
                data: layer_data
            }
        }
    )

    // Create layers
    map_data.layers.push(
        {
            id: layers_ids[0],
            type: 'circle',
            source: sources_ids[0],
            paint: {
                'circle-radius': 4,
                'circle-color': '#062134'
            },
            filter: ['in', '$type', 'Point']
        }
    )



    // TEST 3D
    // Set point 0
    var point0 = capacity.landBase.union.bbox.origin;
    // Georeferencing
    var modelOrigin = point0;
    var modelAltitude = 0;
    var modelRotate = [0, 0, 0];
    var modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
        modelOrigin,
        modelAltitude
    );
    // Set position, rotation and scale
    var modelTransform = {
        translateX: modelAsMercatorCoordinate.x,
        translateY: modelAsMercatorCoordinate.y,
        translateZ: modelAsMercatorCoordinate.z,
        rotateX: modelRotate[0],
        rotateY: modelRotate[1],
        rotateZ: modelRotate[2],
        scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits()
    };
    // Set THREE
    var customLayer = {
        id: layers_ids[1],
        type: 'custom',
        source: sources_ids[0],
        renderingMode: '3d',
        onAdd: function (map, gl) {
            this.camera = new THREE.Camera();
            this.scene = new THREE.Scene();
            // Create lights
            var directionalLight1 = new THREE.DirectionalLight(0xffffff, 0.5);
            // directionalLight1.position.set(-100, 20, -100).normalize();
            directionalLight1.position.x = -10;
            directionalLight1.position.y = -10;
            directionalLight1.position.z = 20;
            this.scene.add(directionalLight1);

            var directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.3);
            // directionalLight1.position.set(-100, 20, -100).normalize();
            directionalLight2.position.x = 10;
            directionalLight2.position.y = 10;
            directionalLight2.position.z = 20;
            this.scene.add(directionalLight2);

            var ambientLight = new THREE.AmbientLight(0xffffff, 0.7);
            this.scene.add(ambientLight);

            // Get local coordinates
            var local_points = [];
            layer_data.features.forEach(point => {
                if (point?.geometry?.coordinates && point?.geometry?.coordinates.length === 2) {
                    var coords_global = point.geometry.coordinates;
                    var deltaX = (turf.distance(turf.point(coords_global), turf.point([point0[0], coords_global[1]])) * 1000);
                    if (coords_global[0] < point0[0]) {
                        deltaX = -deltaX;
                    }
                    var deltaY = (turf.distance(turf.point(coords_global), turf.point([coords_global[0], point0[1]])) * 1000);
                    if (coords_global[1] < point0[1]) {
                        deltaY = -deltaY;
                    }
                    local_points.push([deltaX, deltaY]);
                }
            })
            console.log("local_points", local_points);

            // Define materials
            const leafMaterial = new THREE.MeshLambertMaterial({ color: '#80AB57', transparent: true, opacity: 1, side: THREE.DoubleSide, });
            const trunkMaterial = new THREE.MeshLambertMaterial({ color: '#592E35', transparent: true, opacity: 1, side: THREE.DoubleSide, });
            const blankMaterial = new THREE.MeshLambertMaterial({ color: '#F1F1F1', transparent: true, opacity: 1, side: THREE.DoubleSide, });

            // Load model
            const loader = new GLTFLoader();
            var scale_factor = 5.3 / 8.6;
            loader.load(
                // tree_oval,
                tree_branched,
                (gltf) => {
                    console.log("SCENE", gltf.scene);
                    console.log("CHILDREN", gltf.scene.children[0]);
                    // Rotate 90°
                    gltf.scene.rotation.x = 1.5708;
                    // Translate
                    gltf.scene.position.x = 10;
                    gltf.scene.position.y = 10;
                    // gltf.scene.position.x = local_points[0][0];
                    // gltf.scene.position.y = local_points[0][1];
                    // Scale
                    gltf.scene.scale.set(scale_factor, scale_factor, scale_factor);
                    // Set materials
                    gltf.scene.children[0].material = leafMaterial;
                    gltf.scene.children[1].material = trunkMaterial;
                    // Add to scene
                    this.scene.add(gltf.scene);
                }
            );


            // Add map
            this.map = map;
            // use the Mapbox GL JS map canvas for three.js
            this.renderer = new THREE.WebGLRenderer({
                canvas: map.getCanvas(),
                context: gl,
                antialias: true
            });
            // this.renderer.shadowMap.enabled = true;
            // this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
            this.renderer.autoClear = false;




        },
        render: function (gl, matrix) {
            var rotationX = new THREE.Matrix4().makeRotationAxis(
                new THREE.Vector3(1, 0, 0),
                modelTransform.rotateX
            );
            var rotationY = new THREE.Matrix4().makeRotationAxis(
                new THREE.Vector3(0, 1, 0),
                modelTransform.rotateY
            );
            var rotationZ = new THREE.Matrix4().makeRotationAxis(
                new THREE.Vector3(0, 0, 1),
                modelTransform.rotateZ
            );
            var m = new THREE.Matrix4().fromArray(matrix);
            var l = new THREE.Matrix4()
                .makeTranslation(
                    modelTransform.translateX,
                    modelTransform.translateY,
                    modelTransform.translateZ
                )
                .scale(
                    new THREE.Vector3(
                        modelTransform.scale,
                        -modelTransform.scale,
                        modelTransform.scale
                    )
                )
                .multiply(rotationX)
                .multiply(rotationY)
                .multiply(rotationZ);

            this.camera.projectionMatrix = m.multiply(l);
            this.renderer.resetState();
            this.renderer.render(this.scene, this.camera);
            // this.map.triggerRepaint();
        }
    };
    map_data.layers.push(customLayer);





    // Return
    console.log("map_data", map_data);
    return map_data

}

