import React, { useState } from 'react';

// Import CSS & Assets
import './LandsValidation.css';

const LandsValidation = ({ capacity, setCapacity, isMapExpanded }) => {

    const [isExpanded, setIsExpanded] = useState(false);

    const handleSelectionValidation = () => {
        var modif_value = "";
        if (capacity.landBase.lands.length === 1) {
            modif_value = "la parcelle ";
        }
        else {
            modif_value = "les parcelles ";
        }
        for (var i = 0; i < capacity.landBase.lands.length; i++) {
            if (i === 0) {
                modif_value = modif_value + " ";
            }
            else if (i === capacity.landBase.lands.length - 1) {
                modif_value = modif_value + " et ";
            }
            else {
                modif_value = modif_value + ", ";
            }
            modif_value = modif_value + capacity.landBase.lands[i].properties.section + "-" + capacity.landBase.lands[i].properties.numero;
        }
        var landBase = capacity.landBase;
        landBase.location = "fetching";
        
        var toSaveData = { ...capacity.toSave };
        if (!toSaveData?.keys) { toSaveData.keys = [] }
        toSaveData.keys.push("step");
        toSaveData.keys.push("city");
        toSaveData.keys.push("landBase");
        toSaveData.autoSave = true;
        toSaveData.action = "Définition du terrain par";
        toSaveData.value = modif_value;
        setCapacity({ ...capacity, step: 1, landBase: landBase, toSave: toSaveData });
    }

    return (
        <>
            {(capacity?.step === 0 && capacity?.landBase?.lands && capacity.landBase.lands.length > 0) &&
                <>
                    <div className={`app__landsvalidation_container ${(window.innerWidth > 550 && isMapExpanded === false) ? "app__landsvalidation_container_extanded" : ""}`}>
                        {isExpanded &&
                            <div className="app__landsvalidation_popup_container scale-up-bottom">
                                <div className="app__landsvalidation_popup_title">Voulez-vous vraiment valider cette sélection de parcelles ?</div>
                                <div className="app__landsvalidation_popup_description">Une fois la sélection validée, il n'est plus possible de la modifier dans cette étude capacitaire.</div>
                                <div className="app__landsvalidation_popup_button_container">
                                    <div className="app__landsvalidation_popup_button" onClick={() => { handleSelectionValidation() }}>Valider la sélection</div>
                                    <div className="app__landsvalidation_popup_button_second" onClick={() => { setIsExpanded(false) }}>Modifier la sélection</div>
                                </div>
                            </div>
                        }
                        {(capacity?.landBase?.union?.geometry?.geometry?.type && capacity.landBase.union.geometry.geometry.type === "MultiPolygon") &&
                            <div className="app__landsvalidation_error">Les parcelles doivent être adjacentes</div>
                        }
                        {(capacity?.landBase?.union?.geometry?.geometry?.type && capacity.landBase.union.geometry.geometry.type === "Polygon") &&
                            <div className="app__landsvalidation_button" onClick={() => { setIsExpanded(true) }}>{capacity.landBase.lands.length === 1 ? "Continuer avec cette parcelle" : "Continuer avec ces " + capacity.landBase.lands.length + " parcelles"}</div>
                        }
                    </div>
                    {isExpanded &&
                        <div className="app__landsvalidation_popup_bg"></div>
                    }
                </>
            }
        </>
    );
};

export default LandsValidation;