import {get_popup_list} from './PopUp_list';

export const get_popup = (userID, teamID) => {

    var popup_list = get_popup_list();
    var current_popup = null;

    // Loop th popup list
    for (var i = 0; i < popup_list.length; i++) {
        // Check if popup is active
        if (popup_list[i]?.isActive === true) {
            // Check if user is matching
            if (popup_list[i]?.audience?.users && popup_list[i].audience.users.length > 0 && (popup_list[i].audience.users.includes("all") || popup_list[i].audience.users.includes(userID))) {
                current_popup = popup_list[i];
                break;
            }
            // Or check if team is matching
            else if (popup_list[i]?.audience?.teams && popup_list[i].audience.teams.length > 0 && (popup_list[i].audience.teams.includes("all") || popup_list[i].audience.teams.includes(teamID))) {
                current_popup = popup_list[i];
                break;
            }
        }
    }

    // Send response
    return current_popup

}