import React, { useEffect, useState } from 'react';
import { Page, Text, View, Image, Link, Document, StyleSheet, Font, pdf } from '@react-pdf/renderer';

import * as other_helpers from '../../../../helpers/Other_helpers';

// Import CSS & assets
import './ExportationAll.css';
import ico_file_pdf from '../../../../assets/ico/ico_file_pdf.svg';
import ico_file_ifc from '../../../../assets/ico/ico_file_ifc.svg';
import close from '../../../../assets/ico/ico_close.svg';
import customFont_reg from '../../../../assets/fonts/Manrope-Regular.ttf';
import customFont_light from '../../../../assets/fonts/Manrope-SemiBold.ttf';
import customFont_bold from '../../../../assets/fonts/Manrope-Regular.ttf';
import customFont_extbold from '../../../../assets/fonts/Manrope-ExtraBold.ttf';
import imgBuilding from '../../../../assets/building_big.png';
import imgLogoText_white from '../../../../assets/pdf/logo_text_white.png';
import imgLogoText_blue from '../../../../assets/pdf/logo_text_blue.png';


var confPDF_var = {
    land: true,
    risks: false,
    context: false,
    rules: false,
    buildable: false,
    combination: false,
    template: "standard",
};

Font.register({
    family: 'Manrope', fonts: [
        { src: customFont_reg }, // font-style: normal, font-weight: normal = 400
        { src: customFont_light, fontWeight: 200 },
        { src: customFont_bold, fontWeight: 600 },
        { src: customFont_extbold, fontWeight: 800 },
    ]
});


const ExportationMenuPDF = ({ capacity, setCapacity, handleToaster, user, setIsExportPDF }) => {

    const [exportingPDF_state, setExportingPDF_state] = useState("ready");
    const [confPDF, setConfPDF] = useState(confPDF_var);
    const switchConfPDF = (key) => {
        // Get state
        var confPDFState = confPDF;
        // Update state
        confPDFState[key] = !confPDFState[key];
        // Update original
        setConfPDF({ ...confPDFState });
        confPDF_var = confPDFState;
    }
    const changeTemplatePDF = (template) => {
        // Get state
        var confPDFState = confPDF;
        // Update state
        confPDFState["template"] = template;
        // Update original
        setConfPDF({ ...confPDFState });
        confPDF_var = confPDFState;
    }
    const exportPDF = async () => {
        console.log("EXPORT PDF", confPDF_var);
        setExportingPDF_state("loading");

        setTimeout(async () => {
            const colors = { main: "#062134", txt: "#FFF" }
            if (confPDF_var.template === "custom" && user?.teams[0]?.image) {
                if (user?.teams[0]?.image) {
                    colors.main = await other_helpers.getColorMain(user?.teams[0]?.image);
                    colors.txt = other_helpers.getColorContrast(colors.main);
                }
            }
            const file = await pdf((generatePDF(confPDF_var, colors))).toBlob();
            const link = document.createElement("a");
            link.href = URL.createObjectURL(file);
            link.download = (capacity.title).replace(/\s+/g, "_").normalize("NFD").replace(/\p{Diacritic}/gu, "") + ".pdf";
            link.click();
            URL.revokeObjectURL(link.href);
            setExportingPDF_state("done");
            setTimeout(() => {
                setExportingPDF_state("ready");
            }, 3000);
        }, 400);
        // Toaster
        handleToaster([{ key: "message", status: "info", type: "static", style: "default", title: "Fonctionnalité bêta", text: "Cette fonctionnalité est en mode bêta, elle évoluera dans les prochaines versions de l'application." }]);
    }
    const styles = StyleSheet.create({
        common_page: {
            fontFamily: 'Manrope',
            margin: '20mm',
            position: 'relative',
        },
        footer: {
            position: 'absolute',
            left: '-20mm',
            top: '269mm',
            width: '210mm',
            height: '8mm',
            backgroundColor: '#062134',
            color: '#FFF',
            fontSize: '3.5mm',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        title_1: {
            fontSize: '6mm',
            fontFamily: 'Manrope',
            fontWeight: 800,
            marginBottom: '10mm',
            color: '#062134',
        },
        title_2: {
            fontSize: '4mm',
            fontFamily: 'Manrope',
            fontWeight: 800,
            marginBottom: '6mm',
            color: '#062134',
        },
        toc_1: {
            fontSize: '4.5mm',
            fontFamily: 'Manrope',
            fontWeight: 600,
            marginBottom: '4mm',
            width: '170mm',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        toc_1_link: {
            color: 'inherit',
            textDecoration: 'none',
        },
        toc_2: {
            fontSize: '4mm',
            fontFamily: 'Manrope',
            marginBottom: '4mm',
            marginLeft: '2mm',
            width: '168mm',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        paragraphe: {
            fontSize: '3.5mm',
            fontFamily: 'Manrope',
            width: '170mm',
        },
        legend: {
            fontSize: '3mm',
            fontFamily: 'Manrope',
            color: 'grey',
            textAlign: 'center',
            marginTop: '0.5mm',
        },
        button: {
            fontSize: '3.5mm',
            fontWeight: 800,
            backgroundColor: '#1ecd97',
            color: '#FFF',
            textDecoration: 'none',
            paddingHorizontal: '4mm',
            paddingVertical: '1mm',
            borderRadius: '10mm',
            textAlign: 'center',
        },
        value_container: {
            display: 'flex',
            flexDirection: 'row',
            gap: '2mm',
            alignItems: 'baseline',
        },
        value_main: {
            fontSize: '6mm',
            fontWeight: 800,
        },
        value_unit: {
            fontSize: '4mm',
            fontWeight: 800,
        },
    });
    const generatePDF = (PDF_config, colors) => {
        console.log("PDF CONFIG", PDF_config, colors);
        var PDF_tempate = {}
        if (PDF_config?.land === true) {
            PDF_tempate.land = [
                {
                    title: "Localisation",
                    page: 3
                },
                {
                    title: "Surface et limites",
                    page: 4
                },
                {
                    title: "Parcelles",
                    page: 4
                },
            ]
        }
        console.log("PDF TEMPLATE", PDF_tempate);
        var coords = capacity?.landBase?.union?.center?.geometry?.coordinates;
        var bearing = 0;
        if (capacity?.landBase?.union?.roads_limits && capacity?.landBase?.union?.roads_limits.length > 0) {
            coords = capacity?.landBase?.union?.roads_limits[0]?.road_point;
            bearing = capacity?.landBase?.union?.roads_limits[0]?.bearing;
        }
        return (
            <Document>
                {/* PAGE DE GARDE */}
                {PDF_config.template === "custom" ?
                    <Page style={{ fontFamily: 'Manrope' }}>
                        {/* HEADER */}
                        <View style={{ position: 'absolute', top: '0mm', left: '0mm', backgroundColor: colors.main, width: '210mm', height: '6mm' }}></View>
                        {user?.teams[0]?.image &&
                            <Image src={user?.teams[0]?.image} style={{ position: 'absolute', top: '50mm', left: '15mm', height: '50mm' }} />
                        }
                        {/* TITLE */}
                        <View style={{ position: 'absolute', top: '140mm', left: '15mm', color: '#000', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '180mm' }}>
                            <Text style={{ fontSize: '11mm', fontWeight: 800, marginBottom: '5mm' }}>{capacity?.title}</Text>
                            {capacity?.description &&
                                <Text style={{ fontSize: '6mm', marginBottom: '5mm' }}>{capacity?.description}</Text>
                            }
                            <Link src={'https://proplab.fr/capastudy/' + capacity._id} style={{ fontSize: '3mm', color: '#000', border: '1px solid #000', borderRadius: '10mm', paddingHorizontal: '3mm', paddingVertical: '1mm', textDecoration: 'none' }}>Ouvrir l'étude sur Proplab.fr</Link>
                        </View>
                        {/* FOOTER */}
                        <View style={{ position: 'absolute', top: '257mm', left: '0mm', backgroundColor: colors.main, width: '210mm', height: '40mm', display: 'flex', alignItems: 'flex-start' }}>
                            <Text style={{ marginLeft: '15mm', marginTop: '11mm', fontSize: '3mm', color: colors.txt, opacity: 0.5, marginRight: '1mm' }}>Etude réalisée par :</Text>
                            <Image src={colors?.txt === "#000" ? imgLogoText_blue : imgLogoText_white} style={{ marginLeft: '15mm', marginTop: '3mm', height: '8mm' }} />
                        </View>
                    </Page>
                    :
                    <Page style={{ backgroundColor: '#040D19', fontFamily: 'Manrope' }}>
                        {/* HEADER */}
                        <Image src={imgBuilding} />
                        <Image src={imgLogoText_white} style={{ position: 'absolute', top: '8mm', left: '15mm', height: '5mm' }} />
                        {/* TITLE */}
                        <View style={{ position: 'absolute', top: '150mm', left: '15mm', color: '#FFF', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '180mm' }}>
                            <Text style={{ fontSize: '11mm', fontWeight: 800, marginBottom: '5mm' }}>{capacity?.title}</Text>
                            {capacity?.description &&
                                <Text style={{ fontSize: '6mm', marginBottom: '5mm' }}>{capacity?.description}</Text>
                            }
                            <Link src={'https://proplab.fr/capastudy/' + capacity._id} style={{ fontSize: '3mm', color: '#FFF', border: '1px solid #FFF', borderRadius: '10mm', paddingHorizontal: '3mm', paddingVertical: '1mm', textDecoration: 'none' }}>Ouvrir l'étude sur Proplab.fr</Link>
                        </View>
                        {/* FOOTER */}
                        <View style={{ position: 'absolute', top: '257mm', left: '0mm', backgroundColor: '#FFF', width: '210mm', height: '40mm', display: 'flex', alignItems: 'flex-start' }}>
                            <View style={{ marginLeft: '15mm', marginTop: '3mm', display: 'flex', flexDirection: 'row' }}>
                                <Text style={{ fontSize: '3mm', color: 'grey', marginRight: '1mm' }}>Etude réalisée pour :</Text>
                                <Text style={{ fontSize: '3mm', color: 'grey', fontWeight: 800 }}>{user?.teams[0]?.name}</Text>
                            </View>
                            {user?.teams[0]?.image &&
                                <Image src={user?.teams[0]?.image} style={{ marginLeft: '15mm', height: '26mm' }} />
                            }
                        </View>
                    </Page>
                }
                {/* SOMMAIRE */}
                <Page style={styles.common_page} wrap>
                    <View style={styles.title_1} bookmark="Sommaire">
                        <Text>Sommaire</Text>
                    </View>
                    {PDF_tempate?.land &&
                        <>
                            <View style={styles.toc_1}>
                                <Link style={styles.toc_1_link} src='#section_land' >1. Données terrain</Link>
                                <Link style={styles.toc_1_link} src='#section_land' >3</Link>
                            </View>
                            {PDF_tempate.land.map((section, section_index) => {
                                return (
                                    <View style={styles.toc_2}>
                                        <Text>1.{section_index + 1} {section.title}</Text>
                                        <Text>{section.page}</Text>
                                    </View>
                                )
                            })}
                        </>
                    }
                    {/* FOOTER */}
                    <View style={[styles.footer, { backgroundColor: colors.main, color: colors.txt }]} fixed>
                        <Text render={({ pageNumber, totalPages }) => (
                            `${pageNumber} / ${totalPages}`
                        )} />
                    </View>
                </Page>
                {/* LAND */}
                {PDF_tempate?.land &&
                    <>
                        <Page style={styles.common_page} wrap>
                            <View id='section_land' style={styles.title_1} bookmark="1. Données terrain">
                                <Text>1. Données terrain</Text>
                            </View>
                            <View style={styles.title_2}>
                                <Text>1.1 Localisation</Text>
                            </View>
                            <View style={styles.paragraphe}>
                                <Text>Adresse globale du terrain : {capacity?.landBase?.location?.properties?.name}, {capacity?.landBase?.location?.properties?.postcode} {capacity?.landBase?.location?.properties?.city}, {capacity?.landBase?.location?.properties?.context}.</Text>
                                {capacity?.landBase?.location?.panoid &&
                                    <>
                                        <Image style={{ width: '170mm', height: '85mm', marginTop: '1mm' }} src={"https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=" + capacity.landBase.location.panoid + "&w=500&h=250&yaw=" + bearing + "&pitch=-2&thumbfov=100"} />
                                        <Text style={styles.legend}>Photo du terrain vu depuis la rue</Text>
                                        <View style={{ width: '170mm', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1mm' }}>
                                            <Link style={styles.button} src={'http://maps.google.com/maps?q=&layer=c&cbll=' + coords[1].toString(10) + ',' + coords[0].toString(10) + '&cbp=11,' + bearing.toString(10) + ',0,0,-2'}>Ouvrir dans Google Street View</Link>
                                        </View>
                                    </>
                                }
                            </View>
                            {/* FOOTER */}
                            <View style={[styles.footer, { backgroundColor: colors.main, color: colors.txt }]} fixed>
                                <Text render={({ pageNumber, totalPages }) => (
                                    `${pageNumber} / ${totalPages}`
                                )} />
                            </View>
                        </Page>
                        <Page style={styles.common_page} wrap>
                            <View style={styles.title_2}>
                                <Text>1.2 Surface et limites</Text>
                            </View>
                            <View style={styles.paragraphe}>
                                <Text>Surface totale du terrain :</Text>
                                <View style={styles.value_container}>
                                    <Text style={styles.value_main}>{capacity?.landBase?.union?.area ? other_helpers.get_pretty_num(capacity?.landBase?.union?.area.toFixed(2)) : 0}</Text>
                                    <Text style={styles.value_unit}>m²</Text>
                                </View>
                                <Text>Linéaire total de limites :</Text>
                                <View style={[styles.value_container, { marginTop: '1mm' }]}>
                                    <Text style={styles.value_main}>{capacity?.landBase?.calculated_values ? other_helpers.get_pretty_num((capacity.landBase.calculated_values.limit_total_length).toFixed(2)) : 0}</Text>
                                    <Text style={styles.value_unit}>m</Text>
                                </View>
                            </View>
                            {/* FOOTER */}
                            <View style={[styles.footer, { backgroundColor: colors.main, color: colors.txt }]} fixed>
                                <Text render={({ pageNumber, totalPages }) => (
                                    `${pageNumber} / ${totalPages}`
                                )} />
                            </View>
                        </Page>
                    </>
                }
            </Document >
        )
    };


    return (
        <div className="app__exportation_modal_background">
            <div className="app__exportation_modal_container">
                <div className="app__exportation_modal_close" onClick={() => { setIsExportPDF(false) }}><img src={close} alt="close" /></div>
                <div className="app__exportation_modal_title">Export PDF</div>
                <div className="app__sectiondata_widget_spacer_15"></div>
                <div className="app__exportation_modal_subtitle">Template</div>
                <select className="app__exportation_modal_select" onChange={(e) => { changeTemplatePDF(e.target.value) }} defaultValue={confPDF.template}>
                    <option value="standard">Standard</option>
                    <option value="custom">Personnalisé</option>
                </select>
                <div className="app__sectiondata_widget_spacer_10"></div>
                <div className="app__exportation_modal_subtitle">Sections à intégrer</div>
                <div>
                    <div className="app__map_layer">
                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="exportpdf_switch_land" checked={confPDF.land} onChange={() => { switchConfPDF("land") }} /><label className="app__map_layer_checkbox_label" htmlFor="exportpdf_switch_land">Toggle</label></div>
                        <div className="app__exportation_toogle_label_modal">Données terrain<br /><span style={{ fontSize: '11px', fontStyle: 'italic', color: 'grey' }}>localisation, parcelles, limites, topographie, constructions existantes...</span></div>
                    </div>
                    <div className="app__map_layer not_available">
                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="exportpdf_switch_risks" checked={confPDF.risks} onChange={() => { switchConfPDF("risks") }} /><label className="app__map_layer_checkbox_label" htmlFor="exportpdf_switch_risks">Toggle</label></div>
                        <div className="app__exportation_toogle_label_modal">Risques<br /><span style={{ fontSize: '11px', fontStyle: 'italic', color: 'grey' }}>zone sismique, sols argileux, canalisation de matière dangereuse, pollution du sol...</span></div>
                    </div>
                    <div className="app__map_layer not_available">
                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="exportpdf_switch_context" checked={confPDF.context} onChange={() => { switchConfPDF("context") }} /><label className="app__map_layer_checkbox_label" htmlFor="exportpdf_switch_context">Toggle</label></div>
                        <div className="app__exportation_toogle_label_modal">Analyse contextuelle<br /><span style={{ fontSize: '11px', fontStyle: 'italic', color: 'grey' }}>Statistiques de population, statistiques du parc immobilier existant, transactions immobilières, DAU, monuments historiques, commodités...</span></div>
                    </div>
                    <div className="app__map_layer not_available">
                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="exportpdf_switch_rules" checked={confPDF.rules} onChange={() => { switchConfPDF("rules") }} /><label className="app__map_layer_checkbox_label" htmlFor="exportpdf_switch_rules">Toggle</label></div>
                        <div className="app__exportation_toogle_label_modal">Contraintes d'urbanisme<br /><span style={{ fontSize: '11px', fontStyle: 'italic', color: 'grey' }}>Documents d'urbanisme applicables, règles...</span></div>
                    </div>
                    <div className="app__map_layer not_available">
                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="exportpdf_switch_buildable" checked={confPDF.buildable} onChange={() => { switchConfPDF("buildable") }} /><label className="app__map_layer_checkbox_label" htmlFor="exportpdf_switch_buildable">Toggle</label></div>
                        <div className="app__exportation_toogle_label_modal">Etude capacitaire<br /><span style={{ fontSize: '11px', fontStyle: 'italic', color: 'grey' }}>Surface constructible, détail par niveaux...</span></div>
                    </div>
                    {/* <div className="app__map_layer">
                                    <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="exportpdf_switch_combination" checked={confPDF.combination} onChange={() => { switchConfPDF("combination") }} /><label className="app__map_layer_checkbox_label" htmlFor="exportpdf_switch_combination">Toggle</label></div>
                                    <div className="app__exportation_toogle_label_modal">Simulation<br/><span style={{fontSize: '11px', fontStyle: 'italic', color: 'grey'}}>Simulation de nouvelles constructions...</span></div>
                                </div> */}
                </div>
                <div className="app__sectiondata_widget_spacer_15"></div>
                <div className="app__exportation_modal_subcontainer">
                    <div className={`app__exportation_button_small ${exportingPDF_state === "loading" ? "app__exportation_button_small_loading" : exportingPDF_state === "done" ? "app__exportation_button_small_done" : ""}`} onClick={() => { exportPDF() }}>{exportingPDF_state === "ready" ? "Exporter" : exportingPDF_state === "done" ? "✔" : ""}</div>
                    {/* <div className={`app__exportation_button_small ${confPDF.template !== "standard" ? "app__exportation_button_small_hidden" : ""} ${exportingPDF_state === "loading" ? "app__exportation_button_small_loading" : exportingPDF_state === "done" ? "app__exportation_button_small_done" : ""}`} onClick={() => { exportPDF() }}>{exportingPDF_state === "ready" ? "Exporter" : exportingPDF_state === "done" ? "✔" : ""}</div> */}
                    {/* <PDFDownloadLink document={generatePDF(confPDF)} fileName={capacity?.title + ".pdf"}>
                                    {({ loading }) => loading ?
                                        <div className={`app__exportation_button_small app__exportation_button_small_hidden`}></div>
                                        :
                                        <div className={`app__exportation_button_small ${exportingPDF_state === "loading" ? "app__exportation_button_small_loading" : exportingPDF_state === "done" ? "app__exportation_button_small_done" : ""}`} onClick={() => { exportPDF() }}>{exportingPDF_state === "ready" ? "Exporter" : exportingPDF_state === "done" ? "✔" : ""}</div>
                                    }
                                </PDFDownloadLink> */}
                </div>
            </div>
        </div>
    );
};

export default ExportationMenuPDF;