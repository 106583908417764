import React from 'react';

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const LineChart = ({ chartData, chartOptions }) => {
    if (chartData?.datasets) {
        return (
            <Line data={chartData} options={chartOptions} />
        );
    }
};

export default LineChart;