// Import dependencies
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { teamUpdate } from '../../../actions/teams';

// Import CSS & assets
import "./Team.css";
import ico_close from '../../../assets/ico/ico_close_white.svg';
import ico_edit from '../../../assets/ico/ico_edit.svg';
import ico_access from '../../../assets/ico/ico_access.svg';
import ico_add_user from '../../../assets/ico/ico_add_user.svg';
import ico_delete from '../../../assets/ico/ico_delete.svg';
import ico_undelete from '../../../assets/ico/ico_undelete.svg';

import Input from '../../common/input/Input';

const access_labels = {
    land: "Analyse du terrain",
    risks: "Risques & Pollution",
    context: "Contexte INSEE & DVF & MH",
    rules: "Données Géoportail",
    simulations: "Simulations",
    export: "Partage & Exports",
}


const Team = ({ team, updateTeams, var_users, var_usersMailIndex, teamFilter, setTeamFilter }) => {

    // Set global functions
    const dispatch = useDispatch();

    // Hooks
    const [deletingUser, setDeletingUser] = useState(null);
    const [addingUser, setAddingUser] = useState(false);
    const [newUser, setNewUser] = useState("");
    const [deletingTeam, setDeletingTeam] = useState(null);
    const [activatingTeam, setActivatingTeam] = useState(null);
    const [renamingTeam, setRenamingTeam] = useState(false);
    const [newName, setNewName] = useState(team.name);
    const [modifyingAccess, setModifyingAccess] = useState(false);
    const [newPlan, setNewPlan] = useState(team?.plan || {});
    // Loading
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadedSuccess, setIsLoadedSuccess] = useState(false);
    const [isLoadedFail, setIsLoadedFail] = useState(false);
    // Error
    const [errorMessage, setErrorMessage] = useState('');

    // Specific functions
    function checkEmail(email) {
        const re =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };
    const handleKeyUp_newUser = (e) => {
        if (e.key === "Enter") {
            handleSubmit_addUser(e);
        }
    }
    const handleChange_newUser = (e) => {
        setNewUser(e.target.value);
    }
    const handleChange_newName = (e) => {
        setNewName(e.target.value);
    }
    const handleChange_newPlan = (key, value) => {
        var modified_plan = {...newPlan};
        modified_plan[key] = value;
        setNewPlan(modified_plan);
    }
    const resetNewPlan = () => {
        setNewPlan(team?.plan || {});
    }
    // Loading
    const handleLoading = () => {
        setIsLoading(true);
        setIsLoadedSuccess(false);
        setIsLoadedFail(false);
    }
    const handleLoadedSuccess = () => {
        setIsLoadingImage(false);
        setIsLoading(false);
        setIsLoadedSuccess(true);
        // Reset button & clear form
        setTimeout(() => {
            setIsLoadedSuccess(false);
            setIsLoadedFail(false);
            clear();
            setRenamingTeam(false);
            setAddingUser(false);
            setModifyingAccess(false);
            setDeletingUser(null);
            setDeletingTeam(null);
            setActivatingTeam(null);
            setNewUser("");
            setNewName(team.name);
            setNewPlan(team?.plan || {});
        }, 500);
        // Fetch all teams again to update UI
        updateTeams();
    }
    const handleSuccess = () => {
        console.log("SUCCESS");
        handleLoadedSuccess();
    }
    const handleLoadedFail = () => {
        setIsLoading(false);
        setIsLoadedFail(true);
        // Reset button
        setTimeout(() => {
            setIsLoadedSuccess(false);
            setIsLoadedFail(false);
        }, 4000);
    }
    const handleFail = (error) => {
        console.log("FAIL", error.response.data.message);
        setErrorMessage(error.response.data.message);
        handleLoadedFail();
    }
    // Submit form
    const handleSubmit_addUser = (e) => {
        e.preventDefault(); // no refresh

        if (newUser !== "" && checkEmail(newUser)) {
            // Check if user is already attached
            var newUserLower = newUser.toLowerCase();
            var user = var_users[var_usersMailIndex[newUserLower]];
            console.log("user", user);
            if (user === undefined || !user?.team || user?.team === "") {
                // Set loading button
                handleLoading();
                // Check all required fields are not empty
                console.log("SUBMIT ADD NEW USER", newUserLower);
                // Create members
                var members = [...team.members];
                members.push(newUserLower);
                // Create name
                var name = team.name;
                // Create status
                var isInactive = team.isInactive;
                // Create image
                var image = team?.image || "";
                // Create plan
                var plan = team?.plan || {};
                // Create data
                var data = { name, members, isInactive, image, plan };
                // Send
                dispatch(teamUpdate(team._id, { data }, handleSuccess, handleFail));
            }
            else {
                setErrorMessage("Cet utilisateur est déja rattaché à l'équipe : " + user?.team);
                setTimeout(() => {
                    setErrorMessage("");
                }, 1500);
            }
        }
        else {
            setErrorMessage("Cet email est invalide");
            setTimeout(() => {
                setErrorMessage("");
            }, 1500);
        }
    }
    const handleClick_removeUser = (e) => {
        e.stopPropagation();
        setDeletingUser();
    }
    const handleSubmit_removeUser = (e) => {
        e.preventDefault(); // no refresh

        // Set loading button
        handleLoading();
        // Check all required fields are not empty
        console.log("SUBMIT REMOVE USER", deletingUser);
        // Create members
        var members = [];
        team.members.forEach(member => {
            if (member !== deletingUser) {
                members.push(member);
            }
        })
        // Create name
        var name = team.name;
        // Create status
        var isInactive = team.isInactive;
        // Create image
        var image = team?.image || "";
        // Create plan
        var plan = team?.plan || {};
        // Create data
        var data = { name, members, isInactive, image, plan };
        console.log("data", data);
        // Send
        dispatch(teamUpdate(team._id, { data }, handleSuccess, handleFail));
    }
    const handleSubmit_modifyAccess = (e) => {
        e.preventDefault(); // no refresh

        // Set loading button
        handleLoading();
        // Check all required fields are not empty
        console.log("SUBMIT MODIFY ACCESS", newPlan);
        // Create members
        var members = team.members;
        // Create name
        var name = team.name;
        // Create status
        var isInactive = team.isInactive;
        // Create image
        var image = team?.image || "";
        // Create plan
        var plan = newPlan;
        // Create data
        var data = { name, members, isInactive, image, plan };
        console.log("data", data);
        // Send
        dispatch(teamUpdate(team._id, { data }, handleSuccess, handleFail));
    }
    const handleSubmit_renameTeam = (e) => {
        e.preventDefault(); // no refresh

        if (newName !== "" && newName !== team.name) {
            // Set loading button
            handleLoading();
            // Check all required fields are not empty
            console.log("SUBMIT RENAME TEAM", newName);
            // Create members
            var members = team.members;
            // Create name
            var name = newName;
            // Create status
            var isInactive = team.isInactive;
            // Create image
            var image = team?.image || "";
            // Create plan
            var plan = team?.plan || {};
            // Create data
            var data = { name, members, isInactive, image, plan };
            console.log("data", data);
            // Send
            dispatch(teamUpdate(team._id, { data }, handleSuccess, handleFail));
        }
    }
    const handleSubmit_changeImageTeam = (image64) => {

        // Check all required fields are not empty
        console.log("SUBMIT IMAGE TEAM", image64);
        setIsLoadingImage(true);
        // Create members
        var members = team.members;
        // Create name
        var name = team.name;
        // Create status
        var isInactive = team.isInactive;
        // Create image
        var image = image64;
        // Create plan
        var plan = team?.plan || {};
        // Create data
        var data = { name, members, isInactive, image, plan };
        console.log("data", data);
        // Send
        dispatch(teamUpdate(team._id, { data }, handleSuccess, handleFail));
    }
    const handleSubmit_deleteTeam = (e) => {
        e.preventDefault(); // no refresh

        // Set loading button
        handleLoading();
        // Check all required fields are not empty
        console.log("SUBMIT DELETE TEAM", deletingTeam);
        // Create members
        var members = team.members;
        // Create name
        var name = team.name;
        // Create status
        var isInactive = true;
        // Create image
        var image = team?.image || "";
        // Create plan
        var plan = team?.plan || {};
        // Create data
        var data = { name, members, isInactive, image, plan };
        console.log("data", data);
        // Send
        dispatch(teamUpdate(team._id, { data }, handleSuccess, handleFail));
    }
    const handleSubmit_activateTeam = (e) => {
        e.preventDefault(); // no refresh

        // Set loading button
        handleLoading();
        // Check all required fields are not empty
        console.log("SUBMIT ACTIVATE TEAM", deletingTeam);
        // Create members
        var members = team.members;
        // Create name
        var name = team.name;
        // Create status
        var isInactive = false;
        // Create image
        var image = team?.image || "";
        // Create plan
        var plan = team?.plan || {};
        // Create data
        var data = { name, members, isInactive, image, plan };
        console.log("data", data);
        // Send
        dispatch(teamUpdate(team._id, { data }, handleSuccess, handleFail));
    }
    // Clear
    const clear = () => {
        setIsLoading(false);
        setIsLoadedSuccess(false);
        setIsLoadedFail(false);
    }


    // Image
    const [isLoadingImage, setIsLoadingImage] = useState(false);
    const handleFileRead = async (event) => {
        setIsLoadingImage(true);
        // Get file from input
        const file = event.target.files[0];
        console.log(file);
        // Convert to base64
        const base64 = await convertBase64(file);
        console.log(base64);
        // Set base64 as image
        handleSubmit_changeImageTeam(base64);
        // Reset input
        document.getElementById("image_team" + team._id).value = "";
    }
    function convertBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    // Filter
    const handleTeamClick = () => {
        if (teamFilter === team?._id) {
            setTeamFilter(null);
        }
        else {
            setTeamFilter(team?._id);
        }
    }

    return (
        <div className={`app__admin_team_container ${team?.isInactive === true ? "app__admin_team_container_inactive" : ""}  ${teamFilter === team?._id ? "app__admin_team_container_selected" : teamFilter !== null ? "app__admin_team_container_unselected" : ""}`}>
            <div className="app__admin_team_subcontainer_main" onClick={handleTeamClick}>
                <div className="app__admin_team_title">
                    {team.name}
                </div>
                <div className="app__admin_team_body">
                    <div className="app__admin_team_members_container">
                        <div className="app__admin_team_label">
                            {team.members.length > 0 ?
                                <>Membres ({team.members.length})</>
                                :
                                <>Aucun membre</>
                            }
                        </div>
                        <div className="app__admin_team_members_list">
                            {team.members.length <= 0 ?
                                <>Aucun membre</>
                                :
                                <>
                                    {team.members.map((member, index) => {
                                        var member_index = team.users_email.indexOf(member);
                                        return (
                                            <div key={'team_member_' + team._id + index} className={`app__admin_team_member ${member_index < 0 ? "app__admin_team_member_invalid" : ""}`}>{member} <img src={ico_close} alt="" onClick={(e) => { setDeletingUser(member); e.stopPropagation() }} /></div>
                                        )
                                    })}
                                </>
                            }
                        </div>
                    </div>
                    <div className="app__admin_team_capa_container2">
                        <div className="app__admin_team_label">
                            Accès
                        </div>
                        <div className="app__admin_team_access_container">
                            {team?.isInactive === true ?
                                <>Aucun accès à la plateforme</>
                            :
                            <>
                                {(team?.plan?.land === true && team?.plan?.risks === true && team?.plan?.context === true && team?.plan?.rules === true && team?.plan?.simulations === true && team?.plan?.export === true) ?
                                <>Accès complet à la plateforme</>
                                :
                                <>
                                    <div>Accès partiel à la plateforme</div>
                                    {Object.keys(team.plan).map((plan_key, plan_key_index) => {
                                        if (team.plan[plan_key] === true) {
                                            return (
                                                <div className="app__admin_team_acces_item_label_small">✔ {access_labels[plan_key]}</div>
                                            )
                                        }
                                    })}
                                </>
                                }
                            </>
                            }
                        </div>
                        {/* <div className="app__admin_team_capa_container">
                            <div className="app__admin_team_label">
                                Études
                            </div>
                            <div className="app__admin_team_value">
                                {team.capa.length}
                            </div>
                        </div>
                        <div className="app__admin_team_capa_container">
                            <div className="app__admin_team_label">
                                Statut
                            </div>
                            <div className="app__admin_team_value_small">
                                {team?.isInactive === true ? "Inactive" : "Active"}
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            {window.innerWidth > 550 &&
                <div className="app__admin_team_subcontainer_image">
                    {team?.image && team.image !== "" ?
                        <>
                            <img className="app__admin_team_subcontainer_image_box" src={team.image} alt="" />
                            <div className="app__admin_team_subcontainer_image_box_modal">
                                {isLoadingImage === false ?
                                    <>
                                        <div className="app__admin_team_subcontainer_image_box_modal_button" onClick={() => handleSubmit_changeImageTeam("")}>Supprimer</div>
                                        <span>ou</span>
                                        <div>
                                            <label className="app__admin_team_subcontainer_image_box_modal_button" htmlFor={"image_team" + team._id}>Modifier</label>
                                            <input type="file" id={"image_team" + team._id} name="avatar" accept="image/png, image/jpeg" style={{ display: "none" }} onChange={e => handleFileRead(e)} />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <span>Chargement...</span>
                                    </>
                                }
                            </div>
                        </>
                        :
                        <>
                            <label className={`app__admin_team_subcontainer_image_placeholder ${isLoadingImage ? "app__admin_team_subcontainer_image_placeholder_loading" : ""}`} htmlFor={"image_team" + team._id}>{isLoadingImage === false ? "Ajouter un logo" : "Chargement..."}</label>
                            <input type="file" id={"image_team" + team._id} name="avatar" accept="image/png, image/jpeg" style={{ display: "none" }} onChange={e => handleFileRead(e)} />
                        </>
                    }
                </div>
            }
            <div className="app__admin_team_subcontainer_actions">
                <div className="app__admin_team_actions_container">
                    {team?.isInactive === false &&
                        <>
                        <div className="app__admin_team_button" onClick={() => { setRenamingTeam(true) }}>
                            <img src={ico_edit} alt="" />Renommer l'équipe
                        </div>
                        <div className="app__admin_team_button" onClick={() => { setAddingUser(true) }}>
                            <img src={ico_add_user} alt="" />Rattacher un membre
                        </div>
                        <div className="app__admin_team_button" onClick={() => { setModifyingAccess(true) }}>
                            <img src={ico_access} alt="" />Modifier les accès
                        </div>
                        </>
                    }
                    {team?.isInactive === true ?
                        <div className="app__admin_team_button" onClick={() => { setActivatingTeam(team._id) }}>
                            <img src={ico_undelete} alt="" />Réactiver l'équipe
                        </div>
                        :
                        <div className="app__admin_team_button_red" onClick={() => { setDeletingTeam(team._id) }}>
                            <img src={ico_delete} alt="" />Désactiver l'équipe
                        </div>
                    }
                </div>
            </div>
            {/* MESSAGES */}
            {deletingUser !== null &&
                <div className="app__admin_team_modal" onMouseLeave={() => { setDeletingUser(null) }}>
                    <div>Voulez-vous vraiment retirer l'utilisateur <span style={{ color: '#ababab' }}>{deletingUser}</span> de cette équipe ?</div>
                    <div className="app__admin_team_modal_buttons">
                        <div className={`app__admin_team_modal_button ${isLoading ? "app__admin_team_modal_button_loading" : ""} ${isLoadedSuccess ? "app__admin_team_modal_button_loading_success" : ""} ${isLoadedFail ? "app__admin_team_modal_button_loading_fail" : ""}`} onClick={handleSubmit_removeUser}>{isLoading ? "" : isLoadedSuccess ? "✔" : isLoadedFail ? "🗙" : "Retirer"}</div>
                        <div className="app__admin_team_modal_button_red" onClick={() => { setDeletingUser(null); clear() }}>Annuler</div>
                        <div className="app__admin_team_modal_message">{errorMessage}</div>
                    </div>
                </div>
            }
            {addingUser === true &&
                <div className="app__admin_team_modal" onMouseLeave={() => { setAddingUser(false); setNewUser("") }}>
                    <div className="app__admin_team_modal_input">
                        <Input type='text' name='new_member' label="Email du nouveau membre" value={newUser} handleChange={handleChange_newUser} handleKeyUp={handleKeyUp_newUser} autofocus={true} />
                    </div>
                    <div className="app__admin_team_modal_buttons">
                        <div className={`app__admin_team_modal_button ${isLoading ? "app__admin_team_modal_button_loading" : ""} ${isLoadedSuccess ? "app__admin_team_modal_button_loading_success" : ""} ${isLoadedFail ? "app__admin_team_modal_button_loading_fail" : ""}`} onClick={handleSubmit_addUser}>{isLoading ? "" : isLoadedSuccess ? "✔" : isLoadedFail ? "🗙" : "Ajouter"}</div>
                        <div className="app__admin_team_modal_button_red" onClick={() => { setAddingUser(false); setNewUser(""); clear() }}>Annuler</div>
                        <div className="app__admin_team_modal_message">{errorMessage}</div>
                    </div>
                </div>
            }
            {modifyingAccess === true &&
                <div className="app__admin_team_modal" onMouseLeave={() => { setModifyingAccess(false); resetNewPlan() }}>
                    <div>Accès de l'équipe <span style={{ color: '#ababab' }}>{team.name}</span> :</div>
                    <div className="app__admin_team_acces_container">
                        {Object.keys(newPlan).map((plan_key, plan_key_index) => {
                            return (
                                <div key={"team_plan_" + plan_key_index} className="app__admin_team_acces_item">
                                    <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id={"team_plan_checkbox_" + plan_key_index} checked={newPlan[plan_key]} onChange={(e) => { handleChange_newPlan(plan_key, e.target.checked) }} /><label className="app__map_layer_checkbox_label" htmlFor={"team_plan_checkbox_" + plan_key_index}>Toggle</label></div>
                                    <div className="app__admin_team_acces_item_label">{access_labels[plan_key]}</div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="app__admin_team_modal_buttons">
                        <div className={`app__admin_team_modal_button ${isLoading ? "app__admin_team_modal_button_loading" : ""} ${isLoadedSuccess ? "app__admin_team_modal_button_loading_success" : ""} ${isLoadedFail ? "app__admin_team_modal_button_loading_fail" : ""}`} onClick={handleSubmit_modifyAccess}>{isLoading ? "" : isLoadedSuccess ? "✔" : isLoadedFail ? "🗙" : "Enregistrer"}</div>
                        <div className="app__admin_team_modal_button_red" onClick={() => { setModifyingAccess(false); resetNewPlan(); clear() }}>Annuler</div>
                        <div className="app__admin_team_modal_message">{errorMessage}</div>
                    </div>
                </div>
            }
            {deletingTeam !== null &&
                <div className="app__admin_team_modal" onMouseLeave={() => { setDeletingTeam(null) }}>
                    Voulez-vous vraiment supprimer cette équipe ?
                    <div className="app__admin_team_modal_buttons">
                        <div className={`app__admin_team_modal_button ${isLoading ? "app__admin_team_modal_button_loading" : ""} ${isLoadedSuccess ? "app__admin_team_modal_button_loading_success" : ""} ${isLoadedFail ? "app__admin_team_modal_button_loading_fail" : ""}`} onClick={handleSubmit_deleteTeam}>{isLoading ? "" : isLoadedSuccess ? "✔" : isLoadedFail ? "🗙" : "Supprimer"}</div>
                        <div className="app__admin_team_modal_button_red" onClick={() => { setDeletingTeam(null); clear() }}>Annuler</div>
                        <div className="app__admin_team_modal_message">{errorMessage}</div>
                    </div>
                </div>
            }
            {activatingTeam !== null &&
                <div className="app__admin_team_modal" onMouseLeave={() => { setActivatingTeam(null) }}>
                    Voulez-vous vraiment restaurer cette équipe ?
                    <div className="app__admin_team_modal_buttons">
                        <div className={`app__admin_team_modal_button ${isLoading ? "app__admin_team_modal_button_loading" : ""} ${isLoadedSuccess ? "app__admin_team_modal_button_loading_success" : ""} ${isLoadedFail ? "app__admin_team_modal_button_loading_fail" : ""}`} onClick={handleSubmit_activateTeam}>{isLoading ? "" : isLoadedSuccess ? "✔" : isLoadedFail ? "🗙" : "Restaurer"}</div>
                        <div className="app__admin_team_modal_button_red" onClick={() => { setActivatingTeam(null); clear() }}>Annuler</div>
                        <div className="app__admin_team_modal_message">{errorMessage}</div>
                    </div>
                </div>
            }
            {renamingTeam === true &&
                <div className="app__admin_team_modal" onMouseLeave={() => { setRenamingTeam(false); setNewName(team.name) }}>
                    <div className="app__admin_team_modal_input">
                        <Input type='text' name='new_name' label="Nom de l'équipe" value={newName} handleChange={handleChange_newName} autofocus={true} />
                    </div>
                    <div className="app__admin_team_modal_buttons">
                        <div className={`app__admin_team_modal_button ${isLoading ? "app__admin_team_modal_button_loading" : ""} ${isLoadedSuccess ? "app__admin_team_modal_button_loading_success" : ""} ${isLoadedFail ? "app__admin_team_modal_button_loading_fail" : ""}`} onClick={handleSubmit_renameTeam}>{isLoading ? "" : isLoadedSuccess ? "✔" : isLoadedFail ? "🗙" : "Renommer"}</div>
                        <div className="app__admin_team_modal_button_red" onClick={() => { setRenamingTeam(false); setNewName(team.name); clear() }}>Annuler</div>
                        <div className="app__admin_team_modal_message">{errorMessage}</div>
                    </div>
                </div>
            }
            {(teamFilter !== team?._id && teamFilter !== null) &&
                <div className="app__admin_team_blocker" onClick={handleTeamClick}></div>
            }
        </div>
    );
};

export default Team;