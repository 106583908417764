// Import dependencies
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Import CSS
import './App.css';

// Import components
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import ErrorBoundary from './components/common/errorPage/ErrorBoundary';
import { Home, Mobile, Auth, Activate, Reset, Dashboard, Capa, CapaStudy, Admin, Release } from './pages';

// Import global
import * as global from './global.js';
const version = global.get_version();
const environment = global.get_environment();


const App = () => {

    return (
        <BrowserRouter>
            <ErrorBoundary version={version}>
                <ScrollToTop>
                    <Routes>
                        <Route path='/' exact element={<Home />} />
                        <Route path='/mobile' exact element={<Mobile version={version} />} />
                        <Route path='/auth' exact element={<Auth version={version} environment={environment} />} />
                        <Route path='/auth/activate/:activationToken' exact element={<Activate />} />
                        <Route path='/auth/reset/:resetToken' exact element={<Reset />} />
                        <Route path='/dashboard' exact element={<Dashboard version={version} environment={environment} />} />
                        <Route path='/capacity/:id' exact element={<Capa />} />
                        <Route path='/capastudy/:id' exact element={<CapaStudy version={version} environment={environment} />} />
                        <Route path='/admin' exact element={<Admin />} />
                        <Route path='/release' exact element={<Release version={version} environment={environment} />} />
                    </Routes>
                </ScrollToTop>
            </ErrorBoundary>
        </BrowserRouter>
    );
};

export default App;