// Import dependencies
import React, { useState, useEffect } from 'react';

import ico_arrow from '../../assets/ico/ico_arrow_up.svg';



const ReleaseItem = ({ release, index, focus, max_index }) => {

    // Set hooks
    const [detailed, setDetailed] = useState(release?.version === focus ? true : false);
    const [abstract, setAbstract] = useState("");

    useEffect(() => {
        setDetailed(release?.version === focus ? true : false);
    }, [focus]);

    // ON LOAD
    useEffect(() => {
        var abstract_txt = "Non communiqué";
        var abstract_list = [];
        if (release?.detail?.features && release.detail.features.length > 0) {
            abstract_list.push(release.detail.features.length + (release.detail.features.length > 1 ? " nouvelles fonctionnalités" : " nouvelle fonctionnalité"));
        }
        if (release?.detail?.updates && release.detail.updates.length > 0) {
            abstract_list.push(release.detail.updates.length + (release.detail.updates.length > 1 ? " mises à jour" : " mise à jour"));
        }
        if (release?.detail?.fixes && release.detail.fixes.length > 0) {
            abstract_list.push(release.detail.fixes.length + (release.detail.fixes.length > 1 ? " correctifs" : " correctif"));
        }
        if (abstract_list.length > 0) {
            abstract_txt = "Cette release contient ";
            for (var i = 0; i < abstract_list.length; i++) {
                if (i > 0 && i === abstract_list.length - 1) {
                    abstract_txt = abstract_txt + " et ";
                }
                else if (i > 0) {
                    abstract_txt = abstract_txt + ", ";
                }
                abstract_txt = abstract_txt + abstract_list[i];
            }
        }
        setAbstract(abstract_txt);
    }, []);


    // RETURN
    return (
        <div className='release__item_container' key={index} style={{ marginTop: index === 0 ? "20px" : "" }}>
            <div className={`release__item_box ${index % 2 === 0 ? "release__item_box_left fade_in_box_left" : "release__item_box_right fade_in_box_right"} ${"anim_index_" + index}`}>
                <div id={'release_' + release?.version} className='release__item_subox'>
                    <div className='release__item_date'>{release?.date}</div>
                    <div className='release__item_title_group'>
                        <div className='release__item_version'>{release?.version}</div>
                        <div className='release__item_title'>{release?.title}</div>
                    </div>
                    <div className='release__item_label'>Type</div>
                    <div className='release__item_text'>Release {release?.type}</div>
                    <div className='release__item_label'>Résumé</div>
                    <div className='release__item_text'>{abstract}</div>
                    {abstract !== "Non communiqué" &&
                        <div className='release__item_detail_container'>
                            <div className={`release__item_detail_box ${!detailed ? "release__item_detail_box_hidden" : ""}`}>
                                {/* FEATURES */}
                                {(release?.detail?.features && release.detail.features.length > 0) &&
                                    <>
                                        <div className='release__item_label'>{release.detail.features.length > 1 ? "Nouvelles fonctionnalités" : "Nouvelle fonctionnalité"}</div>
                                        {release.detail.features.map((feature, feature_index) => {
                                            return (
                                                <div key={feature_index}>
                                                    {feature_index > 0 &&
                                                        <div className='release__item_detail_separator'></div>
                                                    }
                                                    {feature?.title &&
                                                        <div className='release__item_detail_label'>• {feature.title}</div>
                                                    }
                                                    {feature?.description &&
                                                        <div className='release__item_detail_description'>{feature.description}</div>
                                                    }
                                                    {feature?.img?.src &&
                                                        <div className='release__item_detail_img'><img src={feature.img.src} style={{ maxWidth: feature?.img?.max_w || "" }} /></div>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </>
                                }
                                {/* UPDATES */}
                                {(release?.detail?.updates && release.detail.updates.length > 0) &&
                                    <>
                                        {(release?.detail?.features && release.detail.features.length > 0) &&
                                            <div className="app__sectiondata_widget_spacer_10"></div>
                                        }
                                        <div className='release__item_label'>{release.detail.updates.length > 1 ? "Mises à jour" : "Mise à jour"}</div>
                                        {release.detail.updates.map((update, update_index) => {
                                            return (
                                                <div key={update_index}>
                                                    {update_index > 0 &&
                                                        <div className='release__item_detail_separator'></div>
                                                    }
                                                    {update?.title &&
                                                        <div className='release__item_detail_label'>• {update.title}</div>
                                                    }
                                                    {update?.description &&
                                                        <div className='release__item_detail_description'>{update.description}</div>
                                                    }
                                                    {update?.img?.src &&
                                                        <div className='release__item_detail_img'><img src={update.img.src} style={{ maxWidth: update?.img?.max_w || "" }} /></div>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </>
                                }
                                {/* FIXES */}
                                {(release?.detail?.fixes && release.detail.fixes.length > 0) &&
                                    <>
                                        {(release?.detail?.features && release.detail.features.length > 0) &&
                                            <div className="app__sectiondata_widget_spacer_10"></div>
                                        }
                                        <div className='release__item_label'>{release.detail.fixes.length > 1 ? "Correctifs" : "Correctif"}</div>
                                        {release.detail.fixes.map((fix, fix_index) => {
                                            return (
                                                <div key={fix_index}>
                                                    {fix_index > 0 &&
                                                        <div className='release__item_detail_separator'></div>
                                                    }
                                                    {fix?.title &&
                                                        <div className='release__item_detail_label'>• {fix.title}</div>
                                                    }
                                                    {fix?.description &&
                                                        <div className='release__item_detail_description'>{fix.description}</div>
                                                    }
                                                    {fix?.img?.src &&
                                                        <div className='release__item_detail_img'><img src={fix.img.src} style={{ maxWidth: fix?.img?.max_w || "" }} /></div>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </>
                                }
                            </div>
                            <div className='release__item_detail_arrow_button' onClick={() => { setDetailed(!detailed) }}>
                                <img className={`release__item_detail_arrow ${detailed ? "release__item_detail_arrow_up" : "release__item_detail_arrow_down"}`} src={ico_arrow} alt="arrow_up" />
                            </div>
                            {/* <div className="app__context_housing_detail_button_container" onClick={() => { setDetailed(!detailed) }}>
                                {detailed &&
                                    <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                }
                                <div className="app__context_housing_detail_button">{detailed ? 'Masquer les détails' : 'Afficher les détails'}</div>
                                {!detailed &&
                                    <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                }
                            </div> */}
                        </div>
                    }

                </div>
            </div>
            <div className='release__item_shapes'>
                <div className={`release__item_circle fade_in_circle ${"anim_index_" + index}`}></div>
                {index < max_index &&
                    <div className={`release__item_line fade_in_line ${"anim_index_" + index}`}></div>
                }
            </div>
        </div>
    );
};

export default ReleaseItem;