import React, { useEffect, useState } from 'react';

import * as IFC_helpers from '../../../../helpers/IFC_helpers';
import * as other_helpers from '../../../../helpers/Other_helpers';

// Import CSS & assets
import './ExportationAll.css';
import ico_file_pdf from '../../../../assets/ico/ico_file_pdf.svg';
import ico_file_ifc from '../../../../assets/ico/ico_file_ifc.svg';
import close from '../../../../assets/ico/ico_close.svg';
import customFont_reg from '../../../../assets/fonts/Manrope-Regular.ttf';
import customFont_light from '../../../../assets/fonts/Manrope-SemiBold.ttf';
import customFont_bold from '../../../../assets/fonts/Manrope-Regular.ttf';
import customFont_extbold from '../../../../assets/fonts/Manrope-ExtraBold.ttf';
import imgBuilding from '../../../../assets/building_big.png';
import imgLogoText_white from '../../../../assets/pdf/logo_text_white.png';
import imgLogoText_blue from '../../../../assets/pdf/logo_text_blue.png';

var confIFC_var = {
    land: true,
    topo: false,
    buildable_volume: false,
    buildings: false,
    combination: false,
    version: "IFC2x3"
};



const ExportationMenuIFC = ({ capacity, setCapacity, handleToaster, user, setIsExportIFC }) => {

    const [exportingIFC_state, setExportingIFC_state] = useState("ready");
    const [confIFC, setConfIFC] = useState(confIFC_var);
    const switchConfIFC = (key) => {
        // Get state
        var confIFCState = confIFC;
        // Update state
        confIFCState[key] = !confIFCState[key];
        // Update original
        setConfIFC({ ...confIFCState });
        confIFC_var = confIFCState;
    }
    const changeVersionIFC = (version) => {
        // Get state
        var confIFCState = confIFC;
        // Update state
        confIFCState["version"] = version;
        // Update original
        setConfIFC({ ...confIFCState });
        confIFC_var = confIFCState;
    }
    const exportIFC = () => {
        console.log("EXPORT IFC", confIFC_var);
        setExportingIFC_state("loading");
        const link = document.createElement("a");
        const content = IFC_helpers.get_ifc_data(capacity, confIFC_var);
        const file = new Blob([content], { type: 'text/plain' });
        link.href = URL.createObjectURL(file);
        link.download = (capacity.title).replace(/\s+/g, "_").normalize("NFD").replace(/\p{Diacritic}/gu, "") + ".ifc";
        link.click();
        URL.revokeObjectURL(link.href);
        setTimeout(() => {
            setExportingIFC_state("done");
            setTimeout(() => {
                setExportingIFC_state("ready");
            }, 3000);
        }, 1000);
        // Toaster
        handleToaster([{ key: "message", status: "info", type: "static", style: "default", title: "Fonctionnalité bêta", text: "Cette fonctionnalité est en mode bêta, elle évoluera dans les prochaines versions de l'application." }]);
    }




    return (
        <div className="app__exportation_modal_background">
            <div className="app__exportation_modal_container">
                <div className="app__exportation_modal_close" onClick={() => { setIsExportIFC(false) }}><img src={close} alt="close" /></div>
                <div className="app__exportation_modal_title">Export IFC</div>
                <div className="app__sectiondata_widget_spacer_15"></div>
                {/* <div className="app__exportation_3d_container"></div> */}
                {/* <div className="app__sectiondata_widget_spacer_10"></div> */}
                <div className="app__exportation_modal_subtitle">Version</div>
                <select className="app__exportation_modal_select" onChange={(e) => { changeVersionIFC(e.target.value) }} defaultValue={confIFC.version}>
                    <option value="IFC2x3">IFC 2x3</option>
                    <option value="IFC4">IFC 4</option>
                </select>
                <div className="app__sectiondata_widget_spacer_10"></div>
                <div className="app__exportation_modal_subtitle">Calques à exporter</div>
                <div>
                    <div className="app__map_layer">
                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="export_switch_land" checked={confIFC.land} onChange={() => { switchConfIFC("land") }} /><label className="app__map_layer_checkbox_label" htmlFor="export_switch_land">Toggle</label></div>
                        <div className="app__exportation_toogle_label_modal">Terrain</div>
                    </div>
                    <div className="app__map_layer not_available">
                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="export_switch_topo" checked={confIFC.topo} onChange={() => { switchConfIFC("topo") }} /><label className="app__map_layer_checkbox_label" htmlFor="export_switch_topo">Toggle</label></div>
                        <div className="app__exportation_toogle_label_modal">Topographie</div>
                    </div>
                    <div className="app__map_layer not_available">
                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="export_switch_volume" checked={confIFC.buildable_volume} onChange={() => { switchConfIFC("buildable_volume") }} /><label className="app__map_layer_checkbox_label" htmlFor="export_switch_volume">Toggle</label></div>
                        <div className="app__exportation_toogle_label_modal">Volume constructible</div>
                    </div>
                    <div className="app__map_layer not_available">
                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="export_switch_buildings" checked={confIFC.buildings} onChange={() => { switchConfIFC("buildings") }} /><label className="app__map_layer_checkbox_label" htmlFor="export_switch_buildings">Toggle</label></div>
                        <div className="app__exportation_toogle_label_modal">Bâtiments existants</div>
                    </div>
                    <div className="app__map_layer not_available">
                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="export_switch_combi" checked={confIFC.combination} onChange={() => { switchConfIFC("combination") }} /><label className="app__map_layer_checkbox_label" htmlFor="export_switch_combi">Toggle</label></div>
                        <div className="app__exportation_toogle_label_modal">Simulation</div>
                    </div>
                </div>
                <div className="app__sectiondata_widget_spacer_15"></div>
                <div className="app__exportation_modal_subcontainer">
                    <div className={`app__exportation_button_small ${confIFC.version !== "IFC2x3" ? "app__exportation_button_small_hidden" : ""} ${exportingIFC_state === "loading" ? "app__exportation_button_small_loading" : exportingIFC_state === "done" ? "app__exportation_button_small_done" : ""}`} onClick={() => { exportIFC() }}>{exportingIFC_state === "ready" ? "Exporter" : exportingIFC_state === "done" ? "✔" : ""}</div>
                </div>
            </div>
        </div>
    );
};

export default ExportationMenuIFC;