import React, { useState } from 'react';

// Import components
import Land from './Land';
import WidgetInfo from '../buildable/WidgetInfo';

// Import CSS & assets
import ico_map_layers from '../../../../assets/ico/ico_map_layers.svg';
import ico_arrow from '../../../../assets/ico/ico_arrow_up.svg';
import fade from '../../../../assets/fade_grey.svg';

// Import external functions
import * as helpers from '../../../../helpers/Other_helpers';

const LandsDetail = ({ capacity, setCapacity, mapLayers, setMapLayers, user }) => {

    // LAYER : Toggle
    const layer_Toggle = (layer) => {
        console.log("TOGGLE LAYER", layer);
        // Get state
        var mapLayersState = mapLayers;
        // Update state
        mapLayersState[layer]["isVisible"] = !mapLayersState[layer]["isVisible"];
        // Update original
        setMapLayers({ ...mapLayersState });
    }

    const [landsDetail, setLandsDetail] = useState(false);
    const handleLandsDetail = () => {
        if (landsDetail === true) {
            // Scroll
            // var topList = document.getElementById("lands_list").offsetTop;
            var topSection = document.getElementById("section_1").offsetTop;
            document.getElementById("section_data").scrollTop = topSection - 15;
        }
        setLandsDetail(!landsDetail);
    }

    const [areaType, setAreaType] = useState("cadastre");
    const changeAreaType = () => {
        const newType = document.getElementById('landAreaType').value;
        setAreaType(newType);
    }


    return (
        <div className="app__sectiondata_widget_layout_B">
            <div>
                <div className="app__sectiondata_widget_title_container">
                    <div className="app__sectiondata_widget_title">Terrain d'assiette</div>
                    {capacity?.step > 0 &&
                        <div className="app__sectiondata_widget_layer">
                            <img src={ico_map_layers} alt="ico_map_layers" />
                            <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_union" checked={mapLayers.union.isVisible} onChange={() => { layer_Toggle("union") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_union">Toggle</label></div>
                        </div>
                    }
                </div>
                {/* {(capacity?.landBase?.union?.geometry?.geometry?.type && capacity.landBase.union.geometry.geometry.type === "MultiPolygon") &&
                    <>
                        <div className="app__sectiondata__widget_warning">✋ Les parcelles sélectionnées doivent être adjacentes</div>
                        <div className="app__sectiondata_widget_spacer_10"></div>
                    </>
                } */}
                <div className="app__sectiondata_lands_select_container">
                    <div className="app__sectiondata_lands_select_label">Type de surfaces à afficher :</div>
                    <select className="app__sectiondata_lands_select" id="landAreaType" onChange={changeAreaType}>
                        <option value="cadastre">issues du cadastre</option>
                        <option value="calculated">recalculées</option>
                    </select>
                </div>
                <div className={`${window.innerWidth > 550 ? "app__sectiondata_widget_layout_A2" : "app__sectiondata_widget_layout_A"}`}>
                    <div className="app__sectiondata__widget">
                        <div className="app__sectiondata_widget_title">Surface totale</div>
                        <div className="app__sectiondata_widget_layout_A">
                            <div className="app__sectiondata_widget_value_container" style={{ position: 'relative' }}>
                                {areaType === "cadastre" ?
                                    <div className="app__sectiondata_widget_value_text">{(capacity?.landBase?.lands && capacity.landBase.lands.length > 0 && capacity.landBase.lands[0]?.properties?.contenance) ? helpers.get_pretty_num(capacity.landBase.lands.reduce((accumulator, current) => accumulator + current?.properties?.contenance, 0)) : capacity?.landBase?.union?.area ? helpers.get_pretty_num(Math.round(capacity.landBase.union.area)) : 0}</div>
                                    :
                                    <div className="app__sectiondata_widget_value_text">{(capacity?.landBase?.lands && capacity.landBase.lands.length > 0 && capacity?.landBase?.union?.area) ? helpers.get_pretty_num(Math.round(capacity.landBase.union.area)) : 0}</div>
                                }
                                <div className="app__sectiondata_widget_value_legend">m²</div>
                                {(capacity?.landBase?.lands && capacity.landBase.lands.length > 0 && areaType === "cadastre") &&
                                    <WidgetInfo ico={"💡"} title={"Information"} text={<>Les surfaces affichées ici sont directement <strong>issues du cadastre</strong>.<br />Ces surfaces étant peu précises, nous les recalculons afin de pouvoir les réutiliser pour d'autres éléments de l'étude capacitaire.<br />Ce calcul apporte plus de précision mais peut créer de légers écarts avec les valeurs issues du cadastre.<br />Pour afficher ces surfaces, sélectionnez "recalculées" dans l'option ci-dessus.</>} />
                                }
                            </div>
                            {window.innerWidth > 550 &&
                                <div className="app__sectiondata_widget_value_container">
                                    <div className="app__sectiondata_widget_value_text">{capacity?.landBase?.union?.area ? helpers.get_pretty_num((capacity.landBase.union.area / 10000).toFixed(2)) : (0).toFixed(2)}</div>
                                    <div className="app__sectiondata_widget_value_legend">ha</div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="app__sectiondata__widget">
                        <div className="app__sectiondata_widget_title">Nombre de parcelles</div>
                        <div className="app__sectiondata_widget_value_container">
                            <div className="app__sectiondata_widget_value_text">{capacity?.landBase?.lands ? capacity.landBase.lands.length : 0}</div>
                            <div className="app__sectiondata_widget_value_legend">{(capacity?.landBase?.lands && capacity.landBase.lands.length > 1) ? "parcelles" : "parcelle"}</div>
                        </div>
                    </div>
                </div>
                {/* <div className="app__sectiondata_widget_info_container">
                    <div className="app__sectiondata_widget_info_box">
                        <div className="app__sectiondata_widget_info_icon">i</div>
                        <div className="app__sectiondata_widget_info_text">
                            Source : IGN - Services de calculs altimétriques, précision 2,5m
                        </div>
                    </div>
                </div> */}
            </div>
            {(capacity?.landBase?.lands && capacity.landBase.lands.length > 0) &&
                <div>
                    <div id="section_1_lands" className="app__sectiondata_widget_title">Liste des parcelles</div>
                    <div id="lands_list" className={`app__sectiondata_lands_container ${(!landsDetail && capacity?.landBase?.lands.length > 5) ? "app__sectiondata_lands_container_small" : ""}`}>
                        {capacity?.landBase?.lands.map((item, index) => {
                            return (
                                <Land key={index} land={item} index={index} user={user} areaType={areaType} />
                            )
                        })}
                    </div>
                    {capacity?.landBase?.lands.length > 5 &&
                        <div className="app__context_housing_detail_button_container2">
                            {!landsDetail ?
                                <img className="app__fade" src={fade} alt="" />
                                :
                                <div className="app__sectiondata_widget_spacer_10"></div>
                            }
                            <div className="app__context_housing_detail_button_container" onClick={handleLandsDetail}>
                                {landsDetail &&
                                    <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                }
                                <div className="app__context_housing_detail_button">{landsDetail ? 'Afficher moins de parcelles' : 'Afficher les ' + capacity?.landBase?.lands.length + ' parcelles'}</div>
                                {!landsDetail &&
                                    <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                }
                            </div>
                        </div>
                    }
                    <div className="app__sectiondata_widget_info_container">
                        <div className="app__sectiondata_widget_info_text">
                            Source : Cadastre et base MAJIC
                        </div>
                        <div className="app__sectiondata_widget_info_icon">i</div>
                    </div>
                </div>
            }
        </div>
    );
};

export default LandsDetail;