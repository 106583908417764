// Import dependencies
import * as turf from '@turf/turf';


const randomId = function (length = 22) {
    return Math.random().toString(36).substring(2, length + 2);
};

const mercator_to_local_point = (point, point0, rounding) => {
    // BASIC VERSION
    var deltaX = (turf.distance(turf.point(point), turf.point([point0[0], point[1]])) * 1000);
    if (point[0] < point0[0]) {
        deltaX = -deltaX;
    }

    var deltaY = (turf.distance(turf.point(point), turf.point([point[0], point0[1]])) * 1000);
    if (point[1] < point0[1]) {
        deltaY = -deltaY;
    }

    // ROUNDING
    if (rounding) {
        deltaX = Math.round(deltaX * rounding) / rounding;
        deltaY = Math.round(deltaY * rounding) / rounding;
    }

    return [deltaX, deltaY];

}

const mercator_to_dms_point = (point) => {
    var absolute_0 = Math.abs(point[0]);
    var degrees_0 = Math.floor(absolute_0);
    var minutesNotTruncated_0 = (absolute_0 - degrees_0) * 60;
    var minutes_0 = Math.floor(minutesNotTruncated_0);
    var seconds_0 = ((minutesNotTruncated_0 - minutes_0) * 60).toFixed(3);
    var dms_0 = degrees_0 + "," + minutes_0 + "," + seconds_0.toString().replace(".", ",");

    var absolute_1 = Math.abs(point[1]);
    var degrees_1 = Math.floor(absolute_1);
    var minutesNotTruncated_1 = (absolute_1 - degrees_1) * 60;
    var minutes_1 = Math.floor(minutesNotTruncated_1);
    var seconds_1 = ((minutesNotTruncated_1 - minutes_1) * 60).toFixed(3);
    var dms_1 = degrees_1 + "," + minutes_1 + "," + seconds_1.toString().replace(".", ",");

    return [dms_0, dms_1]
}

export const get_ifc_data = (capacity, conf) => {

    var name = (capacity.title).replace(/\s+/g, "_").normalize("NFD").replace(/\p{Diacritic}/gu, "");
    var date = new Date().toISOString().slice(0, 19);
    var center_coords = capacity.landBase.union.center.geometry.coordinates;
    var lands_txt = capacity?.landBase?.lands.length > 1 ? "Parcelles " : "Parcelle ";
    capacity?.landBase?.lands.forEach((land, land_index) => {
        if (land_index >= capacity.landBase.lands.length - 1) { lands_txt = lands_txt + " et " }
        else if (land_index > 0) { lands_txt = lands_txt + ", " }
        lands_txt = lands_txt + land.properties.section + "-" + land.properties.numero
    })

    var ifc_data = "";

    // Header
    ifc_data += "ISO-10303-21;\n\nHEADER;\nFILE_DESCRIPTION(('ViewDefinition [CoordinationView_V2.0]'),'2;1');\n";
    ifc_data += "FILE_NAME('" + name + ".ifc','" + date + "',('PropLab - Etude Capacitaire'),('PROPLAB'),'PropLab IFC Exporter 1.1.0','','');\n";
    ifc_data += "FILE_SCHEMA(('IFC2X3'));\nENDSEC;\n\nDATA;\n";

    // Organization & Application & person
    ifc_data += "#1=IFCORGANIZATION($,'Autodesk Revit 2024 (FRA)',$,$,$);\n#2=IFCAPPLICATION(#1,'2024','Autodesk Revit 2024 (FRA)','Revit');\n#15=IFCPERSON($,'','s.lebaillyUXDFD',$,$,$,$,$);\n#16=IFCORGANIZATION($,'','',$,$);\n#17=IFCPERSONANDORGANIZATION(#15,#16,$);\n#18=IFCOWNERHISTORY(#17,#2,$,.NOCHANGE.,$,$,$,1722937191);";

    // Points & Axis
    ifc_data += "#3=IFCCARTESIANPOINT((0.,0.,0.));\n#4=IFCCARTESIANPOINT((0.,0.));\n#5=IFCDIRECTION((1.,0.,0.));\n#6=IFCDIRECTION((-1.,0.,0.));\n#7=IFCDIRECTION((0.,1.,0.));\n#8=IFCDIRECTION((0.,-1.,0.));\n#9=IFCDIRECTION((0.,0.,1.));\n#10=IFCDIRECTION((0.,0.,-1.));\n#11=IFCDIRECTION((1.,0.));\n#12=IFCDIRECTION((-1.,0.));\n#13=IFCDIRECTION((0.,1.));\n#14=IFCDIRECTION((0.,-1.));\n";

    // SI units
    ifc_data += "#19=IFCSIUNIT(*,.LENGTHUNIT.,$,.METRE.);\n#20=IFCSIUNIT(*,.AREAUNIT.,$,.SQUARE_METRE.);\n#21=IFCSIUNIT(*,.VOLUMEUNIT.,$,.CUBIC_METRE.);\n#22=IFCSIUNIT(*,.PLANEANGLEUNIT.,$,.RADIAN.);\n#23=IFCDIMENSIONALEXPONENTS(0,0,0,0,0,0,0);\n#24=IFCMEASUREWITHUNIT(IFCPLANEANGLEMEASURE(0.017453292519943278),#22);\n#25=IFCCONVERSIONBASEDUNIT(#23,.PLANEANGLEUNIT.,'DEGREE',#24);\n";
    ifc_data += "#26=IFCSIUNIT(*,.MASSUNIT.,.KILO.,.GRAM.);\n#27=IFCDERIVEDUNITELEMENT(#26,1);\n#28=IFCDERIVEDUNITELEMENT(#19,-3);\n#29=IFCDERIVEDUNIT((#27,#28),.MASSDENSITYUNIT.,$);\n#30=IFCDERIVEDUNIT((#27,#28),.IONCONCENTRATIONUNIT.,$);\n#31=IFCDERIVEDUNITELEMENT(#19,4);\n#32=IFCDERIVEDUNIT((#31),.MOMENTOFINERTIAUNIT.,$);\n#33=IFCSIUNIT(*,.TIMEUNIT.,$,.SECOND.);\n#34=IFCSIUNIT(*,.FREQUENCYUNIT.,$,.HERTZ.);\n";
    ifc_data += "#35=IFCSIUNIT(*,.THERMODYNAMICTEMPERATUREUNIT.,$,.KELVIN.);\n#36=IFCSIUNIT(*,.THERMODYNAMICTEMPERATUREUNIT.,$,.DEGREE_CELSIUS.);\n#37=IFCDERIVEDUNITELEMENT(#35,-1);\n#38=IFCDERIVEDUNITELEMENT(#33,-3);\n#39=IFCDERIVEDUNIT((#27,#37,#38),.THERMALTRANSMITTANCEUNIT.,$);\n#40=IFCDERIVEDUNITELEMENT(#19,1);\n#41=IFCDERIVEDUNIT((#27,#37,#38,#40),.THERMALCONDUCTANCEUNIT.,$);\n";
    ifc_data += "#42=IFCSIUNIT(*,.LENGTHUNIT.,.DECI.,.METRE.);\n#43=IFCDERIVEDUNITELEMENT(#42,3);\n#44=IFCDERIVEDUNITELEMENT(#33,-1);\n#45=IFCDERIVEDUNIT((#43,#44),.VOLUMETRICFLOWRATEUNIT.,$);\n#46=IFCDERIVEDUNIT((#27,#44),.MASSFLOWRATEUNIT.,$);\n#47=IFCDERIVEDUNIT((#44),.ROTATIONALFREQUENCYUNIT.,$);\n#48=IFCSIUNIT(*,.ELECTRICCURRENTUNIT.,$,.AMPERE.);\n#49=IFCSIUNIT(*,.ELECTRICVOLTAGEUNIT.,$,.VOLT.);\n";
    ifc_data += "#50=IFCSIUNIT(*,.POWERUNIT.,$,.WATT.);\n#51=IFCSIUNIT(*,.FORCEUNIT.,.KILO.,.NEWTON.);\n#52=IFCSIUNIT(*,.ILLUMINANCEUNIT.,$,.LUX.);\n#53=IFCSIUNIT(*,.LUMINOUSFLUXUNIT.,$,.LUMEN.);\n#54=IFCSIUNIT(*,.LUMINOUSINTENSITYUNIT.,$,.CANDELA.);\n#55=IFCSIUNIT(*,.ENERGYUNIT.,$,.JOULE.);\n#56=IFCDERIVEDUNITELEMENT(#26,-1);\n#57=IFCDERIVEDUNITELEMENT(#19,-2);\n#58=IFCDERIVEDUNITELEMENT(#33,3);\n";
    ifc_data += "#59=IFCDERIVEDUNITELEMENT(#53,1);\n#60=IFCDERIVEDUNIT((#56,#57,#58,#59),.USERDEFINED.,'Luminous Efficacy');\n#61=IFCDERIVEDUNITELEMENT(#19,3);\n#62=IFCDERIVEDUNITELEMENT(#48,-2);\n#63=IFCDERIVEDUNIT((#27,#38,#61,#62),.USERDEFINED.,'Electrical Resistivity');\n#64=IFCDERIVEDUNITELEMENT(#19,2);\n#65=IFCDERIVEDUNIT((#27,#38,#64),.SOUNDPOWERUNIT.,$);\n#66=IFCDERIVEDUNITELEMENT(#19,-1);\n";
    ifc_data += "#67=IFCDERIVEDUNITELEMENT(#33,-2);\n#68=IFCDERIVEDUNIT((#27,#66,#67),.SOUNDPRESSUREUNIT.,$);\n#69=IFCDERIVEDUNIT((#40,#44),.LINEARVELOCITYUNIT.,$);\n#70=IFCSIUNIT(*,.PRESSUREUNIT.,$,.PASCAL.);\n#71=IFCDERIVEDUNIT((#27,#57,#67),.USERDEFINED.,'Friction Loss');\n#72=IFCDERIVEDUNIT((#27,#67),.LINEARFORCEUNIT.,$);\n#73=IFCDERIVEDUNIT((#27,#66,#67),.PLANARFORCEUNIT.,$);\n#74=IFCDERIVEDUNIT((#37,#64,#67),.SPECIFICHEATCAPACITYUNIT.,$);\n";
    ifc_data += "#75=IFCDERIVEDUNIT((#27,#38),.HEATFLUXDENSITYUNIT.,$);\n#76=IFCDERIVEDUNIT((#64,#67),.HEATINGVALUEUNIT.,$);\n#77=IFCDERIVEDUNITELEMENT(#33,1);\n#78=IFCDERIVEDUNIT((#66,#77),.VAPORPERMEABILITYUNIT.,$);\n#79=IFCDERIVEDUNIT((#27,#44,#66),.DYNAMICVISCOSITYUNIT.,$);\n#80=IFCDERIVEDUNIT((#37),.THERMALEXPANSIONCOEFFICIENTUNIT.,$);\n#81=IFCDERIVEDUNIT((#27,#66,#67),.MODULUSOFELASTICITYUNIT.,$);\n";
    ifc_data += "#82=IFCDERIVEDUNIT((#56,#61),.ISOTHERMALMOISTURECAPACITYUNIT.,$);\n#83=IFCDERIVEDUNIT((#44,#64),.MOISTUREDIFFUSIVITYUNIT.,$);\n#84=IFCDERIVEDUNIT((#27,#66),.MASSPERLENGTHUNIT.,$);\n#85=IFCDERIVEDUNITELEMENT(#35,1);\n#86=IFCDERIVEDUNIT((#56,#58,#85),.THERMALRESISTANCEUNIT.,$);\n#87=IFCDERIVEDUNIT((#40,#67),.ACCELERATIONUNIT.,$);\n#88=IFCDERIVEDUNITELEMENT(#22,1);\n#89=IFCDERIVEDUNIT((#44,#88),.ANGULARVELOCITYUNIT.,$);\n";
    ifc_data += "#90=IFCDERIVEDUNIT((#27,#67),.LINEARSTIFFNESSUNIT.,$);\n#91=IFCDERIVEDUNITELEMENT(#19,6);\n#92=IFCDERIVEDUNIT((#91),.WARPINGCONSTANTUNIT.,$);\n#93=IFCDERIVEDUNIT((#27,#40,#67),.LINEARMOMENTUNIT.,$);\n#94=IFCDERIVEDUNIT((#27,#64,#67),.TORQUEUNIT.,$);\n#95=IFCUNITASSIGNMENT((#19,#20,#21,#25,#26,#29,#30,#32,#33,#34,#36,#39,#41,#45,#46,#47,#48,#49,#50,#51,#52,#53,#54,#55,#60,#63,#65,#68,#69,#70,#71,#72,#73,#74,#75,#76,#78,#79,#80,#81,#82,#83,#84,#86,#87,#89,#90,#92,#93,#94));\n";

    // Georeference
    ifc_data += "#96=IFCAXIS2PLACEMENT3D(#3,$,$);\n#97=IFCDIRECTION((6.1230317691118863E-17,1.));\n";

    // Geometric representation
    ifc_data += "#98=IFCGEOMETRICREPRESENTATIONCONTEXT($,'Model',3,1.0E-05,#96,#97);\n#99=IFCGEOMETRICREPRESENTATIONSUBCONTEXT('Axis','Model',*,*,*,*,#98,$,.GRAPH_VIEW.,$);\n#100=IFCGEOMETRICREPRESENTATIONSUBCONTEXT('Body','Model',*,*,*,*,#98,$,.MODEL_VIEW.,$);\n#101=IFCGEOMETRICREPRESENTATIONSUBCONTEXT('Box','Model',*,*,*,*,#98,$,.MODEL_VIEW.,$);\n#102=IFCGEOMETRICREPRESENTATIONSUBCONTEXT('FootPrint','Model',*,*,*,*,#98,$,.MODEL_VIEW.,$);\n";

    // Project
    ifc_data += "#103=IFCPROJECT('3MXA8z6T5EeeMW7iI$xcms',#18,'" + capacity?.title + "',$,$,'" + capacity?.title + "','Etude capacitaire',(#98),#95);\n";

    // Site
    ifc_data += "#122=IFCSITE('3MXA8z6T5EeeMW7iI$xcmq',#18,'" + lands_txt + "',$,$,#121," + (conf.land === true ? "#1000" : "$") + ",$,.ELEMENT.,(" + mercator_to_dms_point(center_coords)[1] + "),(" + mercator_to_dms_point(center_coords)[0] + "),0.,$,$);\n#121=IFCLOCALPLACEMENT($,#120);\n#120=IFCAXIS2PLACEMENT3D(#3,$,$);\n#123=IFCRELAGGREGATES('3Kppch9l999ei8KkiDPvZs',#18,$,$,#103,(#122));";

    // Building
    ifc_data += "#124=IFCBUILDING('0$_MKERFLEzP8A49WYesHL',#18,'',$,$,#121,$,'',.ELEMENT.,$,$,#125);\n#125=IFCPOSTALADDRESS($,$,$,$,('Entrer l''adresse ici'),$,'','Paris','','France');\n#126=IFCRELAGGREGATES('0xyPx33kh0UYMjgrks2L1O',#18,$,$,#122,(#124));\n";

    // Level : Ground
    ifc_data += "#127=IFCBUILDINGSTOREY('2Ps44h3395Peo5JgEi7kY5',#18,'TN',$,'Terrain Naturel',#121,$,'TN2',.ELEMENT.,0.);\n#265=IFCRELAGGREGATES('2Ps44h3395Peo5Jg2i7kZB',#18,$,$,#124,(#127));\n";




    // Land
    if (conf.land === true) {
        ifc_data += get_ifc_land(capacity);
    }

    // Topo
    if (conf.topo === true) {
        ifc_data += get_ifc_topo(capacity);
    }

    // Footer
    ifc_data += "ENDSEC;\n\nEND-ISO-10303-21;";

    return ifc_data;
}

const get_ifc_topo = (capacity) => {

    var center_coords = capacity.landBase.union.center.geometry.coordinates;
    var alti = JSON.parse(JSON.stringify(capacity.landBase.topo));
    var ifc_data = "";

    if (capacity?.landBase?.topo?.matrix && capacity?.landBase?.topo?.matrix.length > 0) {

        // Topo Proxy Element
        ifc_data += "#9999=IFCBUILDINGELEMENTPROXY('1qvgwqG6H3nhKKZX704DJ4',#18,'TOPO2',$,'TOPO1',#121,#10000,'253201',$);\n#9998=IFCRELCONTAINEDINSPATIALSTRUCTURE('2Ps44h3395Peo5JgAi7kY5',#18,$,$,(#9999),#127);";
        
        // Topo geometry
        ifc_data += "#10000=IFCPRODUCTDEFINITIONSHAPE($,$,(#10001));\n#10001=IFCSHAPEREPRESENTATION(#10002,'Body','Brep',(#10003));\n#10002=IFCGEOMETRICREPRESENTATIONSUBCONTEXT('Body','Model',*,*,*,*,#98,$,.MODEL_VIEW.,$);\n#10003=IFCFACETEDBREP(#10004);\n"

        // Topo points and faces
        // Create grid of points inside the union at SPACING m each
        var spacing = Math.max(0.0025, ((capacity.landBase.union.area / 800) / 1000));
        var extent = [capacity.landBase.union.bbox.coordinates[0][0][0], capacity.landBase.union.bbox.coordinates[0][0][1], capacity.landBase.union.bbox.coordinates[0][2][0], capacity.landBase.union.bbox.coordinates[0][2][1]];
        // var mask_poly = capacity.landBase.union.geometry;
        var mask_poly = turf.buffer(capacity.landBase.union.geometry, 0.002, { units: 'kilometers' });
        var grid = turf.pointGrid(extent, spacing, { units: 'kilometers', mask: mask_poly });
        // Add bounds points to grid
        for (var i = 0; i < capacity.landBase.union.bounds.length; i++) {
            grid.features.push({
                "type": "Feature",
                "properties": {},
                "geometry": {
                    "type": "Point",
                    "coordinates": capacity.landBase.union.bounds[i].start_coord_global
                }
            })
        }
        // Check
        if (alti.matrix.length !== grid.features.length) {
            console.log("UNABLE TO GENERATE IFC TOPO");
            return "";
        }
        // Set id to each grid element
        for (var i = 0; i < grid.features.length; i++) {
            grid.features[i].properties.id = i;
        }
        // Create triangulated network from grid
        var tin = turf.tin(grid, "id");
        console.log("GRID", grid);
        console.log("TIN", tin);
        var index_start = 10099;
        // Topo points
        for (var i = 0; i < alti.matrix.length; i++) {
            index_start++;
            var point_coords = mercator_to_local_point(alti.matrix[i].cg, center_coords, 100).concat([alti.matrix[i].z]);
            var isInside = turf.booleanPointInPolygon(turf.point(alti.matrix[i].cg), capacity.landBase.union.geometry);
            if (isInside === false) {
                var snapped = turf.nearestPointOnLine(capacity.landBase.union.outerLine, turf.point(alti.matrix[i].cg));
                alti.matrix[i].cg = snapped.geometry.coordinates;
                point_coords = mercator_to_local_point(snapped.geometry.coordinates, center_coords, 100).concat([alti.matrix[i].z]);
            }
            ifc_data += "#" + (index_start).toString() + "=IFCCARTESIANPOINT((" + point_coords[0] + "," + point_coords[1] + "," + Math.max(point_coords[2] - capacity.landBase.topo.point_min_all[2], 0) + "));\n"
        }
        // Topo faces polyloop made of three points
        var topo_faces_list = "";
        var land_buffered = turf.buffer(capacity.landBase.union.geometry, 0.0001, { steps: 2 });
        for (var j = 0; j < tin.features.length; j++) {
            // Check if face is inside land
            var isInside = turf.booleanPointInPolygon(turf.centroid(turf.points([alti.matrix[tin.features[j].properties.a].cg, alti.matrix[tin.features[j].properties.b].cg, alti.matrix[tin.features[j].properties.c].cg])), land_buffered);
            if (isInside === true) {
                ifc_data += "#" + (index_start + 1).toString() + "=IFCPOLYLOOP((#" + (10100 + tin.features[j].properties.a).toString() + ",#" + (10100 + tin.features[j].properties.b).toString() + ",#" + (10100 + tin.features[j].properties.c).toString() + "));\n"
                ifc_data += "#" + (index_start + 2).toString() + "=IFCFACEOUTERBOUND(#" + (index_start + 1).toString() + ",.T.);\n";
                ifc_data += "#" + (index_start + 3).toString() + "=IFCFACE((#" + (index_start + 2).toString() + "));\n";
                index_start = index_start + 3;
                topo_faces_list += "#" + (index_start).toString() + ",";
            }
            else {
                // console.log("Face out of land", j, intersection);
            }
        }
        topo_faces_list = topo_faces_list.substring(0, topo_faces_list.length - 1);
        ifc_data = ifc_data + "#10004=IFCCLOSEDSHELL((" + topo_faces_list + "));\n"

    }

    return ifc_data;
}

const get_ifc_land = (capacity) => {

    var center_coords = capacity.landBase.union.center.geometry.coordinates;
    var ifc_data = "";

    if (capacity?.landBase?.union?.outerLine?.geometry?.type === "LineString" && capacity?.landBase?.union?.geometry?.geometry?.type === "Polygon") {

        // Land geometry
        ifc_data += "#1000=IFCPRODUCTDEFINITIONSHAPE($,$,(#1001,#1500));\n"

        // Land faces
        var triangles = turf.tesselate(capacity.landBase.union.geometry);
        var land_faces_coords = [];
        triangles.features.forEach(triangle => {
            var face_coords = [];
            for (var i = 0; i <= 2; i++) {
                face_coords.push(mercator_to_local_point(triangle.geometry.coordinates[0][i], center_coords, 100).concat([0]));
            }
            land_faces_coords.push(face_coords);
        })
        console.log("land_faces_coords", land_faces_coords);
        var index_start = 1004;
        var land_faces_list = "";
        for (var i = 0; i < land_faces_coords.length; i++) {
            // Land face points
            for (var j = 0; j < land_faces_coords[i].length; j++) {
                index_start++;
                ifc_data += "#" + (index_start).toString() + "=IFCCARTESIANPOINT((" + land_faces_coords[i][j][0] + "," + land_faces_coords[i][j][1] + "," + land_faces_coords[i][j][2] + "));\n"
            }
            // Land face polyloop made of three points
            ifc_data += "#" + (index_start + 1).toString() + "=IFCPOLYLOOP((#" + (index_start - 2).toString() + ",#" + (index_start - 1).toString() + ",#" + (index_start).toString() + "));\n"
            ifc_data += "#" + (index_start + 2).toString() + "=IFCFACEOUTERBOUND(#" + (index_start + 1).toString() + ",.T.);\n";
            ifc_data += "#" + (index_start + 3).toString() + "=IFCFACE((#" + (index_start + 2).toString() + "));\n";
            index_start = index_start + 3;
            land_faces_list += "#" + (index_start).toString();
            if (i < land_faces_coords.length - 1) { land_faces_list += "," }
        }

        // Land shape body
        ifc_data += "#1001=IFCSHAPEREPRESENTATION(#1002,'Body','SurfaceModel',(#1003));\n#1002=IFCGEOMETRICREPRESENTATIONSUBCONTEXT('Body','Model',*,*,*,*,#98,$,.MODEL_VIEW.,$);\n#1003=IFCFACEBASEDSURFACEMODEL((#1004));\n#1004=IFCCONNECTEDFACESET((" + land_faces_list + "));\n"

        // Land points bound
        var land_points_bound_coords = [];
        capacity.landBase.union.outerLine.geometry.coordinates.forEach(coords => {
            land_points_bound_coords.push(mercator_to_local_point(coords, center_coords, 100));
        })
        index_start = 1502;
        var land_points_bound_list = "";
        var land_point_bound_first = "";
        // Land footprint point
        for (var i = 0; i < land_points_bound_coords.length - 1; i++) {
            index_start++;
            ifc_data += "#" + (index_start).toString() + "=IFCCARTESIANPOINT((" + land_points_bound_coords[i][0] + "," + land_points_bound_coords[i][1] + "));\n"
            land_points_bound_list += "#" + (index_start).toString() + ",";
            if (i === 0) { land_point_bound_first = "#" + (index_start).toString() }
        }
        land_points_bound_list += land_point_bound_first;

        // Land shape footprint
        ifc_data += "#1500=IFCSHAPEREPRESENTATION(#1501,'FootPrint','Curve2D',(#1502));\n#1501=IFCGEOMETRICREPRESENTATIONSUBCONTEXT('FootPrint','Model',*,*,*,*,#98,$,.MODEL_VIEW.,$);\n#1502=IFCPOLYLINE((" + land_points_bound_list + "));\n"

    }

    return ifc_data
}