import React from 'react';

// Import CSS & Assets
import './Popup.css';
import popup_angle from '../../../assets/popup_angle.svg';


const Popup = ({ popupData, hidePopup }) => {
    return (
        <div className={`popup__container ${popupData?.type === "release" ? "popup__container_release" : ""} ${popupData?.isVisible === false ? "popup__container_hidden" : ""}`}>
            {popupData?.params?.title &&
                <div className="popup__title">{popupData.params.title}</div>
            }
            {popupData?.params?.description &&
                <div className="popup__text">{popupData.params.description}</div>
            }
            <div className="popup__bottom_part">
                <div className="popup__bottom_btn" onClick={() => { hidePopup(popupData) }}>Ok, c'est noté</div>
            </div>
            {popupData?.type === "release" &&
                <img className="popup__angle" src={popup_angle} />
            }
        </div>
    );
};

export default Popup;