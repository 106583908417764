import React from 'react';

// Import CSS & Assets
import './Land.css';
import logo_card from '../../../../assets/logo_card.svg';
import close from '../../../../assets/ico/ico_close.svg';

// Import external functions
import * as map_helpers from '../../map/Map_helpers';
import * as helpers from '../../../../helpers/Other_helpers';

const Land = ({ land, index, user, areaType }) => {

    // Get land label
    var section = land.properties.section;
    if (section.substring(0, 1) === "0") { section = section.substring(1) };
    var label = "Parcelle " + section + "-" + parseInt(land.properties.numero);

    // Get GPU link code
    var code = land.properties.id.substring(0, 2) + "_" + land.properties.id.substring(2, 5) + "_000_" + land.properties.id.substring(5, 8) + "_" + land.properties.id.substring(8, 10) + "_" + land.properties.id.substring(10, 14);
    var gpu = "https://www.geoportail-urbanisme.gouv.fr/map/parcel-info/" + code;
    var societecom = "";
    if (land?.majic) {
        // societecom = "https://www.societe.com/societe/" + land.majic.denomination_proprietaire.replace(/ /g, "-") + "-" + land.majic.siren_proprietaire + ".html";
        societecom = "https://www.pappers.fr/entreprise/" + land.majic.denomination_proprietaire.replace(/ /g, "-") + "-" + land.majic.siren_proprietaire;
    }

    return (
        <div className="app__sectiondata__widget app__land_container">
            {/* <div className="app__land_img_container">
                {(land?.geometry?.type && land?.geometry?.type === "Polygon") ?
                    // <div>IMG</div>
                    <svg width="45" height="45" viewBox="-10 -10 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                        {land.geometry.coordinates[0].map((item, index) => {
                            // Get variables
                            var svgWidth = 45;
                            var scaleFactor = svgWidth / capaData.landBase.union.bbox.lengthH;
                            var verticalOffset = (svgWidth - (capaData.landBase.union.bbox.lengthV * scaleFactor)) / 2;
                            return (<>
                                <line key={index} x1={item.start_coord_local[0] * scaleFactor} y1={svgWidth - (item.start_coord_local[1] * scaleFactor) - verticalOffset} x2={item.end_coord_local[0] * scaleFactor} y2={svgWidth - (item.end_coord_local[1] * scaleFactor) - verticalOffset} stroke="white" strokeWidth="8" />
                                <circle cx={item.start_coord_local[0] * scaleFactor} cy={svgWidth - (item.start_coord_local[1] * scaleFactor) - verticalOffset} r="14" fill="white" />
                            </>)
                        })}
                    </svg>
                    :
                    <img src={logo_card} alt="" />
                }
            </div> */}
            <div className="app__land_data_container">
                <div className="app__land_data_main_container">
                    <div className="app__land_data_main_label">{label}</div>
                    {/* <div className="app__land_data_main_close">
                        <img src={close} alt="close_land" />
                    </div> */}
                </div>
                <div className="app__land_data_detail_container">
                    <div className="app__land_data_detail_box">
                        <div className="app__land_data_detail_title">ID Cadastre</div>
                        <div className="app__land_data_detail_value">{land.properties.id}</div>
                    </div>
                    <div className="app__land_data_detail_box">
                        <div className="app__land_data_detail_title">Section</div>
                        <div className="app__land_data_detail_value">{land.properties.section}</div>
                    </div>
                    <div className="app__land_data_detail_box">
                        <div className="app__land_data_detail_title">Numéro</div>
                        <div className="app__land_data_detail_value">{land.properties.numero}</div>
                    </div>
                    <div className="app__land_data_detail_box">
                        <div className="app__land_data_detail_title">Surface</div>
                        {areaType === "cadastre" ?
                            <div className="app__land_data_detail_value">{helpers.get_pretty_num((land?.properties?.contenance || 0).toFixed(0))} m²</div>
                            :
                            <div className="app__land_data_detail_value">{helpers.get_pretty_num((land?.area || 0).toFixed(0))} m²</div>
                        }
                    </div>
                    {window.innerWidth > 550 &&
                        <div className="app__land_data_detail_box">
                            <div className="app__land_data_detail_title">Fiche détaillée</div>
                            <div className="app__land_data_button"><a href={gpu} target="_blanck">Géoportail de l'Urbanisme</a></div>
                        </div>
                    }
                </div>
                {(land?.majic && user?.access?.land === true) &&
                    <>
                        <div className="app__sectiondata_widget_spacer_10"></div>
                        <div className="app__land_data_detail_container" style={{ borderTop: '1px solid lightgrey', paddingTop: '4px' }}>
                            <div className="app__land_data_detail_box">
                                <div className="app__land_data_detail_title">Propriétaire</div>
                                <div className="app__land_data_detail_value">Nom : <strong>{land.majic.denomination_proprietaire}</strong></div>
                                <div className="app__land_data_detail_value">Type : {land.majic.groupe_proprietaire}</div>
                                <div className="app__land_data_detail_value">Forme juridique : {land.majic.abrev_forme_juridique_proprietaire} - {land.majic.label_forme_juridique_proprietaire} • Siren : {land.majic.siren_proprietaire}</div>
                                {/* <div className="app__land_data_detail_value">Siren : <a href={societecom} target="_blanck">{land.majic.siren_proprietaire}</a></div> */}
                                <div className="app__land_data_button" style={{ width: "115px" }}><a href={societecom} target="_blanck">Ouvrir sur Pappers</a></div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default Land;