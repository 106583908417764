import React from 'react';

// Import CSS & Assets
import './Location.css';
import bg_filter_1 from '../../../../assets/location_bg_filter_1.svg';

const Location = ({ capacity, setCapacity }) => {

    if (capacity?.landBase?.location && capacity.landBase.location !== "fetching" && capacity.landBase.location !== "error") {
        var coords = capacity?.landBase?.union?.center?.geometry?.coordinates;
        var bearing = 0;
        if (capacity?.landBase?.union?.roads_limits && capacity?.landBase?.union?.roads_limits.length > 0) {
            coords = capacity?.landBase?.union?.roads_limits[0]?.road_point;
            bearing = capacity?.landBase?.union?.roads_limits[0]?.bearing;
        }
        var streeView_URL = 'http://maps.google.com/maps?q=&layer=c&cbll=' + coords[1].toString(10) + ',' + coords[0].toString(10) + '&cbp=11,' + bearing.toString(10) + ',0,0,-2';
        return (
            <>
                <div id="section_0_location" className="app__sectiondata__widget app__location_container">
                    <div className="app__sectiondata_widget_title">Localisation</div>
                    <div className="app__location_body">
                        <div className="app__location_label_container">
                            <div className="app__location_label_1">{capacity.landBase.location.properties.name}</div>
                            <div className="app__location_label_2">{capacity.landBase.location.properties.postcode} {capacity.landBase.location.properties.city}</div>
                            <div className="app__location_label_3">{capacity.landBase.location.properties.context}</div>
                        </div>
                        <div className="app__location_button_container">
                            <div className="app__location_button"><a href={streeView_URL} target="_blanck">{window.innerWidth > 550 ? "Ouvrir dans Google Street View" : "Street View"}</a></div>
                        </div>
                    </div>
                    {capacity.landBase.location?.panoid &&
                        <>
                            <img className="app__location_background_filter_1" src={bg_filter_1} alt="bg_filter_1" />
                            <img className="app__location_background" src={"https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=" + capacity.landBase.location.panoid + "&w=640&h=150&yaw=" + bearing + "&pitch=-2&thumbfov=100"} alt="location_pano" />
                        </>
                    }
                </div>
                <div className="app__sectiondata_widget_spacer"></div>
            </>
        );
    }
};

export default Location;