import React, { useState, useEffect } from 'react';

// Import CSS & assets
import ico_delete from '../../../assets/ico/ico_delete.svg';

const Rule = ({ style, rule, user, currentType, showUser, isPopular, rule_Add, rule_Modify, rule_Delete }) => {

    const [userCreation, setUserCreation] = useState({
        name: "",
        img: ""
    });
    useEffect(() => {
        if (showUser === true) {
            get_creationUser();
        }
    }, [rule.creatorId]);
    const get_creationUser = () => {
        // Get user creation
        if (rule.creatorId === user?.result?._id) {
            setUserCreation({
                name: "vous",
                img: user?.result?.avatar
            });
        }
        else {
            var user_creation = null;
            if (user.users && user.users.length > 0) {
                for (var i = 0; i < user.users.length; i++) {
                    if (rule.creatorId === user.users[i]["_id"]) {
                        user_creation = user.users[i];
                    }
                }
            }
            if (user_creation !== null) {
                setUserCreation({
                    name: user_creation.firstName,
                    img: user_creation.avatar
                });
            }
        }
    }

    const [isDelete, setIsDelete] = useState(false);

    const handleRuleDelete = () => {
        rule_Delete(rule);
        setIsDelete(false);
    }


    const [isCopiedRule, setIsCopiedRule] = useState(false);
    const rule_Copy = () => {
        var rule_string = JSON.stringify(rule);
        navigator.clipboard.writeText(rule_string);
        setIsCopiedRule(true);
        setTimeout(() => {
            setIsCopiedRule(false);
        }, 1000);
    }


    return (
        <div style={style} className={isPopular ? "app__rulecatalog_rule_container_pop" : "app__rulecatalog_rule_container"} onMouseLeave={() => { setIsDelete(false) }}>
            <div className={isPopular ? "app__rulecatalog_rule_title_pop" : "app__rulecatalog_rule_title"}>
                {rule.title}
            </div>
            <div className="app__rulecatalog_rule_box">
                <div className="app__rulecatalog_rule_body">
                    <div className={isPopular ? "app__rulecatalog_rule_description_pop" : "app__rulecatalog_rule_description"}>
                        {rule?.description_splitted ?
                            // rule.description_splitted.join("")
                            <>
                                {rule.description_splitted.map((text, index) => {
                                    if (index % 2 === 0) {
                                        return (
                                            <span key={"span_" + index}>{text}</span>
                                        )
                                    }
                                    else {
                                        return (
                                            <span key={"span_" + index} className="app__rulecatalog_rule_description_variable">{text}</span>
                                        )
                                    }
                                })}
                            </>
                            :
                            rule.description
                        }
                    </div>
                    {/* USER INFOS */}
                    {showUser &&
                        <div className="app__rulecatalog_footer">
                            <div className="app__card_bottom_footer_userpp">
                                <img src={userCreation.img} alt="user_pp" />
                            </div>
                            <div className="app__card_bottom_footer_userinfos">
                                <div className="app__rulecatalog_footer_userinfos_time">
                                    Crée par
                                </div>
                                <div className="app__rulecatalog_footer_userinfos_name">
                                    {userCreation.name}
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="app__rulecatalog_rule_body_right" style={{minHeight: showUser ? '100px' : '40px'}}>
                    {rule?.image &&
                        <div className={isPopular ? "app__rulecatalog_rule_image_container_pop" : "app__rulecatalog_rule_image_container"}><img src={rule?.image} /></div>
                    }
                    <div className={isPopular ? "app__rulecatalog_rule_button_container_pop" : "app__rulecatalog_rule_button_container"}>
                        <div className={isPopular ? "app__rulecatalog_rule_button_pop" : "app__rulecatalog_rule_button"} onClick={() => { rule_Add(rule) }}>{currentType === "rule" ? "Ajouter à l'étude" : "Ajouter à la règle"}</div>
                        {showUser &&
                            <>
                                <div className="app__rulecatalog_rule_button_box">
                                    <div className="app__rulecatalog_rule_button" onClick={() => { rule_Modify(rule) }}>Modifier</div>
                                    <div className="app__rulecatalog_rule_button button_red_color" style={{ width: "30px" }} onClick={() => { setIsDelete(true) }}><img src={ico_delete} alt="delete" /></div>
                                </div>
                                {user?.result?.email.includes("@fractal-buildings.com") || user?.result?.email.includes("@proplab.fr") &&
                                    <div className="app__rulecatalog_rule_button_box">
                                        <div className="app__rulecatalog_rule_button_small" onClick={() => { rule_Copy() }}>{isCopiedRule ? "✔" : "Copier le JSON"}</div>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
                {isDelete &&
                    <div className="app__rulecatalog_rule_delete_container">
                        <div className="app__bound_type_modify_title_big">{currentType === "rule" ? "Supprimer cette règle ?" : currentType === "condition" ? "Supprimer cette condition ?" : "Supprimer ce périmètre ?"}</div>
                        <div className="app__rulecatalog_rule_delete_container_buttons">
                            <div className="app__landsvalidation_popup_button" onClick={() => { setIsDelete(false) }}>Conserver</div>
                            <div className="app__landsvalidation_popup_button_second" onClick={() => { handleRuleDelete() }}>Supprimer</div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Rule;