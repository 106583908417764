import React from 'react';

// Import CSS & Assets
import check from '../../../assets/ico/ico_toaster_check.svg';
import error from '../../../assets/ico/ico_toaster_error.svg';

const Toaster = ({ item }) => {
    return (
        <div className={`toaster__container ${item?.status === "done" ? "toaster__container_done" : ""} ${item?.status === "" ? "toaster__container_hidden" : ""} ${item?.style === "error" ? "toaster__container_error" : ""} slide-left`}>
            <div className="toaster__title">{item?.title}</div>
            <div id="toaster__text" className="toaster__text">{item?.text}</div>
            {item?.type === "progress" &&
                <div className="toaster__bottom">
                    <div className="toaster__progress_container">
                        <div className={`toaster__progress_bar ${(item?.status === "success" || item?.status === "error" || item?.status === "done") ? "toaster__progress_bar_full" : ""}`}></div>
                        <div className="toaster__progress_bar_ph"></div>
                    </div>
                    <div className="toaster__progress_icon">
                        {item?.status === "loading" ?
                            <div className="toaster__progress_loading"></div>
                            :
                            item?.status === "error" ?
                                <img src={error} alt="" />
                                :
                                <img src={check} alt="" />
                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default Toaster;