import React from 'react';

// Import components
import Toaster from './Toaster';

// Import CSS & Assets
import './Toaster.css';

const ToasterContainer = ({ toastersData }) => {
    return (
        <div className="toaster__container_main">
            {Object.keys(toastersData).map((toasterId, index) => {
                if (toastersData[toasterId]?.status !== "closed") {
                    return (
                        <Toaster key={index} item={toastersData[toasterId]} />
                    )
                }
            })}
        </div>
    );
};

export default ToasterContainer;