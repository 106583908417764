// Import dependencies
import React from 'react';

// Import CSS & assets
import './Partners.css';
import logo_ec from '../../../assets/logo/logo_etonnantscreateurs.svg';
import logo_fpt from '../../../assets/logo/logo_frenchproptech.svg';
import logo_nexity from '../../../assets/logo/logo_nexity.svg';

const Partners = () => {
    return (
        <div className="landing__partners">
            <div className="landing__partners_container">
                <img src={logo_ec} alt="logo_ec" />
            </div>
            <div className="landing__partners_container">
                <img src={logo_fpt} alt="logo_fpt" />
            </div>
            {/* <div className="landing__partners_container">
                <img src={logo_nexity} alt="logo_nexity" />
            </div> */}
        </div>
    );
};

export default Partners;