import React, { useState } from 'react';


const HeatNetwork = ({ capacity }) => {

    return (
        <>
            <div className="app__sectiondata_widget_spacer_50"></div>
            <div id="section_1_heatnetwork" className="app__sectiondata_widget_title">Raccordement à un réseau de chauffage urbain</div>
            <div className="app__sectiondata__widget">
                <div>Le terrain <strong>est éligible</strong> pour le raccordement à un réseau de chaleur urbain.</div>
                <div className="app__sectiondata_widget_spacer_15"></div>
                <div className="app__sectiondata_widget_title" style={{ marginBottom: '6px' }}>Détails du réseau</div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {(capacity?.landBase?.location?.chaleur?.name && capacity?.landBase?.location?.chaleur?.id) &&
                        <div>
                            <div className='app__land_data_detail_title'>Nom et ID du réseau</div>
                            <div className='app__land_data_detail_value'>{capacity?.landBase?.location?.chaleur?.name} ({capacity?.landBase?.location?.chaleur?.id})</div>
                        </div>
                    }
                    {capacity?.landBase?.location?.chaleur?.gestionnaire &&
                        <div>
                            <div className='app__land_data_detail_title'>Gestionnaire</div>
                            <div className='app__land_data_detail_value'>{capacity?.landBase?.location?.chaleur?.gestionnaire}</div>
                        </div>
                    }
                    {/* {capacity?.landBase?.location?.chaleur?.distance &&
                                        <div>
                                            <div className='app__land_data_detail_title'>Distance du terrain</div>
                                            <div className='app__land_data_detail_value'>{other_helpers.get_pretty_num(capacity?.landBase?.location?.chaleur?.distance.toFixed(0))} m</div>
                                        </div>
                                    } */}
                    {(capacity?.landBase?.location?.chaleur?.inPDP === true || capacity?.landBase?.location?.chaleur?.inPDP === false) &&
                        <div>
                            <div className='app__land_data_detail_title'>Obligation de raccordement</div>
                            <div className='app__land_data_detail_value'>{capacity?.landBase?.location?.chaleur?.inPDP ? "Oui pour certains bâtiments" : "Non"}</div>
                        </div>
                    }
                    {capacity?.landBase?.location?.chaleur?.id &&
                        <div>
                            <div className='app__land_data_detail_title'>Fiche détaillée</div>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <div className="app__land_data_button" style={{ width: "80px" }}><a href={"https://france-chaleur-urbaine.beta.gouv.fr/reseaux/" + capacity?.landBase?.location?.chaleur?.id} target="_blanck">Voir plus</a></div>
                            </div>
                        </div>
                    }
                </div>
                {capacity?.landBase?.location?.chaleur?.inPDP === true &&
                    // <div>Le terrain se situe dans le périmètre de développement prioritaire d'un réseau classé. <strong>Le raccordement à ce réseau est obligatoire pour certains bâtiments.</strong></div>
                    <div><strong>Le raccordement à ce réseau est obligatoire pour certains bâtiments.</strong></div>
                }
                {/* inPDP:
                                type: boolean
                                description: true si l'adresse testée se situe dans le périmètre de développement prioritaire d'un réseau classé (zone où s'applique une obligation de raccordement pour certains bâtiments) */}
            </div>
            <div className="app__sectiondata_widget_spacer_10"></div>
            <div className="app__sectiondata_widget_info_container">
                <div className="app__sectiondata_widget_info_text">
                    Source : France-Chaleur-Urbaine.gouv
                </div>
                <div className="app__sectiondata_widget_info_icon">i</div>
            </div>
        </>
    );
};

export default HeatNetwork;