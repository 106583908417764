// Import dependencies
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { capaGetById, capaUpdate } from '../../../actions/capa';
import { rulesGetByTeamId } from '../../../actions/teams';
import moment from 'moment';
import 'moment/locale/fr.js';
import * as turf from '@turf/turf';

// Import components
import Header from '../../../components/app/header/Header';
import Navbar from '../../../components/app/navbar/Navbar';
import Map from '../../../components/app/map/Map';
import ErrorPage from '../../../components/common/errorPage/ErrorPage';
import ToasterContainer from '../../../components/common/toaster/ToasterContainer';
import { TitleAndDescription, Location, Exportation, ExportationMenuPDF, ExportationMenuIFC, Modifications } from '../../../components/app/capaStudy_elements';
import { Geocoder } from '../../../components/app/capaStudy_elements';
import { LandsDetail, LandsValidation, BoundsDetail, Topo, Trees, Buildings, HeatNetwork } from '../../../components/app/capaStudy_elements';
import { Risks } from '../../../components/app/capaStudy_elements';
import { Context } from '../../../components/app/capaStudy_elements';
import { List } from '../../../components/app/capaStudy_elements';
import { BuildableDetails } from '../../../components/app/capaStudy_elements';
import { Combinations } from '../../../components/app/capaStudy_elements';
import RuleCatalog from '../../../components/app/ruleCatalog_elements/RuleCatalog';

// Import CSS & Assets
import './CapaStudy.css';
import logo_card from '../../../assets/logo.svg';
import ico_home_outline from '../../../assets/ico/ico_home_outline.svg';
import ico_home_fill from '../../../assets/ico/ico_home_fill.svg';
import ico_land_outline from '../../../assets/ico/ico_land_outline.svg';
import ico_land_fill from '../../../assets/ico/ico_land_fill.svg';
import ico_risk_outline from '../../../assets/ico/ico_risk_outline.svg';
import ico_risk_fill from '../../../assets/ico/ico_risk_fill.svg';
import ico_stats_outline from '../../../assets/ico/ico_stats_outline.svg';
import ico_stats_fill from '../../../assets/ico/ico_stats_fill.svg';
import ico_rules_outline from '../../../assets/ico/ico_rules_outline.svg';
import ico_rules_fill from '../../../assets/ico/ico_rules_fill.svg';
import ico_capa_outline from '../../../assets/ico/ico_capa_outline.svg';
import ico_capa_fill from '../../../assets/ico/ico_capa_fill.svg';
import ico_combi_outline from '../../../assets/ico/ico_combi_outline.svg';
import ico_combi_fill from '../../../assets/ico/ico_combi_fill.svg';
import ico_reload from '../../../assets/ico/ico_reload.svg';

// Import external functions
import * as map_helpers from '../../../components/app/map/Map_helpers';
import * as fetch_helpers from '../../../helpers/Fetch_helpers';
import * as buildable_helpers from '../../../helpers/Buildable_helpers';
import * as combination_helpers from '../../../helpers/Combination_helpers';
import * as other_helpers from '../../../helpers/Other_helpers';
import * as node_helpers from '../../../components/app/ruleEditor_elements/helpers/NodeHelpers';
import * as global from '../../../global.js';


// Toaster
var toasterData_all = {
    land: { title: "Données terrain", text: "Analyse des données du terrain : limites, topographie et constructions exitantes.", type: "progress", style: "default", status: "closed" },
    risks: { title: "Risques", text: "Analyse des risques rattachés au terrain : risques naturels et industriels, sismicité, sols argileux, canalisations et pollution du sol.", type: "progress", style: "default", status: "closed" },
    context: { title: "Données contextuelles", text: "Analyse des données contextuelles du terrain : statistiques de population, marché immobilier et présence de monuments historiques.", type: "progress", style: "default", status: "closed" },
    gpu: { title: "Données PLU", text: "Récupération des données d'urbanisme issues du Géoportail de l'Urbanisme.", type: "progress", style: "default", status: "closed" },
    buildable: { title: "Capacité constructible", text: "Calcul de la capacité constructible du terrain.", type: "progress", style: "default", status: "closed" },
    combinations: { title: "Simulations de construction", text: "Génération des simulations de construction sur le terrain.", type: "progress", style: "default", status: "closed" },
    save: { title: "Enregistrement", text: "Enregistrement des dernières modifications apportées à l'étude.", type: "progress", style: "default", status: "closed" },
    message: { title: "", text: "", type: "", style: "", status: "closed" },
}
// Reload
var reloading = null;
// Async save
var save_counter = -1;

const CapaStudy = ({ version, environment }) => {

    // Set moment to french time
    moment.locale('fr');

    // ANALYSE JSON SIZE
    const optimizeCapa = (capa) => {

        var capa_optim = other_helpers.compressCapa(capa);
        getCapaSize(capa_optim);

    }

    const getCapaSize = (capa) => {

        // TEST SIZE

        // const size_full = JSON.stringify(capa).length;
        // console.log("FULL size :", size_full);

        // var size_details = [];
        // var size_landbase = 1;
        // var size_context = 1;
        // var size_rules = 1;

        // for (var key of Object.keys(capa)) {
        //     const size_1 = JSON.stringify(capa[key]).length;
        //     // console.log(key + " size : " + size_1 + " (" + Math.round(100 * size_1 / size_full) + "%)");
        //     size_details.push({ 'Key': key, 'Size': size_1, 'Percentage': Math.round(100 * size_1 / size_full) });
        //     if (key === "landBase") { size_landbase = size_1 }
        //     if (key === "context") { size_context = size_1 }
        //     if (key === "rules") { size_rules = size_1 }
        // }

        // console.table(size_details);


        // LANDBASE
        // var size_details_landbase = [];

        // for (var key of Object.keys(capa.landBase)) {
        //     const size_1 = JSON.stringify(capa["landBase"][key]).length;
        //     // console.log(key + " size : " + size_1 + " (" + Math.round(100 * size_1 / size_full) + "%)");
        //     size_details_landbase.push({ 'Key': key, 'Size': size_1, 'Percentage': Math.round(100 * size_1 / size_landbase) });
        // }

        // console.log("LANDBASE details");
        // console.table(size_details_landbase);

        // LANDBASE > BUILDINGS
        // var size_details_landbase_buildings = [];
        // if (capa?.landBase?.buildings?.buildings) {
        //     for (var key of Object.keys(capa.landBase.buildings.buildings[0])) {
        //         const size_1 = JSON.stringify(capa["landBase"]["buildings"]["buildings"][0][key]).length;
        //         // console.log(key + " size : " + size_1 + " (" + Math.round(100 * size_1 / size_full) + "%)");
        //         size_details_landbase_buildings.push({ 'Key': key, 'Size': size_1, 'Percentage': Math.round(100 * size_1 / size_landbase) });
        //     }
        // }

        // console.log("LANDBASE BUILDINGS details");
        // console.table(size_details_landbase_buildings);

        // LANDBASE > UNION
        // var size_details_landbase_union = [];

        // for (var key of Object.keys(capa.landBase.union)) {
        //     const size_1 = JSON.stringify(capa["landBase"]["union"][key]).length;
        //     // console.log(key + " size : " + size_1 + " (" + Math.round(100 * size_1 / size_full) + "%)");
        //     size_details_landbase_union.push({ 'Key': key, 'Size': size_1, 'Percentage': Math.round(100 * size_1 / size_landbase) });
        // }

        // console.log("LANDBASE UNION details");
        // console.table(size_details_landbase_union);

        // LANDBASE > UNION > BOUNDS GROUP
        // var size_details_landbase_union_bounds = [];

        // for (var key of Object.keys(capa.landBase.union.bounds_groups[0])) {
        //     const size_1 = JSON.stringify(capa["landBase"]["union"]["bounds_groups"][0][key]).length;
        //     // console.log(key + " size : " + size_1 + " (" + Math.round(100 * size_1 / size_full) + "%)");
        //     size_details_landbase_union_bounds.push({ 'Key': key, 'Size': size_1, 'Percentage': Math.round(100 * size_1 / size_landbase) });
        // }

        // console.log("LANDBASE UNION BOUNDS details");
        // console.table(size_details_landbase_union_bounds);


        // CONTEXT
        // var size_details_context = [];

        // if (capa?.context) {
        //     for (var key of Object.keys(capa.context)) {
        //         const size_1 = JSON.stringify(capa["context"][key]).length;
        //         // console.log(key + " size : " + size_1 + " (" + Math.round(100 * size_1 / size_full) + "%)");
        //         size_details_context.push({ 'Key': key, 'Size': size_1, 'Percentage': Math.round(100 * size_1 / size_context) });
        //     }

        //     console.log("CONTEXT details");
        //     console.table(size_details_context);
        // }


        // // RULES
        // var size_details_rules = [];

        // if (capa?.rules) {
        //     for (var key of Object.keys(capa.rules)) {
        //         const size_1 = JSON.stringify(capa["rules"][key]).length;
        //         // console.log(key + " size : " + size_1 + " (" + Math.round(100 * size_1 / size_full) + "%)");
        //         size_details_rules.push({ 'Key': key, 'Size': size_1, 'Percentage': Math.round(100 * size_1 / size_rules) });
        //     }

        //     console.log("RULES details");
        //     console.table(size_details_rules);
        // }

        // // RULES > GPU DATA
        // var size_details_rules_gpu = [];

        // if (capa?.rules?.gpu_data) {
        //     for (var key of Object.keys(capa.rules.gpu_data)) {
        //         const size_1 = JSON.stringify(capa["rules"]["gpu_data"][key]).length;
        //         // console.log(key + " size : " + size_1 + " (" + Math.round(100 * size_1 / size_full) + "%)");
        //         size_details_rules_gpu.push({ 'Key': key, 'Size': size_1, 'Percentage': Math.round(100 * size_1 / size_rules) });
        //     }

        //     console.log("RULES GPU details");
        //     console.table(size_details_rules_gpu);
        // }


        // CONTEXT > DVF
        // var size_details_context_dvf = [];

        // for (var key of Object.keys(capa.context.dvf)) {
        //     const size_1 = JSON.stringify(capa["context"]["dvf"][key]).length;
        //     // console.log(key + " size : " + size_1 + " (" + Math.round(100 * size_1 / size_full) + "%)");
        //     size_details_context_dvf.push({ 'Key': key, 'Size': size_1, 'Percentage': Math.round(100 * size_1 / size_context) });
        // }

        // console.log("CONTEXT DVF details");
        // console.table(size_details_context_dvf);
    }

    // TEST LIST
    const [categories, setCategories] = useState([
        {
            id: "q101",
            name: "Réglement littéral de test",
            items: []
        },
    ]);

    const [isRuleCatalogOpen, setIsRuleCatalogOpen] = useState(false);
    // useEffect(() => {
    //     console.log("-UPDATE RULE CATALOG-", isRuleCatalogOpen);
    // }, [isRuleCatalogOpen]);


    // ERROR MANAGEMENT
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const createError = () => {
        console.log("CREATE ERROR");
        setIsError(true);
    }
    const setErrorPage = (errorMessage) => {
        setErrorMsg(errorMessage);
        setIsError(true);
    }


    // TOASTER MANAGEMENT
    const [toastersData, setToastersData] = useState(toasterData_all);
    const handleToaster = (actionList) => {
        // console.log("HANDLE TOASTER", actionList);
        // var oldData = { ...toastersData };
        var oldData = JSON.parse(JSON.stringify(toasterData_all));
        actionList.forEach(action => {
            // Update toaster elements
            if (action?.title) { oldData[action.key].title = action.title }
            if (action?.text) { oldData[action.key].text = action.text }
            if (action?.style) { oldData[action.key].style = action.style }
            if (action?.status) { oldData[action.key].status = action.status }
            // Set timeouts
            if (action.status === "success") {
                setTimeout(() => {
                    handleToaster([{ key: action.key, status: "done" }]);
                }, 3000);
            }
            else if (action.status === "info" || action.status === "error") {
                setTimeout(() => {
                    handleToaster([{ key: action.key, status: "done" }]);
                }, 6000);
            }
            else if (action.status === "done") {
                setTimeout(() => {
                    handleToaster([{ key: action.key, status: "closed" }]);
                }, 3000);
            }
        })
        toasterData_all = oldData;
        setToastersData(oldData);
    }
    // useEffect(() => {
    // console.log("toastersData", toastersData);
    // }, [toastersData]);



    // MAP UPDATE MANAGEMENT
    const [globalUpdateTracker, setGlobalUpdateTracker] = useState({ value: 0, item: "" });

    const [mapDrawMode, setMapDrawMode] = useState({ mode: null, data: null, target: null });
    useEffect(() => {
        // console.log("mapDrawMode", mapDrawMode);
    }, [mapDrawMode]);


    // Set body color
    // document.body.style.background = "#f5f5f5";

    // Navigate & Dispatch
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // __________________________________ GLOBAL STUDY VARIABLES
    const [targetSection, setTargetSection] = useState(null);
    const [capacity, setCapacity] = useState();
    const [isAccessDenied, setIsAccessDenied] = useState(false);
    // Get Capa ID from URL
    const { id } = useParams();
    // Fetch Capa data
    // useEffect(() => {
    //     console.log("-FETCHING CAPA- : Loading...");
    //     dispatch(capaGetById(id, handleCapaGetSuccess, handleCapaGetFail));
    // }, []);
    const getCapacity = () => {
        console.log("-FETCHING CAPA- : Loading...");
        var user = JSON.parse(localStorage.getItem('profile'));
        // Check if user is connected and is inside a team
        if (user?.teams && user?.teams.length > 0) {
            dispatch(capaGetById(id, user?.teams[0]?._id, handleCapaGetSuccess, handleCapaGetFail));
        }
    }
    // Get response from Capa fetching : success
    const handleCapaGetSuccess = (data) => {
        console.log("-FETCHING CAPA- : Success", data);
        data.toSave = { autoSave: false, action: "", value: "" };
        // Add new data if needed
        if (data?.buildable?.volume?.parameters && !data?.buildable?.volume?.parameters?.coef_area_to_shabsu) {
            data.buildable.volume.parameters.coef_area_to_shabsu = 0.7;
        }
        // Update capacity hook
        setCapacity(data);
        // Fetch rules
        getRules();
        // Interpret ThreeJS Objects
        if (data?.landBase?.union) {
            getInterpretedObjects(data, false);
        }

        // SIZE
        getCapaSize(data);
    };
    // Get response from Capa fetching : error
    const handleCapaGetFail = (error) => {
        console.log("-FETCHING CAPA- : Error", error?.response?.data?.message);
        // If id doesn't exists => back to dashboard
        if (error?.response?.status === 404) {
            // console.log("Redirecting to Dashboard");
            navigate('/dashboard');
        }
        if (error?.response?.status === 403) {
            // console.log("Access forbiden");
            // navigate('/dashboard');
            setIsAccessDenied(true);
        }
        // If session expired => back to signin
        if (error?.response?.data?.message === "La session a expiré.") {
            // console.log("Redirecting to SignIn");
            setTimeout(() => {
                navigate('/auth?type=signin&cause=expired&source=' + window.location.pathname);
            }, 500);
        }
    }
    // Get user
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));


    //__________ UI - GLOBAL PAGE STRUCTURE

    // UI : Variables
    const hSeparator = 30;
    // var hHeader = 80;
    var hHeader = document.getElementById('header')?.offsetHeight || 80;
    const hMobileFooter = 80;
    const hLimit = window.innerHeight / 6;

    // UI : Navbar
    const layout_data = {
        layout: [
            {
                title_long: "Informations générales",
                title_short: "Infos Générales",
                ico_outline: ico_home_outline,
                ico_fill: ico_home_fill,
                isVisible: false,
                sub: [
                    { title: "Titre et description", target: "section_0" },
                    { title: "Localisation", target: "section_0_location" },
                    { title: "Partage et export", target: "section_0_share" },
                    { title: "Modifications", target: "section_0_modif" },
                ],
            },
            {
                title_long: "Terrain",
                title_short: "Terrain",
                ico_outline: ico_land_outline,
                ico_fill: ico_land_fill,
                isVisible: false,
                sub: [
                    { title: "Terrain d'assiette", target: "section_1" },
                    { title: "Parcelles", target: "section_1_lands" },
                    { title: "Limites", target: "section_1_bounds" },
                    { title: "Topographie", target: "section_1_topo" },
                    { title: "Arbres", target: "section_1_trees" },
                    { title: "Constructions existantes", target: "section_1_buildings" },
                    { title: "Imperméabilisation", target: "section_1_waterproof" },
                    { title: "Réseau de chaleur", target: "section_1_heatnetwork" },
                ],
            },
            {
                title_long: "Risques",
                title_short: "Risques",
                ico_outline: ico_risk_outline,
                ico_fill: ico_risk_fill,
                isVisible: false,
                sub: [
                    { title: "Risques naturels et industriels", target: "section_2" },
                    { title: "Risque sismique", target: "section_2_earthquake" },
                    { title: "Aléa des sols argileux", target: "section_2_clay" },
                    { title: "Canalisations", target: "section_2_pipes" },
                    { title: "Pollution du sol", target: "section_2_pollution" },
                ],
            },
            {
                title_long: "Données contextuelles",
                title_short: "Contexte",
                ico_outline: ico_stats_outline,
                ico_fill: ico_stats_fill,
                isVisible: false,
                sub: [
                    { title: "Zone fiscale", target: "section_3" },
                    { title: "Population", target: "section_3_population" },
                    { title: "Parc immobilier résidentiel", target: "section_3_housing" },
                    { title: "SRU", target: "section_3_sru" },
                    { title: "Transactions immobilières", target: "section_3_transactions" },
                    { title: "DAU", target: "section_3_dau" },
                    { title: "Monuments Historiques", target: "section_3_mh" },
                    { title: "Périmètres de mobilité", target: "section_3_areas" },
                    { title: "Commodités", target: "section_3_amenities" },
                ],
            },
            {
                title_long: "Règles d'urbanisme",
                title_short: "Règles",
                ico_outline: ico_rules_outline,
                ico_fill: ico_rules_fill,
                isVisible: false,
                sub: [
                    { title: "Référencement PLU", target: "section_4" },
                    { title: "Règles d'urbanisme", target: "section_4_rules" },
                ],
            },
            {
                title_long: "Capacité constructible",
                title_short: "Capacité",
                ico_outline: ico_capa_outline,
                ico_fill: ico_capa_fill,
                isVisible: false,
                sub: [
                    { title: "Paramètres", target: "section_5" },
                    { title: "Volume constructible", target: "section_5_volume" },
                    { title: "Surfaces constructibles", target: "section_5_surfaces" },
                ],
            },
            {
                title_long: "Simulations de construction",
                title_short: "Simulation",
                ico_outline: ico_combi_outline,
                ico_fill: ico_combi_fill,
                isVisible: false,
                sub: [
                    { title: "Paramètres", target: "section_6" },
                    { title: "Simulation sélectionnée", target: "section_6_selected", delta: 130 },
                    { title: "Liste des simulations", target: "section_6_selected", delta: 130 },
                ],
            },
        ]
    };
    const [navigation, setNavigation] = useState(layout_data);

    // UI : Section
    const [currentSection, setCurrentSection] = useState(0);
    const updateCurrentSection = (value) => {
        setCurrentSection(value);
    }
    const getSectionMinHeight = () => {
        if (window.innerWidth <= 550) {
            var data_height = document.getElementById('section_data')?.offsetHeight;
            var title_height = document.getElementById('data_title_0')?.offsetHeight || 0;
            return (data_height - title_height)
        }
        var header_height = document.getElementById('header')?.offsetHeight || 80;
        var margin_bottom = 20;
        var margin_top = 10;
        var padding = 15 * 2;
        var other = 55;
        return (window.innerHeight - header_height - margin_bottom - margin_top - padding - other);
    }
    const [sectionMinHeight, setSectionMinHeight] = useState(getSectionMinHeight());


    // UI : Drag comportment
    const [separatorBounce, setSeparatorBounce] = useState(null);
    const getSectionSeparatorStyle = () => {
        if (window.innerWidth > 1050) {
            return { top: hHeader - hSeparator, heightMessageTop: 0, heightMessageBottom: 0 };
        }
        else {
            return { top: window.innerHeight / 2, heightMessageTop: 0, heightMessageBottom: 0 };
        }
    }
    const [sectionSeparatorStyle, setSectionSeparatorStyle] = useSpring(getSectionSeparatorStyle);
    const getSectionDataStyle = () => {
        if (window.innerWidth > 1050) {
            return { top: hHeader, height: window.innerHeight - hHeader, opacity: 1 };
        }
        else if (window.innerWidth < 550) {
            return { top: (window.innerHeight / 2) + hSeparator, height: (window.innerHeight / 2) - hSeparator - hMobileFooter, opacity: 1 };
        }
        else {
            return { top: (window.innerHeight / 2) + hSeparator, height: (window.innerHeight / 2) - hSeparator, opacity: 1 };
        }
    }
    const [sectionDataStyle, setSectionDataStyle] = useSpring(getSectionDataStyle);
    const getSectionMapStyle = () => {
        if (window.innerWidth > 1050) {
            return { top: hHeader, height: window.innerHeight - hHeader, opacity: 1 };
        }
        else {
            return { top: hHeader, height: (window.innerHeight / 2) - hHeader, opacity: 1 };
        }
    }
    const [sectionMapStyle, setSectionMapStyle] = useSpring(getSectionMapStyle);
    const dragSectionSeparator = useDrag((params) => {
        handleDrag(params);
    });
    const handleDrag = (params) => {
        if (window.innerWidth <= 1050) {
            const y = params.xy[1] - 15;
            // Update hHeader
            hHeader = document.getElementById("header").offsetHeight;

            if (params.dragging) {
                setSeparatorBounce(null);
                if (y <= 80) {
                    // Update sectionSeparator
                    sectionSeparatorStyle.top.set(hHeader);
                    // Update sectionData
                    sectionDataStyle.top.set(hHeader + hSeparator);
                    if (window.innerWidth < 550) {
                        sectionDataStyle.height.set(window.innerHeight - hHeader - hSeparator - hMobileFooter);
                    }
                    else {
                        sectionDataStyle.height.set(window.innerHeight - hHeader - hSeparator);
                    }
                    // Update sectionMap
                    sectionMapStyle.height.set(0);
                }
                else if (window.innerWidth >= 550 && y >= window.innerHeight - hSeparator) {
                    // Update sectionSeparator
                    sectionSeparatorStyle.top.set(window.innerHeight - hSeparator);
                    // Update sectionData
                    sectionDataStyle.top.set(window.innerHeight);
                    sectionDataStyle.height.set(0);
                    // Update sectionMap
                    sectionMapStyle.height.set(window.innerHeight - hHeader - hSeparator);
                }
                else if (window.innerWidth < 550 && y >= window.innerHeight - hSeparator - hMobileFooter) {
                    // Update sectionSeparator
                    sectionSeparatorStyle.top.set(window.innerHeight - hSeparator - hMobileFooter);
                    // Update sectionData
                    sectionDataStyle.top.set(window.innerHeight - hMobileFooter);
                    sectionDataStyle.height.set(0);
                    // Update sectionMap
                    sectionMapStyle.height.set(window.innerHeight - hHeader - hSeparator - hMobileFooter);
                }
                else {
                    // Update sectionSeparator
                    sectionSeparatorStyle.top.set(y);
                    sectionSeparatorStyle.heightMessageTop.start(0);
                    sectionSeparatorStyle.heightMessageBottom.start(0);
                    // Update sectionData
                    sectionDataStyle.top.set(y + hSeparator);
                    if (window.innerWidth < 550) {
                        sectionDataStyle.height.set(window.innerHeight - y - hSeparator - hMobileFooter);
                    }
                    else {
                        sectionDataStyle.height.set(window.innerHeight - y - hSeparator);
                    }
                    if (window.innerWidth < 550 && y >= window.innerHeight - hSeparator - hLimit - hMobileFooter) {
                        sectionDataStyle.opacity.set(0);
                    }
                    else if (window.innerWidth >= 550 && y >= window.innerHeight - hSeparator - hLimit) {
                        sectionDataStyle.opacity.set(0);
                    }
                    else {
                        sectionDataStyle.opacity.set(1);
                    }
                    // Update sectionMap
                    sectionMapStyle.height.set(y - hHeader);
                    if (y <= hHeader + hLimit) {
                        sectionMapStyle.opacity.set(0);
                    }
                    else {
                        sectionMapStyle.opacity.set(1);
                    }
                    // Resize map
                    setMapResize(y);
                }
            }
            else {
                // On stop dragging check if it is in top limit part
                if (y <= hHeader + hLimit) {
                    // Update sectionSeparator
                    sectionSeparatorStyle.top.start(hHeader);
                    sectionSeparatorStyle.heightMessageTop.start(12);
                    setSeparatorBounce("down");
                    // Update sectionData
                    sectionDataStyle.top.start(hHeader + hSeparator);
                    if (window.innerWidth < 550) {
                        sectionDataStyle.height.set(window.innerHeight - hHeader - hSeparator - hMobileFooter);
                    }
                    else {
                        sectionDataStyle.height.set(window.innerHeight - hHeader - hSeparator);
                    }
                }
                // On stop dragging check if it is in bottom limit part (for mobile)
                if (window.innerWidth < 550) {
                    if (y >= window.innerHeight - hSeparator - hMobileFooter - hLimit) {
                        // Update sectionData
                        sectionDataStyle.height.set(5);
                        // Update sectionSeparator
                        sectionSeparatorStyle.top.start(window.innerHeight - hSeparator - hMobileFooter);
                        sectionSeparatorStyle.heightMessageBottom.start(12);
                        setSeparatorBounce("up");
                        // Update sectionMap
                        sectionMapStyle.height.start(window.innerHeight - hHeader - hSeparator - hMobileFooter);
                        // Resize map
                        var interval = 0;
                        var mapResizer = setInterval(() => {
                            interval += 10;
                            setMapResize(interval);
                        }, 10);
                        setTimeout(
                            () => { clearInterval(mapResizer); }
                            , 500);
                        // Scroll all sections to top
                        setTimeout(() => {
                            for (var i = 0; i < navigation.layout.length; i++) {
                                document.getElementById("section_" + i).scrollTo(0, 0);
                            }
                        }, 800);
                    }
                }
                // On stop dragging check if it is in bottom limit part (for tablet & desktop)
                else {
                    if (y >= window.innerHeight - hSeparator - hLimit) {
                        // Update sectionSeparator
                        sectionSeparatorStyle.top.start(window.innerHeight - hSeparator);
                        sectionSeparatorStyle.heightMessageBottom.start(12);
                        setSeparatorBounce("up");
                        // Update sectionMap
                        sectionMapStyle.height.start(window.innerHeight - hHeader - hSeparator);
                        // Resize map
                        var interval = 0;
                        var mapResizer = setInterval(() => {
                            interval += 10;
                            setMapResize(interval);
                        },
                            10);
                        setTimeout(
                            () => { clearInterval(mapResizer); },
                            500);
                    }
                }
            }
        }
    }

    // UI : Resize window
    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, { passive: true });
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    const handleWindowResize = () => {
        // console.log("RESIZE");
        // Update hHeader
        hHeader = document.getElementById("header").offsetHeight;
        // Resize elements
        setSectionMinHeight(getSectionMinHeight());
        if (window.innerWidth > 1050) {
            // Reset styles to get it back to normal large UI
            // Update sectionSeparator
            sectionSeparatorStyle.top.set(hHeader - hSeparator);
            sectionSeparatorStyle.heightMessageTop.set(0);
            sectionSeparatorStyle.heightMessageBottom.set(0);
            // Update sectionData
            sectionDataStyle.top.set(hHeader);
            sectionDataStyle.height.set(window.innerHeight - hHeader);
            sectionDataStyle.opacity.set(1);
            // Update sectionMap
            sectionMapStyle.top.set(hHeader);
            sectionMapStyle.height.set(window.innerHeight - hHeader);
            sectionMapStyle.opacity.set(1);
        }
        else {
            handleDrag({ xy: [0, document.getElementById('section_separator').offsetTop + 15], dragging: true });
            if (document.getElementById('section_separator').offsetTop === hHeader - hSeparator || document.getElementById('section_separator').offsetTop === Math.round(window.innerHeight / 2)) {
                // Reset styles to get it back to normal small UI
                // Update sectionSeparator
                sectionSeparatorStyle.top.set(window.innerHeight / 2);
                sectionSeparatorStyle.heightMessageTop.set(0);
                sectionSeparatorStyle.heightMessageBottom.set(0);
                // Update sectionData
                sectionDataStyle.top.set((window.innerHeight / 2) + hSeparator);
                sectionDataStyle.height.set((window.innerHeight / 2 - hSeparator));
                sectionDataStyle.opacity.set(1);
                // Update sectionMap
                sectionMapStyle.top.set(hHeader);
                sectionMapStyle.height.set((window.innerHeight / 2) - hHeader);
                sectionMapStyle.opacity.set(1);
            }
        }
        setMapResize(window.innerWidth * window.innerHeight);
    }

    // UI : Use handleDrag on load if mobile
    useEffect(() => {
        if (window.innerWidth < 550) {
            // console.log("MOBILE RESIZE");
            handleDrag({ xy: [0, document.getElementById('section_separator').offsetTop + 15], dragging: true });
        }
    }, []);

    //_________ MAP

    // MAP : Ready
    const [isMapReady, setIsMapReady] = useState(false);
    useEffect(() => {
        // console.log("MAP READY", isMapReady);
        if (isMapReady) {
            getCapacity();
        }
    }, [isMapReady]);

    // MAP : Resize
    const [mapResize, setMapResize] = useState(0);
    const [isMapExpanded, setIsMapExpanded] = useState(false);

    // MAP : Layers
    const [mapLayers, setMapLayers] = useState(
        {
            satellite: {
                isVisible: false,
                label: "Vue satellite"
            },
            OSM_Buildings: {
                isVisible: true,
                label: "Bâtiments (Open Street Map)"
            },
            cityLands: {
                isVisible: false,
                label: "Parcelles"
            },
            trees: {
                isVisible: false,
                label: "Arbres"
            },
            IGN_Buildings: {
                isVisible: false,
                label: "Bâtiments (Cadastre)"
            },
            IGN_BuildingsLand: {
                isVisible: false,
                label: "Bâtiments (Cadastre)"
            },
            IGN_BuildingsNoland: {
                isVisible: false,
                label: "Bâtiments (Cadastre)"
            },
            union: {
                isVisible: true,
                label: "Terrain d'assiette"
            },
            boundsType: {
                isVisible: false,
                label: "Détail des limites"
            },
            topo_3D: {
                isVisible: false,
                label: "Topographie 3D"
            },
            topo_points: {
                isVisible: false,
                label: "Plan topo"
            },
            topo_profile: {
                isVisible: false,
                label: "Profil altimétrique caractéristique"
            },
            risks_earthquake: {
                isVisible: false,
                label: "Zones de sismicité"
            },
            risks_clay: {
                isVisible: false,
                label: "Zone d'aléa de retrait-gonflement des sols argileux"
            },
            risks_pipes: {
                isVisible: false,
                label: "Canalisations de matières dangereuses"
            },
            risks_basias: {
                isVisible: false,
                label: "Sites pollués"
            },
            context_merimee: {
                isVisible: false,
                label: "Monuments historiques"
            },
            context_sitadel: {
                isVisible: false,
                label: "Demandes d'autorisation d'urbanisme"
            },
            context_isodistance: {
                isVisible: false,
                label: "Surfaces couvertes en 5 minutes en voiture et 10 minutes à pied"
            },
            context_dvf: {
                isVisible: false,
                label: "Transactions immobilières"
            },
            rules: {
                isVisible: false,
                label: "Règles d'urbanisme"
            },
            zones_urba: {
                isVisible: false,
                label: "Zones PLU"
            },
            buildable_volume: {
                isVisible: false,
                label: "Volume constructible"
            },
        }
    );

    // LAYER : Refresh
    const layer_Refresh = (layer) => {
        if (mapLayers[layer]["isVisible"] === true) {
            // console.log("REFRESH LAYER", layer);
            // Get state
            var mapLayersState = mapLayers;
            // Update state
            mapLayersState[layer]["isVisible"] = false;
            // Update original
            setMapLayers({ ...mapLayersState });

            setTimeout(() => {
                // Update state
                mapLayersState[layer]["isVisible"] = true;
                // Update original
                setMapLayers({ ...mapLayersState });
            }, 100);
        }
    }

    //__________ CAPACITY

    // CAPACITY : Step
    const [currentStep, setCurrentStep] = useState(-1);
    useEffect(() => {
        // console.log("!!! OFFICIAL STEP CHANGE", currentStep);
        if (currentStep === 0) {
            // Set menu visibility
            var nav = navigation;
            nav.layout[0].isVisible = true;
            nav.layout[1].isVisible = true;
            setNavigation({ ...nav });
        }
        else if (currentStep >= 1) {
            // Set menu visibility
            var nav = navigation;
            nav.layout[0].isVisible = true;
            nav.layout[1].isVisible = true;
            nav.layout[2].isVisible = true;
            nav.layout[3].isVisible = true;
            nav.layout[4].isVisible = true;
            nav.layout[5].isVisible = true;
            nav.layout[6].isVisible = false;
            if (capacity?.buildable?.volume?.interpreted && capacity?.buildable?.volume?.interpreted.length > 0 && capacity?.buildable?.volume?.interpreted[0]?.userData) {
                nav.layout[6].isVisible = true;
            }
            setNavigation({ ...nav });
            // Check if last user is current
            if (capacity?.modifications && capacity.modifications.length > 1 && capacity.modifications[0]?.userId === user?.result?._id && capacity.modifications[capacity.modifications.length - 1]?.userId !== user?.result?._id) {
                var name = "un autre utilisateur";
                if (user?.users) {
                    for (var i = 0; i < user.users.length; i++) {
                        if (user.users[i]?._id === capacity.modifications[capacity.modifications.length - 1]?.userId) {
                            name = user.users[i]?.firstName + " " + user.users[i]?.lastName;
                            break;
                        }
                    }
                }
                handleToaster([{ key: "message", status: "info", type: "static", style: "default", title: "Etude modifiée", text: "Cette étude a été modifiée pour la dernière fois par " + name + " " + moment(capacity.modifications[capacity.modifications.length - 1]?.time).startOf().fromNow() + "." }]);
            }
        }
    }, [currentStep]);

    // CAPACITY : Track updates
    useEffect(() => {
        if (capacity === undefined) { return }

        console.log("-UPDATING CAPA- : Updated", capacity);
        // console.log("-UPDATING CAPA- : Updated");
        // Check if autosave
        if (capacity?.toSave?.autoSave === true) {
            saveCapacity();
        }
        else {
            // CHANGING STEP ELEMENTS

            // CHANGING TO STEP 1
            if (capacity?.step === 1) {
                // Hide STEP 0 elements : landsPolys, landsLabels, landDashLines
                // Set STEP 1 elements : landLines, flytoLand

                // Get location
                // if (!capacity?.landBase?.location) {
                //     var landBase = capacity.landBase;
                //     landBase.location = "fetching";
                //     setCapacity({ ...capacity, landBase: landBase, toSave: { autoSave: false, action: "", value: "" } });
                // }

                //____ TOPO
                // Fetch
                // if (capacity?.landBase?.topo === "fetching" || capacity?.landBase?.topo === "error") {
                if (capacity?.landBase?.topo === "fetching") {
                    fetchTopo();
                }
                // Set fetching state
                if ((capacity?.landBase?.location && capacity.landBase.location !== "fetching") && !capacity?.landBase?.topo) {
                    console.log("!!!!!!!!!! SET TOPO FETCHING");
                    // addToaster("land");
                    handleToaster([{ key: "land", status: "loading" }]);
                    var landBase = capacity.landBase;
                    landBase.topo = "fetching";
                    var toSaveData = { ...capacity.toSave };
                    if (!toSaveData?.keys) { toSaveData.keys = [] }
                    toSaveData.keys.push("landBase");
                    toSaveData.autoSave = false;
                    setCapacity({ ...capacity, landBase: landBase, toSave: toSaveData });
                }


                //____ RISKS
                // Fetch
                if (capacity?.risks === "fetching") {
                    fetchRisks();
                }
                // Set fetching state
                if (capacity?.landBase?.topo && capacity.landBase.topo !== "fetching" && (!capacity?.risks || capacity.risks === "")) {
                    console.log("!!!!!!!!!! SET RISK FETCHING");
                    // addToaster("risks");
                    // finishToaster("land");
                    handleToaster([{ key: "risks", status: "loading" }, { key: "land", status: "success" }]);
                    var toSaveData = { ...capacity.toSave };
                    if (!toSaveData?.keys) { toSaveData.keys = [] }
                    toSaveData.keys.push("risks");
                    toSaveData.autoSave = false;
                    setCapacity({ ...capacity, risks: "fetching", toSave: toSaveData });
                }


                //____ CONTEXT
                // Set fetching state
                if (capacity?.risks && capacity.risks !== "fetching" && (!capacity?.context || capacity.context === "")) {
                    console.log("!!!!!!!!!! SET CONTEXT FETCHING");
                    // addToaster("context");
                    // finishToaster("risks");
                    handleToaster([{ key: "context", status: "loading" }, { key: "risks", status: "success" }]);
                    var toSaveData = { ...capacity.toSave };
                    if (!toSaveData?.keys) { toSaveData.keys = [] }
                    toSaveData.keys.push("context");
                    toSaveData.keys.push("landBase");
                    toSaveData.keys.push("city");
                    toSaveData.autoSave = true;
                    setCapacity({ ...capacity, context: "fetching", toSave: toSaveData });
                }

                //____ GPU
                // Fetch
                if (capacity?.rules?.gpu_data === "fetching") {
                    fetchGPU();
                }
                // Set fetching state
                if (capacity?.context && capacity.context !== "fetching" && (!capacity?.rules?.gpu_data || capacity.rules?.gpu_data === "")) {
                    console.log("!!!!!!!!!! SET GPU FETCHING");
                    // addToaster("gpu");
                    // finishToaster("context");
                    handleToaster([{ key: "gpu", status: "loading" }, { key: "context", status: "success" }]);
                    var rules = capacity.rules;
                    rules.gpu_data = "fetching";
                    var toSaveData = { ...capacity.toSave };
                    if (!toSaveData?.keys) { toSaveData.keys = [] }
                    toSaveData.keys.push("rules");
                    toSaveData.autoSave = false;
                    setCapacity({ ...capacity, rules: rules, toSave: toSaveData });
                }


                //____ BUILDABLE
                // Fetch
                if (capacity?.buildable?.volume?.levels && capacity.buildable.volume.levels === "fetching") {
                    // if (capacity?.buildable?.volume?.levels && capacity.buildable.volume.levels.length === 0) {
                    console.log("!!!!!!!!!! SET BUILDABLE FETCHING");
                    getBuildableData();
                }

                //____ COMBINATIONS
                // Fetch
                if (capacity?.buildable?.combinations && capacity.buildable.combinations === "fetching") {
                    // addToaster("combinations");
                    handleToaster([{ key: "combinations", status: "loading" }]);
                    // if (capacity?.buildable?.volume?.levels && capacity.buildable.volume.levels.length === 0) {
                    console.log("!!!!!!!!!! SET COMBINATION FETCHING");
                    getCombinationData();
                }

            }
        }

        // Update currentStep
        if (capacity?.step >= 0) {
            setCurrentStep(capacity.step);
        }

        // Show/Hide combination section
        if (capacity?.step > 0 && capacity?.buildable?.volume?.interpreted && capacity?.buildable?.volume?.interpreted.length > 0 && capacity?.buildable?.volume?.interpreted[0]?.userData) {
            var nav = navigation;
            // Set menu visibility
            nav.layout[0].isVisible = true;
            nav.layout[1].isVisible = true;
            nav.layout[2].isVisible = true;
            nav.layout[3].isVisible = true;
            nav.layout[4].isVisible = true;
            nav.layout[5].isVisible = true;
            nav.layout[6].isVisible = true;
            setNavigation({ ...nav });
        }
        else if (capacity?.step > 0) {
            var nav = navigation;
            // Set menu visibility
            nav.layout[0].isVisible = true;
            nav.layout[1].isVisible = true;
            nav.layout[2].isVisible = true;
            nav.layout[3].isVisible = true;
            nav.layout[4].isVisible = true;
            nav.layout[5].isVisible = true;
            nav.layout[6].isVisible = false;
            setNavigation({ ...nav });
        }

    }, [capacity]);

    // CAPACITY : Check frequency save
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         // Decrement save counter
    //         save_counter--;

    //         console.log('CHECK IF SAVE', save_counter);
    //     }, 60000);
    //     return () => clearInterval(interval);
    // }, []);

    // // CAPACITY : Check save on unload
    // useEffect(() => {
    //     const handleUnload = () => {
    //         console.log('CHECK IF SAVE ON CLOSE', save_counter);
    //     };
    //     window.addEventListener('unload', handleUnload);
    //     return () => {
    //         window.removeEventListener('unload', handleUnload);
    //     };
    // }, []);

    // CAPACITY : Autosave in DB
    const saveCapacity = () => {
        console.log("-SAVING CAPA- : Optimizing...");
        // return
        // Get optimized version
        var capaToUpdate = other_helpers.compressCapa(capacity);

        // Save to db
        console.log("-SAVING CAPA- : Loading...", capaToUpdate);
        // addToaster("save");
        if (reloading === "risks" && capaToUpdate?.toSave?.keys && capaToUpdate?.toSave?.keys.includes("risks") && capaToUpdate?.risks?.basias) {
            handleToaster([{ key: "risks", status: "success" }]);
            reloading = null;
        }
        if (reloading === "plu" && capaToUpdate?.toSave?.keys && capaToUpdate?.toSave?.keys.includes("rules") && capaToUpdate?.rules?.gpu_data?.document) {
            handleToaster([{ key: "gpu", status: "success" }]);
            reloading = null;
        }
        if (capaToUpdate?.toSave?.action !== "") {
            handleToaster([{ key: "save", status: "loading", style: "default", title: "Enregistrement", text: "Enregistrement des dernières modifications apportées à l'étude." }]);
        }
        else {
            // console.log("HIDDEN AUTO SAVE");
        }
        dispatch(capaUpdate(capacity._id, { capaToUpdate, userId: user.result._id }, handleUpdateSuccess, handleUpdateFail));

        // Reset autosave
        // setCapacity({ ...capacity, toSave: { autoSave: false, action: "", value: "" } });
    }
    const handleUpdateSuccess = (data) => {
        console.log("-SAVING CAPA- : Success", data);
        // Toaster
        var toaster_items = [
            { key: "save", status: "success", style: "default", title: "Enregistrement", text: "Enregistrement des dernières modifications apportées à l'étude." }
        ]
        if (reloading === "landBase" && data?.result?.landBase) {
            toaster_items.push(
                { key: "land", status: "success" }
            )
            reloading = null;
        }
        if (reloading === "context" && data?.result?.context) {
            toaster_items.push(
                { key: "context", status: "success" }
            )
            reloading = null;
        }
        handleToaster(toaster_items);

        var capacity_data = data.result;
        // capacity_data.toSave = false;
        if (capacity_data?.toUpdate === true) {
            console.log("CAPA toUpdate TRUE");
            // /!\ Should be better to update only what had been modififed in backend (not the optimized removed elements)
            setCapacity({ ...capacity_data, toUpdate: false });
        }
        else {
            // SET CAPACITY ONLY WITH THE MODIFICATIONS
            console.log("UPDATING MODIFS", capacity);
            var capa_new = { ...capacity };
            capa_new.toSave = { autoSave: false, action: "", value: "", keys: [] };
            capa_new.toUpdate = false;
            if (data?.result) {
                Object.keys(data?.result).forEach(key => {
                    capa_new[key] = data.result[key];
                })
            }
            // capa_new.modifications = capacity_data.modifications;
            // setCapacity({ ...capacity, toSave: { autoSave: false, action: "", value: "", keys: [] }, toUpdate: false, modifications: capacity_data.modifications });
            setCapacity(capa_new);
        }
    }
    const handleUpdateFail = (error) => {
        console.log("-SAVING CAPA- : Error", error?.response?.data?.message);
        handleToaster([{ key: "save", status: "error", style: "error", title: "Enregistrement", text: error?.response?.data?.message || "L'étude n'a pas pu être enregistrée." }]);
        // If session expired => back to signin
        if (error?.response?.data?.message === "La session a expiré.") {
            // console.log("Redirecting to SignIn");
            setTimeout(() => {
                navigate('/auth?type=signin&cause=expired&source=' + window.location.pathname);
            }, 500);
        }
    }

    // CAPACITY : Get Interpreted Object
    const getInterpretedObjects = (capa, relaunch_buildable) => {

        // Interpret rules
        if (capa?.rules?.ruleset && capa.rules.ruleset.length > 0) {
            // console.log("INTERPRETING THREE OBJECTS");
            capa.rules.ruleset.forEach(ruleset => {
                ruleset.items.forEach(item => {
                    if (item?.type === "rule") {
                        if (item?.data?.params?.condition?.data?.interpreted === false) {
                            item.interpreted = [];
                        }
                        else {
                            // Check if not already interpreted (to optimize)
                            // console.log("XXXXXXXXXXXXXX - RULE", item);
                            if (!item?.interpreted || item.interpreted.length === 0 || item.interpreted[0] === "removed") {
                                // console.log("XXXXXXXXXXXXXX - INTERPRET RULE");
                                item.interpreted = node_helpers.nodeInterpretor(item.data.composition.nodes[0].data.source, capa, item?.data?.params?.perimeter);
                            }
                            // Check if rule has to be re interpreted even if already interpreted => use the variable of max_height or limits
                            else if (item?.data?.composition?.nodes && item?.data?.composition?.nodes.length > 0 && (item?.data?.composition?.nodes[0]?.data?.source[0]?.value.includes("Node_Study_Buildable_Height[]") || item?.data?.composition?.nodes[0]?.data?.source[0]?.value.includes("Node_Study_Bounds["))) {
                                // console.log("XXXXXXXXXXXXXX - INTERPRET RULE");
                                item.interpreted = node_helpers.nodeInterpretor(item.data.composition.nodes[0].data.source, capa, item?.data?.params?.perimeter);
                            }
                        }
                    }
                })
            })
        }

        // Interpret volume
        // if ((capa?.buildable?.volume?.interpreted && capa.buildable.volume.interpreted[0] === "removed") || !capa?.buildable?.volume?.parameters?.max_area_total) {
        if ((capa?.buildable?.volume?.interpreted && capa.buildable.volume.interpreted[0] !== "removed") || !capa?.buildable?.volume?.parameters?.max_area_total) {

            // Get rules that affects calculations
            var rules_effect_parameters = buildable_helpers.get_rules_effect(capa);
            // console.log("--_--_--_--_ rules_effect", rules_effect_parameters.rules_effect);
            // console.log("--_--_--_--_ volume_parameters", rules_effect_parameters.volume_parameters);

            capa.buildable.volume.parameters = rules_effect_parameters.volume_parameters;

        }



        // Interpret rules again to update to change of max height
        if (rules_effect_parameters?.max_height_change === true && capa?.rules?.ruleset && capa.rules.ruleset.length > 0) {
            // console.log("INTERPRETING THREE OBJECTS");
            capa.rules.ruleset.forEach(ruleset => {
                ruleset.items.forEach(item => {
                    if (item?.type === "rule") {
                        if (item?.data?.params?.condition?.data?.interpreted === false) {
                            item.interpreted = [];
                        }
                        else {
                            // Check if not already interpreted (to optimize)
                            // console.log("XXXXXXXXXXXXXX - RULE", item);
                            if (!item?.interpreted || item.interpreted.length === 0 || item.interpreted[0] === "removed") {
                                // console.log("XXXXXXXXXXXXXX - INTERPRET RULE");
                                item.interpreted = node_helpers.nodeInterpretor(item.data.composition.nodes[0].data.source, capa, item?.data?.params?.perimeter);
                            }
                            // Check if rule has to be re interpreted even if already interpreted => use the variable of max_height or limits
                            else if (item?.data?.composition?.nodes && item?.data?.composition?.nodes.length > 0 && (item?.data?.composition?.nodes[0]?.data?.source[0]?.value.includes("Node_Study_Buildable_Height[]"))) {
                                // console.log("XXXXXXXXXXXXXX - INTERPRET RULE");
                                item.interpreted = node_helpers.nodeInterpretor(item.data.composition.nodes[0].data.source, capa, item?.data?.params?.perimeter);
                            }
                        }
                    }
                })
            })
        }

        // Interpret buildable volume
        // if (capa?.landBase?.union?.geometry?.geometry?.type === "Polygon") {
        //     var buildable_interpreted = node_helpers.buildableInterpretor(capa);
        //     console.log("buildable_interpreted", buildable_interpreted);
        //     capa.buildable.volume.interpreted = buildable_interpreted;
        // }

        // Set capacity
        setCapacity(capa);

        // Update tracker
        setRulesetUpdateTracker((Date.now()).toString());

        // Update map
        // layer_Refresh("rules");

        getInterpretedVolume(capa, relaunch_buildable);
    }
    const getInterpretedVolume = (capa, relaunch_buildable) => {

        var newBuildable = { ...capa.buildable };
        // Interpret buildable volume
        if (capa?.landBase?.union?.geometry?.geometry?.type === "Polygon") {
            var buildable_interpreted = node_helpers.buildableInterpretor(capa);
            // console.log("buildable_interpreted", buildable_interpreted);
            newBuildable.volume.interpreted = buildable_interpreted;
        }
        // Remove levels to force calcul again
        if (relaunch_buildable === true || !capa?.buildable?.volume?.levels) {
            newBuildable.volume.levels = "fetching";
        }
        // Set capacity
        var save = { ...capa.toSave };
        var rules = { ...capa.rules };
        rules.state = "done";
        // save.autoSave = true;
        setCapacity({ ...capa, rules: rules, toSave: save, buildable: newBuildable });

        setTimeout(() => {
            // Set volume tracker
            setVolumeUpdateTracker((Date.now()).toString());
        }, 500);
    }

    //__________ FETCH

    // FETCH : Topo
    const fetchTopo = async () => {
        var topo_majic_buildings = await fetch_helpers.get_topo_majic_buildings(capacity);
        // console.log("topo_majic_buildings", topo_majic_buildings);
        var landBase = capacity.landBase;
        landBase.topo = topo_majic_buildings.topo;
        landBase.lands = topo_majic_buildings.majic;
        landBase.buildings = topo_majic_buildings.buildings;
        landBase.trees = topo_majic_buildings.trees;
        var city = capacity.city;
        city.administrative = topo_majic_buildings.administrative;
        // Remove unecessary data
        // if (landBase?.buildings_osm && landBase.buildings !== "error" && landBase?.buildings?.buildings && landBase.buildings.buildings.length > 0) {
        // delete landBase.buildings_osm;
        // }
        // Set buildable volume max height
        var buildable = capacity.buildable;
        var maxHeight = -1;
        if (topo_majic_buildings?.buildings?.buildings_close && topo_majic_buildings.buildings.buildings_close.length > 0) {
            for (var i = 0; i < topo_majic_buildings.buildings.buildings_close.length; i++) {
                if (topo_majic_buildings.buildings.buildings[topo_majic_buildings.buildings.buildings_close[i]]?.properties?.height_max > maxHeight) {
                    maxHeight = topo_majic_buildings.buildings.buildings[topo_majic_buildings.buildings.buildings_close[i]]?.properties?.height_max;
                }
            }
        }
        // if (maxHeight >= 3) {
        //     buildable.volume.parameters.max_height = parseFloat((maxHeight + 0.02).toFixed(2));
        // }
        // Set buildable volume parameters
        if (!buildable?.volume?.parameters) {
            buildable.volume.parameters = {};
        }
        if (!buildable?.volume?.parameters?.max_height_database) {
            buildable.volume.parameters.max_height_database = parseFloat((maxHeight).toFixed(2));
        }
        if (!buildable?.volume?.parameters?.max_height) {
            if (maxHeight > 3) {
                buildable.volume.parameters.max_height = parseFloat((maxHeight).toFixed(2));
                buildable.volume.parameters.max_height_type = "database";
            }
            else { // DEFAULT VALUE OF max_height
                buildable.volume.parameters.max_height = 3.00;
                buildable.volume.parameters.max_height_type = "default";
            }
        }

        // Update capacity
        var toSaveData = { ...capacity.toSave };
        if (!toSaveData?.keys) { toSaveData.keys = [] }
        toSaveData.keys.push("buildable");
        toSaveData.keys.push("landBase");
        toSaveData.keys.push("city");
        toSaveData.autoSave = true;
        setCapacity({ ...capacity, buildable: buildable, landBase: landBase, city: city, toSave: toSaveData });
    }

    const fetchRisks = async () => {
        // console.log("STOP TEST HERE");
        // return
        var risks = await fetch_helpers.get_risks(capacity.landBase.union.center.geometry.coordinates);
        // console.log("risks", risks);
        var toSaveData = { ...capacity.toSave };
        if (!toSaveData?.keys) { toSaveData.keys = [] }
        toSaveData.keys.push("risks");
        toSaveData.autoSave = true;
        setCapacity({ ...capacity, risks: risks, toSave: toSaveData });
        // setCapacity({ ...capacity, risks: risks, context: "fetching", toSave: { autoSave: true, action: "", value: "" } });
    }

    const fetchGPU = async () => {
        // console.log("FETCHING GPU");
        var gpu_data = await fetch_helpers.get_gpu(capacity.landBase);
        // var gpu_data = await fetch_helpers.get_gpu(capacity.landBase.union);
        // console.log("gpu_data", gpu_data);
        var rules = capacity.rules;
        rules.gpu_data = gpu_data;
        var toSaveData = { ...capacity.toSave };
        if (!toSaveData?.keys) { toSaveData.keys = [] }
        toSaveData.keys.push("rules");
        toSaveData.keys.push("all");
        toSaveData.autoSave = true;
        setCapacity({ ...capacity, rules: rules, toSave: toSaveData });
        // finishToaster("gpu");
        handleToaster([{ key: "buildable", status: "loading" }, { key: "gpu", status: "success" }]);


        // Auto calculate buildable
        // addToaster("buildable");
        getInterpretedObjects(capacity, true);
    }

    const getBuildableData = () => {
        var volumeData = buildable_helpers.get_buildable_data(capacity);
        var buildable = capacity.buildable;
        buildable.volume = volumeData;
        buildable.combinations = ["removed"];
        setHighlightedCombination([]);
        // finishToaster("buildable");
        handleToaster([{ key: "buildable", status: "success" }]);
        // NOT FORGET TO GET BACK SAVING
        var toSaveData = { ...capacity.toSave };
        if (!toSaveData?.keys) { toSaveData.keys = [] }
        toSaveData.keys.push("buildable");
        toSaveData.autoSave = true; // TO CHANGE TO TRUE WHEN RECETTE OVER
        // console.log("FINAL --toSaveData", toSaveData);
        // setCapacity({ ...capacity, buildable: buildable, toSave: { autoSave: true, action: "", value: "" } });
        setCapacity({ ...capacity, buildable: buildable, toSave: toSaveData });
    }

    const getCombinationData = () => {
        // Reset list parameters
        setHighlightedCombination([]);
        var list_select = document.getElementById('orderBy');
        if (list_select !== null) {
            list_select.value = "byId";
        }

        // Launch algo after 200 msec
        setTimeout(() => {
            var combinations = combination_helpers.get_combinations(capacity);
            var buildable = { ...capacity.buildable };
            buildable.combinations = combinations;
            // console.log("buildable", buildable);
            // finishToaster("combinations");
            handleToaster([{ key: "combinations", status: "success" }]);
            // NOT FORGET TO GET BACK SAVING
            // setCapacity({ ...capacity, buildable: buildable, toSave: { autoSave: true, action: "", value: "" } });
            var toSaveData = { ...capacity.toSave };
            if (!toSaveData?.keys) { toSaveData.keys = [] }
            toSaveData.keys.push("buildable");
            toSaveData.autoSave = true;
            setCapacity({ ...capacity, buildable: buildable, toSave: toSaveData });
        }, 200);
    }


    // _____ RULES
    const [rulesCatalogTeam, setRulesCatalogTeam] = useState(
        {
            rulesCatalog: [],
            rulesTeam: []
        }
    );
    const getRules = () => {
        // console.log("-FETCHING RULES- : Loading...");
        dispatch(rulesGetByTeamId(user.teams[0]._id, handleRulesGetSuccess, handleRulesGetFail));
    }
    // Get response from Rules fetching : success
    const handleRulesGetSuccess = (data) => {
        // console.log("-FETCHING RULES- : Success", data);
        rulesUpdate(data);
    };
    // Get response from Rules fetching : error
    const handleRulesGetFail = (error) => {
        console.log("-FETCHING RULES- : Error", error.response.data);
    }
    const rulesUpdate = (rulesCatalogTeam) => {
        setRulesCatalogTeam({
            ...rulesCatalogTeam,
            rulesCatalog: rulesCatalog_Filter(rulesCatalogTeam.rulesCatalog),
            rulesTeam: rulesCatalogTeam.rulesTeam
        })
    }
    const rulesCatalog_Filter = (rulesCatalog) => {
        // console.log("RULES CATALOG TO FILTER", rulesCatalog);

        // Sort
        var rulesCatalogOrdered = [...rulesCatalog];
        rulesCatalogOrdered.sort(function (a, b) {
            if (a.title < b.title) { return -1; }
            if (a.title > b.title) { return 1; }
            return 0;
        })

        var rulesPopular = [];
        var rulesFiltered = {};
        var rulesFilteredTitles = [];
        rulesCatalogOrdered.forEach(rule => {
            // Popular
            if (rule.popular === true) {
                rulesPopular.push(rule);
            }
            // Filter
            if (!rulesFilteredTitles.includes(rule.category)) {
                rulesFilteredTitles.push(rule.category);
                rulesFiltered[rule.category] = [];
            }
            rulesFiltered[rule.category].push(rule);
        });
        // Sort titles
        rulesFilteredTitles.sort();
        // Create final list
        var rulesFinal = {
            "popular": rulesPopular,
        }
        rulesFilteredTitles.forEach(title => {
            rulesFinal[title] = rulesFiltered[title];
        });

        // console.log("RULES CATALOG FILTERED", rulesFinal);
        return rulesFinal;
    }

    const [selectedRuleGroup, setSelectedRuleGroup] = useState(null);
    const [ruleCatalogType, setRuleCatalogType] = useState("rule");

    const ruleCatalog_Open = (ruleGroup_Id, type) => {
        setRuleCatalogType(type);
        setSelectedRuleGroup(ruleGroup_Id);
        setIsRuleCatalogOpen(true);
    }

    const [ruleUpdateTracker, setRuleUpdateTracker] = useState(0);
    useEffect(() => {
        if (ruleUpdateTracker !== 0) {
            // console.log("!!!!!! OFFICIAL RULE CHANGE", ruleUpdateTracker);

            // Derivated step : get buildable volume
            // addToaster("buildable");
            handleToaster([{ key: "buildable", status: "loading" }]);
            // Update just volume
            // getInterpretedVolume(capacity);
            // Or update all three
            getInterpretedObjects(capacity, true);
        }

    }, [ruleUpdateTracker]);

    const [rulesetUpdateTracker, setRulesetUpdateTracker] = useState(0);
    useEffect(() => {
        if (rulesetUpdateTracker !== 0) {
            // console.log("!!!!!! OFFICIAL RULESET CHANGE", rulesetUpdateTracker);
            // Global update
            setGlobalUpdateTracker({ value: rulesetUpdateTracker, item: "rule" });
        }

    }, [rulesetUpdateTracker]);

    const [highlightedRule, setHighlightedRule] = useState(null);
    const [hiddenRule, setHiddenRule] = useState(null);

    const [volumeUpdateTracker, setVolumeUpdateTracker] = useState(0);
    useEffect(() => {
        if (volumeUpdateTracker !== 0) {
            // console.log("!!!!!! OFFICIAL VOLUME CHANGE", volumeUpdateTracker);
            // Global update
            setGlobalUpdateTracker({ value: volumeUpdateTracker, item: "volume" });

            // Derivated step : get combinations TO DO

        }
    }, [volumeUpdateTracker]);

    const [highlightedSitadel, setHighlightedSitadel] = useState(null);



    // BOUNDS
    const [boundUpdateTracker, setBoundUpdateTracker] = useState(0);
    useEffect(() => {
        if (boundUpdateTracker !== 0) {
            // console.log("!!!!!! OFFICIAL BOUND CHANGE", boundUpdateTracker);
            // Global update
            setGlobalUpdateTracker({ value: boundUpdateTracker, item: "bound" });

            // Derivated step : get buildable volume
            // addToaster("buildable");
            handleToaster([{ key: "buildable", status: "loading" }]);
            // Update just volume
            // getInterpretedVolume(capacity);
            // Or update all three
            getInterpretedObjects(capacity, true);
        }

    }, [boundUpdateTracker]);

    // BUILDINGS
    const [buildingUpdateTracker, setBuildingUpdateTracker] = useState(0);
    useEffect(() => {
        if (buildingUpdateTracker !== 0) {
            // console.log("!!!!!! OFFICIAL BUILDING CHANGE", buildingUpdateTracker);
            // Global update
            setGlobalUpdateTracker({ value: buildingUpdateTracker, item: "building" });

            // Derivated step : get buildable volume
            // addToaster("buildable");
            handleToaster([{ key: "buildable", status: "loading" }]);
            // Update just volume
            // getInterpretedVolume(capacity);
            // Or update all three
            getInterpretedObjects(capacity, true);
        }

    }, [buildingUpdateTracker]);


    // COMBINATIONS
    const [highlightedCombination, setHighlightedCombination] = useState([]);



    // RELOAD DATA
    const reloadData = (dataType) => {
        if (dataType === "landBase") {
            // Set data
            reloading = "landBase";
            var landBase = capacity.landBase;
            landBase.topo = "fetching";
            // Scroll
            var topSection = document.getElementById("section_1").offsetTop;
            var topElement = document.getElementById("land_loading_top").offsetTop;
            document.getElementById("section_data").scrollTop = topSection + topElement + 30;
            // Toaster
            handleToaster([{ key: "land", status: "loading" }]);
            // Send
            setCapacity({ ...capacity, landBase: landBase, toSave: { autoSave: false, action: "", value: "" } });
        }
        if (dataType === "risks") {
            reloading = "risks";
            // Scroll
            var topSection = document.getElementById("section_2").offsetTop;
            document.getElementById("section_data").scrollTop = topSection - 15;
            // Toaster
            handleToaster([{ key: "risks", status: "loading" }]);
            // Send
            setCapacity({ ...capacity, risks: "fetching", toSave: { autoSave: false, action: "", value: "" } });
        }
        if (dataType === "context") {
            reloading = "context";
            // Scroll
            var topSection = document.getElementById("section_3").offsetTop;
            document.getElementById("section_data").scrollTop = topSection - 15;
            // Toaster
            handleToaster([{ key: "context", status: "loading" }]);
            // Send
            setCapacity({ ...capacity, context: "fetching", toSave: { autoSave: true, action: "", value: "" } });
        }
        if (dataType === "plu") {
            // Set data
            reloading = "plu";
            var rules = capacity.rules;
            rules.gpu_data = "fetching";
            // Scroll
            var topSection = document.getElementById("section_4").offsetTop;
            document.getElementById("section_data").scrollTop = topSection - 15;
            // Toaster
            handleToaster([{ key: "gpu", status: "loading" }]);
            // Send
            setCapacity({ ...capacity, rules: rules, toSave: { autoSave: false, action: "", value: "" } });
        }
    }


    // MODALS
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isExportPDF, setIsExportPDF] = useState(false);
    const [isExportIFC, setIsExportIFC] = useState(false);

    const test_sup_inondation = async () => {
        // const response = await fetch('https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&version=1.1.1&request=GetFeatureInfo&exceptions=application%2Fjson&id=SUP_INOND__68&layers=SUP_INOND&query_layers=SUP_INOND&x=51&y=51&height=101&width=101&srs=EPSG%3A4326&bbox=' + capacity?.landBase?.union?.bbox?.matrix?.lat_min + '%2C' + capacity?.landBase?.union?.bbox?.matrix?.lng_min + '%2C' + capacity?.landBase?.union?.bbox?.matrix?.lat_max + '%2C' + capacity?.landBase?.union?.bbox?.matrix?.lng_max, { method: 'GET' });
        // console.log("SUP INOND API response", response);
        // const response_text = await response.text();
        // console.log("SUP INOND API response text", response_text);
        // if (response_text.includes("Layer 'SUP_INOND'")) {
        //     console.log("INNONDATION RISK");
        // }
        // else {
        //     console.log("NO INNONDATION RISK");
        // }
        setNavigation(navigation);

    }

    const test_osm = async () => {
        if (capacity?.landBase?.union?.center?.geometry?.type === "Point") {
            // Get bbox
            var bbox_turf_150m = turf.bbox(turf.circle(capacity.landBase.union.center, 0.15, { steps: 10 }));
            var bbox_150m = [bbox_turf_150m[1], bbox_turf_150m[0], bbox_turf_150m[3], bbox_turf_150m[2]];
            var bbox_turf_200m = turf.bbox(turf.circle(capacity.landBase.union.center, 0.20, { steps: 10 }));
            var bbox_200m = [bbox_turf_200m[1], bbox_turf_200m[0], bbox_turf_200m[3], bbox_turf_200m[2]];
            var bbox_turf_500m = turf.bbox(turf.circle(capacity.landBase.union.center, 0.5, { steps: 10 }));
            var bbox_500m = [bbox_turf_500m[1], bbox_turf_500m[0], bbox_turf_500m[3], bbox_turf_500m[2]];
            var bbox_turf_1000m = turf.bbox(turf.circle(capacity.landBase.union.center, 1, { steps: 10 }));
            var bbox_1000m = [bbox_turf_1000m[1], bbox_turf_1000m[0], bbox_turf_1000m[3], bbox_turf_1000m[2]];
            var bbox_turf_5000m = turf.bbox(turf.circle(capacity.landBase.union.center, 5, { steps: 10 }));
            var bbox_5000m = [bbox_turf_5000m[1], bbox_turf_5000m[0], bbox_turf_5000m[3], bbox_turf_5000m[2]];
            // EXAMPLES
            // Arbres 1 : (bbox_150m, ["node[natural=tree]"]) à recombiner avec Arbres 2
            // Arbres 2 : (bbox_200m, ["nwr[natural=tree_row]"])
            // Quartier : (bbox_1000m, ["relation[admin_level=10]"]) try 11 first, if not try 10 NOT TO USE
            // Parcs & jardins : (bbox_500m, ["nwr[leisure=park]"])
            // Santé : (bbox_1000m, ["node[healthcare=clinic]","node[healthcare=dentist]","node[healthcare=doctor]","node[healthcare=hospital]","node[healthcare=laboratory]","node[healthcare=nurse]","node[healthcare=pharmacy]"])
            // Borne électrique : (bbox_500m, ["node[amenity=charging_station]"])
            // Parkings : (bbox_500m, ["nwr[amenity=parking]"])
            // Education : (bbox_1000m, ["node[amenity=college]", "node[amenity=kindergarten]", "node[amenity=music_school]", "node[amenity=language_school]", "node[amenity=school]", "node[amenity=university]"])
            // Transports 1 (bus, tram, métro) : (bbox_500m, ["relation[route=bus]","relation[route=subway]","relation[route=tram]"])
            // Transports 2 (vélo) : (bbox_500m, ["node[bicycle_rental=docking_station]"])
            // Transports 3 (gare ferroviaire) : (bbox_1000m, ["node[railway=station]","node[railway=halt]"])
            // Magasins : (bbox_1000m, ["nwr[shop=supermarket]","nwr[shop=mall]"])
            // ==> Le resultat doit etre un objet avec une clé "items" étant une liste ou un objet avec des listes par catégories
            // var osm_data = await fetchOSMdata_bbox(bbox_1000m, ["nwr[shop=supermarket]","nwr[shop=mall]"]);
            var osm_data = await fetchOSMdata_point(capacity?.landBase?.union?.center?.geometry?.coordinates, bbox_150m, [{ key: "admin_level", value: "11" }, { key: "admin_level", value: "10" }], "single");
            console.log("OSM DATA", osm_data);
        }
    }
    const fetchOSMdata_point = async (point_coords, bbox, key_list, type_response) => {
        var result = "error";
        try {
            const data = "[timeout:10][out:json];is_in(" + point_coords[1] + "," + point_coords[0] + ")->.a;way(pivot.a);out tags bb;out ids geom(" + bbox.join(",") + ");relation(pivot.a);out tags bb;";
            const response = await fetch('https://www.overpass-api.de/api/interpreter?', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: data
            });
            const response_json = await response.json();
            console.log("OSM API response", response_json);
            if (response_json?.elements) {
                result = response_json?.elements;
                var results_filtered = [];
                for (var i = 0; i < key_list.length; i++) {
                    var matched = null;
                    for (var j = 0; j < result.length; j++) {
                        if (result[j]?.tags && Object.keys(result[j].tags).includes(key_list[i]?.key) && result[j].tags[key_list[i].key] === key_list[i]?.value) {
                            matched = result[j];
                            break;
                        }
                    }
                    if (matched !== null) {
                        results_filtered.push(matched);
                        if (type_response === "single") {
                            break;
                        }
                    }
                }
                result = results_filtered;
            }

            return result

        } catch (error) {
            return "error"
        }
    }
    const fetchOSMdata_bbox = async (bbox, key_list, isDeep) => {
        var result = "error";
        try {
            const data_keys = key_list.map(key => { return key + "(" + bbox.join(",") + ");" });
            // const data_deep = isDeep ? ";(._;>;)" : "";
            const data_deep = ";(._;>;)";
            const data = "[out:json];(" + data_keys.join("") + ")" + data_deep + ";out;";
            console.log("DATA", data);
            const response = await fetch('https://www.overpass-api.de/api/interpreter?', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: data
            });
            const response_json = await response.json();
            console.log("OSM API response", response_json);
            if (response_json?.elements) {
                result = response_json?.elements;
                // POST TREATMENT
                // Arbres 1
                if (key_list.includes("node[natural=tree]")) {
                    var result_filtered = [];
                    var trees_inside_land = [];
                    var trees_less_5m = [];
                    result.forEach(res => {
                        if (res?.type === "node" && res?.tags?.natural === "tree" && res?.lon && res?.lat) {
                            var distance = get_distance_point_land([res.lon, res.lat], capacity);
                            result_filtered.push({
                                id: res?.id,
                                coords: [res.lon, res.lat],
                                type: res.type,
                                properties: {
                                    distance: distance,
                                    height: res?.tags?.height || res?.tags?.est_height || null,
                                    diameter: res?.tags?.diameter || null,
                                    circumference: res?.tags?.circumference || null,
                                }
                            });
                        }
                    })
                    // var result_sorted = result_filtered.sort(function (a, b) {
                    //     let x = a.properties.distance;
                    //     let y = b.properties.distance;
                    //     if (x > y) { return 1; }
                    //     if (x < y) { return -1; }
                    //     return 0;
                    // });
                    for (var i = 0; i < result_filtered.length; i++) {
                        if (result_filtered[i].properties.distance === 0) {
                            trees_inside_land.push(i);
                        }
                        if (result_filtered[i].properties.distance <= 5) {
                            trees_less_5m.push(i);
                        }
                    }
                    result = {
                        items: result_filtered,
                        inside: trees_inside_land,
                        near_5m: trees_less_5m
                    };
                }
                // Arbres 2
                if (key_list.includes("nwr[natural=tree_row]")) {
                    var result_filtered = [];
                    var result_simplified = [];
                    var result_splitted = [];
                    var result_nodes = {};
                    var trees_inside_land = [];
                    var trees_less_5m = [];
                    result.forEach(res => {
                        if (res?.type === "way" && res?.tags?.natural === "tree_row") {
                            result_filtered.push(res);
                        }
                        if (res?.type === "node" && res?.id && res?.lon && res?.lat) {
                            result_nodes[(res.id).toString()] = res;
                        }
                    })
                    // Simplify & Add cords
                    result_filtered.forEach(res_fil => {
                        var coords = [];
                        res_fil.nodes.forEach(nd_id => {
                            if (Object.keys(result_nodes).includes(nd_id.toString())) {
                                coords.push([result_nodes[nd_id].lon, result_nodes[nd_id].lat]);
                            }
                        })
                        var length = 0;
                        if (coords.length > 1) {
                            var linestring = turf.lineString(coords);
                            length = turf.length(linestring) * 1000;
                            var nb_seg = Math.round(length / 8);
                            var lg_seg = length / nb_seg;
                            var points = [];
                            // Cretae equidistant points along linestring
                            for (var i = 0; i < (nb_seg + 1); i++) {
                                if (i === 0) {
                                    points.push(coords[0]);
                                }
                                else if (i === nb_seg) {
                                    points.push(coords[coords.length - 1]);
                                }
                                else {
                                    var along = turf.along(linestring, i * (lg_seg / 1000));
                                    if (along?.geometry?.coordinates) {
                                        points.push(along.geometry.coordinates);
                                    }
                                }
                            }
                            points.forEach((point, point_index) => {
                                var distance = get_distance_point_land(point, capacity);
                                result_splitted.push({
                                    id: res_fil?.id + "_" + point_index,
                                    coords: point,
                                    type: "node",
                                    properties: {
                                        distance: distance,
                                        height: res_fil?.tags?.height || res_fil?.tags?.est_height || null,
                                        diameter: res_fil?.tags?.diameter || null,
                                        circumference: res_fil?.tags?.circumference || null,
                                    }
                                });
                            })
                            // var result_sorted = result_splitted.sort(function (a, b) {
                            //     let x = a.properties.distance;
                            //     let y = b.properties.distance;
                            //     if (x > y) { return 1; }
                            //     if (x < y) { return -1; }
                            //     return 0;
                            // });
                            for (var i = 0; i < result_splitted.length; i++) {
                                if (result_splitted[i].properties.distance === 0) {
                                    trees_inside_land.push(i);
                                }
                                if (result_splitted[i].properties.distance <= 5) {
                                    trees_less_5m.push(i);
                                }
                            }
                            result = {
                                items: result_splitted,
                                inside: trees_inside_land,
                                near_5m: trees_less_5m
                            };
                        }
                    })
                }
                // Parcs & jardin
                else if (key_list.includes("nwr[leisure=park]")) {
                    var result_filtered = [];
                    var result_simplified = [];
                    var result_nodes = {};
                    result.forEach(res => {
                        if (res?.type === "way" && res?.tags?.name && !(res?.tags?.access === "private") && res?.nodes && res.nodes.length > 2) {
                            result_filtered.push(res);
                        }
                        if (res?.type === "node" && res?.id && res?.lon && res?.lat) {
                            result_nodes[(res.id).toString()] = res;
                        }
                    })
                    // Simplify & Add cords
                    result_filtered.forEach(res_fil => {
                        var coords = [];
                        res_fil.nodes.forEach(nd_id => {
                            if (Object.keys(result_nodes).includes(nd_id.toString())) {
                                coords.push([result_nodes[nd_id].lon, result_nodes[nd_id].lat]);
                            }
                        })
                        // Close coords if needed
                        if (coords.length > 0 && (coords[0][0] !== coords[coords.length - 1][0] || coords[0][1] !== coords[coords.length - 1][1])) {
                            coords.push(coords[0]);
                        }
                        result_simplified.push({
                            id: res_fil?.id,
                            coords: coords,
                            type: res_fil?.type,
                            properties: { name: res_fil?.tags?.name || "" },
                        })
                    })
                    result = { items: result_simplified };
                }
                // Santé
                else if (key_list.includes("node[healthcare=clinic]")) {
                    var result_filtered = {
                        clinic: [],
                        doctor: [],
                        hospital: [],
                        laboratory: [],
                        nurse: [],
                        pharmacy: [],
                    };
                    result.forEach(res => {
                        if (res?.tags?.healthcare === "dentist") {
                            res.tags.healthcare = "doctor";
                            res.tags["healthcare:speciality"] = "dentist";
                        }
                        if (res?.type === "node" && res?.tags?.healthcare && res?.tags?.name && res?.lon && res?.lat && Object.keys(result_filtered).includes(res.tags.healthcare)) {
                            var spe = null;
                            if (res?.tags && Object.keys(res.tags).includes("healthcare:speciality")) {
                                spe = res.tags["healthcare:speciality"];
                            }
                            result_filtered[res.tags.healthcare].push({
                                id: res?.id,
                                coords: [res.lon, res.lat],
                                type: res.type,
                                properties: {
                                    name: res?.tags?.name || "",
                                    speciality: spe
                                },
                            });
                        }
                    })
                    result = { items: result_filtered };
                }
                // Bornes
                else if (key_list.includes("node[amenity=charging_station]")) {
                    var result_filtered = [];
                    result.forEach(res => {
                        if (res?.type === "node" && res?.lon && res?.lat) {
                            result_filtered.push({
                                id: res?.id,
                                coords: [res.lon, res.lat],
                                type: res.type,
                                properties: {
                                    access: res?.tags?.access || null,
                                    slots: res?.tags?.capacity || null,
                                    fee: res?.tags?.fee || null,
                                }
                            });
                        }
                    })
                    result = { items: result_filtered };
                }
                // Vélos
                else if (key_list.includes("node[bicycle_rental=docking_station]")) {
                    var result_filtered = [];
                    result.forEach(res => {
                        if (res?.type === "node" && res?.tags?.name && res?.lon && res?.lat) {
                            var distance = get_distance_point_land([res.lon, res.lat], capacity);
                            result_filtered.push({
                                id: res?.id,
                                coords: [res.lon, res.lat],
                                type: res.type,
                                properties: {
                                    name: res?.tags?.name || null,
                                    distance: distance,
                                    slots: res?.tags?.capacity ? parseInt(res.tags.capacity) : null,
                                    network: res?.tags?.network || null,
                                    operator: res?.tags?.operator || null,
                                }
                            });
                        }
                    })
                    var result_sorted = result_filtered.sort(function (a, b) {
                        let x = a.properties.distance;
                        let y = b.properties.distance;
                        if (x > y) { return 1; }
                        if (x < y) { return -1; }
                        return 0;
                    });
                    result = { items: result_sorted };
                }
                // Gare
                else if (key_list.includes("node[railway=station]")) {
                    var result_filtered = [];
                    result.forEach(res => {
                        if (res?.type === "node" && res?.tags?.name && res?.lon && res?.lat && res?.tags?.train === "yes") {
                            var distance = get_distance_point_land([res.lon, res.lat], capacity);
                            result_filtered.push({
                                id: res?.id,
                                coords: [res.lon, res.lat],
                                type: res.type,
                                properties: {
                                    name: res?.tags?.name || null,
                                    distance: distance,
                                    operator: res?.tags?.operator || null,
                                }
                            });
                        }
                    })
                    var result_sorted = result_filtered.sort(function (a, b) {
                        let x = a.properties.distance;
                        let y = b.properties.distance;
                        if (x > y) { return 1; }
                        if (x < y) { return -1; }
                        return 0;
                    });
                    result = { items: result_sorted };
                }
                // Parkings
                else if (key_list.includes("nwr[amenity=parking]")) {
                    var result_filtered = [];
                    var result_simplified = [];
                    var result_nodes = {};
                    var total_slots = 0;
                    result.forEach(res => {
                        if (res?.type === "way" && !(res?.tags?.access === "private") && res?.nodes && res.nodes.length > 2) {
                            result_filtered.push(res);
                        }
                        if (res?.type === "node" && res?.id && res?.lon && res?.lat) {
                            result_nodes[(res.id).toString()] = res;
                        }
                    })
                    // Simplify & Add cords
                    result_filtered.forEach(res_fil => {
                        var coords = [];
                        var area = null;
                        var est_slots = null;
                        res_fil.nodes.forEach(nd_id => {
                            if (Object.keys(result_nodes).includes(nd_id.toString())) {
                                coords.push([result_nodes[nd_id].lon, result_nodes[nd_id].lat]);
                            }
                        })
                        // Close coords if needed
                        if (coords.length > 0 && (coords[0][0] !== coords[coords.length - 1][0] || coords[0][1] !== coords[coords.length - 1][1])) {
                            coords.push(coords[0]);
                        }
                        if (coords.length > 0 && coords[0][0] === coords[coords.length - 1][0] && coords[0][1] === coords[coords.length - 1][1]) {
                            area = turf.area(turf.polygon([coords]));
                            est_slots = Math.max(Math.round(area / 28), 1);
                        }
                        result_simplified.push({
                            id: res_fil?.id,
                            coords: coords,
                            type: res_fil?.type,
                            properties: {
                                access: res_fil?.tags?.access || null,
                                category: res_fil?.tags?.parking || null,
                                slots: res_fil?.tags?.capacity ? parseInt(res_fil?.tags?.capacity) : est_slots,
                                fee: res_fil?.tags?.fee || null,
                                area: area
                            },
                        })
                        total_slots = 0;
                        result_simplified.forEach(item => {
                            total_slots = total_slots + (item?.properties?.slots || 0)
                        })
                    })
                    result = { items: result_simplified, total_slots };
                }
                // Education
                else if (key_list.includes("node[amenity=college]")) {
                    var result_filtered = {
                        kindergarten: [],
                        maternel: [],
                        elementary: [],
                        primary: [],
                        medschool: [],
                        highschool: [],
                        university: [],
                        music_school: [],
                        language_school: []
                    };
                    result.forEach(res => {
                        if (res?.tags?.amenity === "school") {
                            if ((Object.keys(res.tags).includes("school:FR") && res.tags["school:FR"] === "maternelle") || (res?.tags?.name && res.tags.name.toLowerCase().includes("maternelle"))) {
                                res.tags.amenity = "maternel";
                            }
                            else if ((Object.keys(res.tags).includes("school:FR") && res.tags["school:FR"] === "élémentaire") || (res?.tags?.name && res.tags.name.toLowerCase().includes("élémentaire"))) {
                                res.tags.amenity = "elementary";
                            }
                            else if ((Object.keys(res.tags).includes("school:FR") && res.tags["school:FR"] === "primaire") || (res?.tags?.name && res.tags.name.toLowerCase().includes("primaire"))) {
                                res.tags.amenity = "primary";
                            }
                            else if ((Object.keys(res.tags).includes("school:FR") && res.tags["school:FR"] === "collège") || (res?.tags?.name && res.tags.name.toLowerCase().includes("collège"))) {
                                res.tags.amenity = "medschool";
                            }
                            else if ((Object.keys(res.tags).includes("school:FR") && res.tags["school:FR"] === "lycée") || (res?.tags?.name && res.tags.name.toLowerCase().includes("lycée"))) {
                                res.tags.amenity = "highschool";
                            }
                        }
                        else if (res?.tags?.amenity === "college") {
                            res.tags.amenity = "university";
                        }
                        if (res?.type === "node" && res?.tags?.amenity && res?.tags?.name && res?.lon && res?.lat && Object.keys(result_filtered).includes(res.tags.amenity)) {
                            var operator = null;
                            if ((Object.keys(res.tags).includes("operator:type") && res.tags["operator:type"] === "public") || (res?.tags?.name && (res.tags.name.toLowerCase().includes("public") || res.tags.name.toLowerCase().includes("publique")))) {
                                operator = "public";
                            }
                            else if ((Object.keys(res.tags).includes("operator:type") && res.tags["operator:type"] === "private") || (res?.tags?.name && (res.tags.name.toLowerCase().includes("privé") || res.tags.name.toLowerCase().includes("privée")))) {
                                operator = "private";
                            }
                            result_filtered[res.tags.amenity].push({
                                id: res?.id,
                                coords: [res.lon, res.lat],
                                type: res.type,
                                properties: {
                                    name: res?.tags?.name || "",
                                    operator: operator
                                },
                            });
                        }
                    })
                    result = { items: result_filtered };
                }
                // Transport
                else if (key_list.includes("relation[route=bus]")) {
                    var result_filtered = [];
                    var result_nodes = {};
                    result.forEach(res => {
                        if (res?.type === "relation") {
                            var members_filtered = [];
                            res.members.forEach(mem => {
                                if (mem?.type === "node" && mem?.ref && mem?.role && (mem.role.includes("platform") || mem.role.includes("stop"))) {
                                    members_filtered.push(mem.ref);
                                }
                            })
                            res.nodes = members_filtered;
                            result_filtered.push(res);
                        }
                        // Get nodes
                        else if (res?.type === "node" && res?.id && res?.tags?.name && res?.lon && res?.lat) {
                            result_nodes[(res.id).toString()] = res;
                        }
                    })
                    // Simplify & Add cords
                    var result_simplified = [];
                    var result_ref = {};
                    var result_counter = -1;
                    result_filtered.forEach(res_fil => {
                        var stops = [];
                        var nearest_stop = null;
                        var counter = -1;
                        res_fil.nodes.forEach(nd_id => {
                            if (Object.keys(result_nodes).includes(nd_id.toString())) {
                                counter++;
                                var distance = get_distance_point_land([result_nodes[nd_id].lon, result_nodes[nd_id].lat], capacity);
                                stops.push({
                                    id: nd_id,
                                    coords: [result_nodes[nd_id].lon, result_nodes[nd_id].lat],
                                    distance: distance,
                                    name: result_nodes[nd_id]?.tags?.name || "",
                                    level: result_nodes[nd_id]?.tags?.level || null,
                                    // tags: result_nodes[nd_id]?.tags,
                                });
                                if (nearest_stop === null || distance < stops[nearest_stop]?.distance) {
                                    nearest_stop = counter;
                                }
                            }
                        })
                        if (nearest_stop !== null && stops[nearest_stop]?.distance <= 500) {
                            result_counter++;
                            result_simplified.push({
                                id: res_fil?.id,
                                stops: stops,
                                nearest_stop: nearest_stop,
                                type: res_fil?.type,
                                properties: {
                                    name: res_fil?.tags?.name || null,
                                    ref: res_fil?.tags?.ref || null,
                                    color: res_fil?.tags?.colour || null,
                                    color_txt: res_fil?.tags?.text_colour || null,
                                    category: res_fil?.tags?.route || null,
                                },
                                // tags: res_fil?.tags || null,
                            })
                            // Get ref
                            var ref = res_fil?.tags?.ref + "__" + res_fil?.tags?.operator;
                            if (res_fil?.tags?.ref && !Object.keys(result_ref).includes(ref)) {
                                result_ref[ref] = [result_counter];
                            }
                            else if (res_fil?.tags?.ref && Object.keys(result_ref).includes(ref)) {
                                result_ref[ref].push(result_counter);
                            }
                        }
                    })
                    console.log("simplified", result_simplified);
                    console.log("REFs", result_ref);
                    // Aggregate by ref
                    var result_aggregated = [];
                    Object.keys(result_ref).forEach(ref_key => {
                        var result_ref_item = result_ref[ref_key];
                        if (result_ref_item.length > 0) {
                            var line0 = result_simplified[result_ref_item[0]];
                            var name = null;
                            if (result_ref_item.length > 1) {
                                name = get_matching_substring(line0?.properties?.name, result_simplified[result_ref_item[1]]?.properties?.name);
                            }
                            if (name === null) {
                                name = (line0?.properties?.category === "bus" ? "Bus " : line0?.properties?.category === "subway" ? "Métro " : line0?.properties?.category === "tram" ? "Tram " : "") + (line0?.properties?.ref || "");
                            }
                            var lines = [];
                            var nearest_stop = null;
                            result_ref_item.forEach((line_index, i) => {
                                lines.push(result_simplified[line_index]);
                                if (nearest_stop === null || nearest_stop.distance > result_simplified[line_index].stops[result_simplified[line_index]["nearest_stop"]]?.distance) {
                                    nearest_stop = {
                                        line_i: i,
                                        distance: result_simplified[line_index].stops[result_simplified[line_index]["nearest_stop"]]?.distance,
                                        name: result_simplified[line_index].stops[result_simplified[line_index]["nearest_stop"]]?.name,
                                    }
                                }
                            })
                            result_aggregated.push({
                                ref: line0?.properties?.ref || "",
                                name: name,
                                color: line0?.properties?.color || null,
                                color_txt: line0?.properties?.color_txt || null,
                                category: line0?.properties?.category || null,
                                lines: lines,
                                nearest_stop: nearest_stop
                            })
                        }
                    })
                    console.log("aggregated", result_aggregated);
                    var result_categorized = {
                        bus: [],
                        subway: [],
                        tram: [],
                        other: []
                    }
                    result_aggregated.forEach(aggreg => {
                        if (aggreg?.category && Object.keys(result_categorized).includes(aggreg?.category)) {
                            result_categorized[aggreg.category].push(aggreg);
                        }
                        else {
                            result_categorized["other"].push(aggreg);
                        }
                    })
                    console.log("categorized", result_categorized);
                    Object.keys(result_categorized).forEach(cat => {
                        var result_sorted = result_categorized[cat].sort(function (a, b) {
                            let x = a.nearest_stop.distance;
                            let y = b.nearest_stop.distance;
                            if (x > y) { return 1; }
                            if (x < y) { return -1; }
                            return 0;
                        });
                        result_categorized[cat] = result_sorted;
                    })
                    result = { items: result_categorized };
                }
            }
            return result
        } catch (error) {
            console.log("OSM API error", error);
            return "error"
        }
    }

    const get_distance_point_land = (point_coords, capacity) => {
        if (point_coords.length === 2 && capacity?.landBase?.union?.geometry?.geometry?.type === "Polygon" && capacity?.landBase?.union?.outerLine?.geometry?.type === "LineString") {
            var isInside = turf.booleanPointInPolygon(turf.point(point_coords), capacity?.landBase?.union?.geometry);
            var distance = 0;
            if (isInside === false) {
                // distance = turf.distance(turf.point([res.lon, res.lat]), turf.point(point_coords)) * 1000;
                distance = Math.round(turf.pointToLineDistance(turf.point(point_coords), capacity?.landBase?.union?.outerLine) * 1000);
            }
            return distance
        }
        else {
            return null
        }
    }

    const get_matching_substring = (string1, string2) => {
        var list1 = string1.split(" ");
        var list2 = string2.split(" ");

        var matching_text = null;
        var matching_list = [];
        for (var i = 0; i < list1.length; i++) {
            if (list1[i].toLowerCase() === "direction") {
                break
            }
            else if (list1[i] === list2[i]) {
                matching_list.push(list1[i]);
            }
            else {
                break
            }
        }
        if (matching_list.length > 0) {
            matching_text = matching_list.join(" ");
        }

        return matching_text
    }


    return (
        <div>
            {isError &&
                <ErrorPage setIsError={setIsError} errorMsg={errorMsg} version={version} />
            }
            {/* <RuleEditorContainer /> */}
            {isRuleCatalogOpen &&
                <RuleCatalog ruleGroup_Id={selectedRuleGroup} currentType={ruleCatalogType} setCurrentType={setRuleCatalogType} setIsRuleCatalogOpen={setIsRuleCatalogOpen} capacity={capacity} setCapacity={setCapacity} user={user} rulesCatalogTeam={rulesCatalogTeam} rulesUpdate={rulesUpdate} setRuleUpdateTracker={setRuleUpdateTracker} />
            }
            {isModalOpen &&
                <div className="app__update_capa_modal_container">
                    <div className="app__update_capa_modal" onClick={() => { setIsModalOpen(false) }}>

                    </div>
                </div>
            }
            {isExportPDF &&
                <ExportationMenuPDF capacity={capacity} setCapacity={setCapacity} handleToaster={handleToaster} user={user} setIsExportPDF={setIsExportPDF} />
            }
            {isExportIFC &&
                <ExportationMenuIFC capacity={capacity} setCapacity={setCapacity} handleToaster={handleToaster} user={user} setIsExportIFC={setIsExportIFC} />
            }
            <ToasterContainer toastersData={toastersData} />
            <Navbar layout={navigation.layout} isApp={true} targetSection={targetSection} setTargetSection={setTargetSection} currentSection={currentSection} updateCurrentSection={updateCurrentSection} version={version} environment={environment} isMapExpanded={isMapExpanded} setIsMapExpanded={setIsMapExpanded} />
            <Header title="Etude Capacitaire" isApp={true} capacity={capacity} />
            {/* SECTION DATA */}
            <animated.div style={{ top: sectionDataStyle.top, height: sectionDataStyle.height, opacity: sectionDataStyle.opacity }} id="section_data" className={`app__sectiondata_UI ${(isMapReady === false || isMapExpanded === true) ? "app__sectiondata_hidden_UI" : ""}`}>
                {/* SECTION DATA PART 0 */}
                <div id='section_0' className="app__sectiondata_container_UI" style={{ marginBottom: capacity?.step ? '' : '0px' }}>
                    {(mapDrawMode?.mode && mapDrawMode.mode !== null) &&
                        <div className="app__section_data_disabled"></div>
                    }
                    {(isMapReady && capacity?.title) ?
                        <>
                            <div id="data_title_0" className="app__sectiondata_title_container_UI">
                                {window.innerWidth > 1050 &&
                                    <div className="app__sectiondata_widget_spacer_15"></div>
                                }
                                <div className="app__sectiondata_title_label">{navigation.layout[0]['title_long']}</div>
                                <div className="app__sectiondata_title_bar"></div>
                            </div>
                            <div className="app__sectiondata_body_container_UI">
                                {/* <div className="app__sectiondata_body_container_UI" style={{ minHeight: sectionMinHeight }}> */}
                                <TitleAndDescription capacity={capacity} setCapacity={setCapacity} />
                                <Location capacity={capacity} setCapacity={setCapacity} />
                                {(capacity?.step >= 1 && user?.access?.export === true) &&
                                    <Exportation capacity={capacity} setCapacity={setCapacity} handleToaster={handleToaster} setIsExportPDF={setIsExportPDF} setIsExportIFC={setIsExportIFC} />
                                }
                                <Modifications capacity={capacity} user={user} />
                                {window.innerWidth <= 550 &&
                                    <div className="app__sectiondata_widget_spacer_10"></div>
                                }
                                {environment !== "PROD" &&
                                    <>
                                        <div style={{ marginTop: '10px', cursor: 'pointer' }} onClick={() => { test_osm() }}>🚧 TEST API OSM 🚧</div>
                                        <div style={{ marginTop: '10px', cursor: 'pointer' }} onClick={() => { test_sup_inondation() }}>🚧 TEST API SUP INOND 🚧</div>
                                    </>
                                }
                            </div>
                        </>
                        :
                        <>
                            {isAccessDenied ?
                                <>
                                    {/* <div className="app__sectiondata_body_container_UI" style={{ minHeight: sectionMinHeight }}> */}
                                    <div className="app__sectiondata_body_container_UI">
                                        <div className="app__sectiondata_loading_container_full">
                                            <div className="app__sectiondata_loading_text"><span style={{ fontSize: "20px" }}>😥</span><br></br>Vous n'avez pas le droit d'accéder à cette étude</div>
                                            <div className="combi__launch_button" onClick={() => { navigate('/dashboard') }}>Retourner au Dashboard</div>
                                        </div>
                                    </div>
                                </>
                                :
                                <div className="app__sectiondata_body_container_UI">
                                    {/* <div className="app__sectiondata_body_container_UI" style={{ minHeight: sectionMinHeight }}> */}
                                    <div className="app__sectiondata_loading_container_full">
                                        <div className="app__sectiondata_loading"></div>
                                        <div className="app__sectiondata_loading_text">Nous récupérons les données de cette étude</div>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
                {/* SECTION DATA PART 1 */}
                <div id='section_1' className="app__sectiondata_container_UI">
                    {(mapDrawMode?.mode && mapDrawMode.mode !== null) &&
                        <div className="app__section_data_disabled"></div>
                    }
                    {(isMapReady && capacity?.title) &&
                        <>
                            <div className="app__sectiondata_title_container_UI">
                                {window.innerWidth > 1050 &&
                                    <div className="app__sectiondata_widget_spacer_15"></div>
                                }
                                <div className="app__sectiondata_title_subcontainer">
                                    <div className="app__sectiondata_title_label">{navigation.layout[1]['title_long']}</div>
                                    {(capacity?.step >= 1 && user?.access?.land === true) &&
                                        <div className="app__sectiondata_title_reload">
                                            <img className={`app__sectiondata_title_reload_img ${capacity?.landBase?.topo === "fetching" ? "app__sectiondata_title_reload_img_loading" : ""}`} src={ico_reload} alt="" onClick={() => { reloadData("landBase") }} />
                                            <div className="app__sectiondata_title_reload_text">Mettre à jour les données externes</div>
                                        </div>
                                    }
                                </div>
                                <div className="app__sectiondata_title_bar"></div>
                            </div>
                            <div className="app__sectiondata_body_container_UI">
                                {/* <div className="app__sectiondata_body_container_UI" style={{ minHeight: sectionMinHeight }}> */}
                                <LandsDetail capacity={capacity} setCapacity={setCapacity} mapLayers={mapLayers} setMapLayers={setMapLayers} user={user} />
                                <div className="app__sectiondata_widget_spacer_40"></div>
                                <BoundsDetail capacity={capacity} setCapacity={setCapacity} mapLayers={mapLayers} setMapLayers={setMapLayers} setBoundUpdateTracker={setBoundUpdateTracker} />
                                <div id="land_loading_top"></div>
                                {capacity?.landBase?.topo === "fetching" ?
                                    <div className="app__sectiondata_loading_container_end">
                                        <div className="app__sectiondata_loading"></div>
                                        <div className="app__sectiondata_loading_text">Nous analysons les données du terrain</div>
                                        <div className="app__sectiondata_loading_text_small_container">
                                            <div className="app__sectiondata_noaccess_text_small">• Propriétaires des parcelles</div>
                                            <div className="app__sectiondata_noaccess_text_small">• Topographie</div>
                                            <div className="app__sectiondata_noaccess_text_small">• Arbres</div>
                                            <div className="app__sectiondata_noaccess_text_small">• Bâtiments existants</div>
                                            <div className="app__sectiondata_noaccess_text_small">• Imperméabilisation du sol</div>
                                            <div className="app__sectiondata_noaccess_text_small">• Réseau de chauffage urbain</div>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        {user?.access?.land === true ?
                                            <>
                                                {(capacity?.landBase?.topo && capacity.landBase.topo !== "fetching" && capacity.landBase.topo !== "error") &&
                                                    <Topo capacity={capacity} mapLayers={mapLayers} setMapLayers={setMapLayers} />
                                                }
                                                {/* {(capacity?.landBase?.trees && capacity?.landBase?.trees?.items && capacity.landBase.trees.items.length > 0) &&
                                                    <Trees capacity={capacity} mapLayers={mapLayers} setMapLayers={setMapLayers} />
                                                } */}
                                                {(capacity?.landBase?.buildings && capacity.landBase.buildings !== "error") &&
                                                    <Buildings capacity={capacity} setCapacity={setCapacity} mapLayers={mapLayers} setMapLayers={setMapLayers} setBuildingUpdateTracker={setBuildingUpdateTracker} />
                                                }
                                                {(capacity?.landBase?.location?.chaleur && capacity?.landBase?.location?.chaleur?.isEligible === true) &&
                                                    <HeatNetwork capacity={capacity} />
                                                }
                                                {window.innerWidth <= 550 &&
                                                    <div className="app__sectiondata_widget_spacer_20"></div>
                                                }
                                            </>
                                            :
                                            <div className="app__sectiondata_noaccess_container">
                                                <div className="app__sectiondata_noaccess_container_centered">
                                                    <div style={{ fontSize: "26px" }}>😓</div>
                                                    <div className="app__sectiondata_noaccess_text">Votre abonnement ne donne pas accès aux données analytiques du terrain.<br /></div>
                                                </div>
                                                <div className="app__sectiondata_noaccess_text_small">Ces données contiennent :</div>
                                                <div className="app__sectiondata_noaccess_text_small">- les propriétaires (personnes morales) des parcelles sélectionnées</div>
                                                {(capacity?.landBase?.lands && capacity?.landBase?.lands.length > 0 && capacity?.landBase?.lands.filter(element => { if (element?.majic) { return true; } return false; }).length > 0) &&
                                                    <div className="app__sectiondata_noaccess_text_note">→ nous avons identifié {capacity?.landBase?.lands.filter(element => { if (element?.majic) { return true; } return false; }).length} propriétaire{capacity?.landBase?.lands.filter(element => { if (element?.majic) { return true; } return false; }).length > 1 && "s"}</div>
                                                }
                                                <div className="app__sectiondata_noaccess_text_small">- le maillage topographique du terrain et le dénivelé maximum</div>
                                                <div className="app__sectiondata_noaccess_text_small">- le détail des bâtiments existants sur le terrain (nature, superficie, hauteur, DPE)</div>
                                                {(capacity?.landBase?.buildings?.buildings_land && capacity?.landBase?.buildings?.buildings_land.length > 0) &&
                                                    <div className="app__sectiondata_noaccess_text_note">→ nous avons analysé {capacity?.landBase?.buildings?.buildings_land.length} bâtiment{capacity?.landBase?.buildings?.buildings_land.length > 1 && "s"}</div>
                                                }
                                                <div className="app__sectiondata_noaccess_text_small">- le coefficient d'imperméabilisation du terrain</div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </>
                    }
                </div>
                {/* SECTION DATA PART 2 */}
                {currentStep >= 1 &&
                    <div id='section_2' className="app__sectiondata_container_UI">
                        {(mapDrawMode?.mode && mapDrawMode.mode !== null) &&
                            <div className="app__section_data_disabled"></div>
                        }
                        <div className="app__sectiondata_title_container_UI">
                            {window.innerWidth > 1050 &&
                                <div className="app__sectiondata_widget_spacer_15"></div>
                            }
                            <div className="app__sectiondata_title_subcontainer">
                                <div className="app__sectiondata_title_label">{navigation.layout[2]['title_long']}</div>
                                {user?.access?.risks === true &&
                                    <div className="app__sectiondata_title_reload">
                                        <img className={`app__sectiondata_title_reload_img ${capacity?.risks === "fetching" ? "app__sectiondata_title_reload_img_loading" : ""}`} src={ico_reload} alt="" onClick={() => { reloadData("risks") }} />
                                        <div className="app__sectiondata_title_reload_text">Mettre à jour les données externes</div>
                                    </div>
                                }
                            </div>
                            <div className="app__sectiondata_title_bar"></div>
                        </div>
                        {/* <div className="app__sectiondata_body_container_UI" style={{ minHeight: sectionMinHeight }}> */}
                        <div className="app__sectiondata_body_container_UI">
                            {user?.access?.risks === true ?
                                <>
                                    <Risks capacity={capacity} mapLayers={mapLayers} setMapLayers={setMapLayers} />
                                    {window.innerWidth <= 550 &&
                                        <div className="app__sectiondata_widget_spacer_10"></div>
                                    }
                                </>
                                :
                                <div className="app__sectiondata_noaccess_container">
                                    <div className="app__sectiondata_noaccess_container_centered">
                                        <div style={{ fontSize: "26px" }}>😓</div>
                                        <div className="app__sectiondata_noaccess_text">Votre abonnement ne donne pas accès aux données de l'analyse des risques.<br /></div>
                                    </div>
                                    <div className="app__sectiondata_noaccess_text_small">Ces données contiennent :</div>
                                    <div className="app__sectiondata_noaccess_text_small">- les risques naturels et industriels identifiés par GéoRisques</div>
                                    {((capacity?.risks?.natural && capacity?.risks?.natural.length > 1) || (capacity?.risks?.industrial && capacity?.risks?.industrial.length > 0)) &&
                                        <div className="app__sectiondata_noaccess_text_note">→ nous avons identifié {capacity?.risks?.natural.length + capacity?.risks?.industrial.length} risque{(capacity?.risks?.natural.length + capacity?.risks?.industrial.length) > 1 && "s"}</div>
                                    }
                                    <div className="app__sectiondata_noaccess_text_small">- le risque de sismicité</div>
                                    <div className="app__sectiondata_noaccess_text_small">- le risque lié au retrait-gonflement des sols argileux</div>
                                    <div className="app__sectiondata_noaccess_text_small">- la présence de canalisations de matières dangereuses</div>
                                    <div className="app__sectiondata_noaccess_text_small">- le risque de pollution du sol</div>
                                </div>
                            }
                        </div>
                    </div>
                }
                {/* SECTION DATA PART 3 : CONTEXT */}
                {currentStep >= 1 &&
                    <div id='section_3' className="app__sectiondata_container_UI">
                        {(mapDrawMode?.mode && mapDrawMode.mode !== null) &&
                            <div className="app__section_data_disabled"></div>
                        }
                        <div className="app__sectiondata_title_container_UI">
                            {window.innerWidth > 1050 &&
                                <div className="app__sectiondata_widget_spacer_15"></div>
                            }
                            <div className="app__sectiondata_title_subcontainer">
                                <div className="app__sectiondata_title_label">{navigation.layout[3]['title_long']}</div>
                                {user?.access?.context === true &&
                                    <div className="app__sectiondata_title_reload">
                                        <img className={`app__sectiondata_title_reload_img ${capacity?.context === "fetching" ? "app__sectiondata_title_reload_img_loading" : ""}`} src={ico_reload} alt="" onClick={() => { reloadData("context") }} />
                                        <div className="app__sectiondata_title_reload_text">Mettre à jour les données externes</div>
                                    </div>
                                }
                            </div>
                            <div className="app__sectiondata_title_bar"></div>
                        </div>
                        <div className="app__sectiondata_body_container_UI">
                            {/* <div className="app__sectiondata_body_container_UI" style={{ minHeight: sectionMinHeight }}> */}
                            {user?.access?.context === true ?
                                <>
                                    <Context capacity={capacity} mapLayers={mapLayers} setMapLayers={setMapLayers} highlightedSitadel={highlightedSitadel} />
                                    {window.innerWidth <= 550 &&
                                        <div className="app__sectiondata_widget_spacer_10"></div>
                                    }
                                </>
                                :
                                <div className="app__sectiondata_noaccess_container">
                                    <div className="app__sectiondata_noaccess_container_centered">
                                        <div style={{ fontSize: "26px" }}>😓</div>
                                        <div className="app__sectiondata_noaccess_text">Votre abonnement ne donne pas accès aux données de l'analyse contextuelle.<br /></div>
                                    </div>
                                    <div className="app__sectiondata_noaccess_text_small">Ces données contiennent :</div>
                                    <div className="app__sectiondata_noaccess_text_small">- la zone ABC</div>
                                    <div className="app__sectiondata_noaccess_text_small">- les statistiques de la population (CSP, âge moyen, composition des ménages)</div>
                                    <div className="app__sectiondata_noaccess_text_small">- le détail du parc immobilier de la commune (typologies, surfaces, pièces...)</div>
                                    <div className="app__sectiondata_noaccess_text_small">- la liste des transactions immobilières des parcelles du terrain et aux alentours</div>
                                    {(capacity?.context?.dvf?.dvf_transactions_land && capacity?.context?.dvf?.dvf_transactions_land.length > 0) &&
                                        <div className="app__sectiondata_noaccess_text_note">→ nous avons identifié {capacity?.context?.dvf?.dvf_transactions_land.length} transaction{capacity?.context?.dvf?.dvf_transactions_land.length > 1 && "s"} sur les parcelles du terrain</div>
                                    }
                                    <div className="app__sectiondata_noaccess_text_small">- la présence de monuments historiques aux alentours du terrain</div>
                                    <div className="app__sectiondata_noaccess_text_small">- les différentes commodités à proximité du terrain</div>
                                </div>
                            }
                        </div>
                    </div>
                }
                {/* SECTION DATA PART 4 : RULES */}
                {currentStep >= 1 &&
                    <div id='section_4' className="app__sectiondata_container_UI">
                        {(mapDrawMode?.mode && mapDrawMode.mode !== null) &&
                            <div className="app__section_data_disabled"></div>
                        }
                        <div className="app__sectiondata_title_container_UI">
                            {window.innerWidth > 1050 &&
                                <div className="app__sectiondata_widget_spacer_15"></div>
                            }
                            <div className="app__sectiondata_title_subcontainer">
                                <div className="app__sectiondata_title_label">{navigation.layout[4]['title_long']}</div>
                                {user?.access?.context === true &&
                                    <div className="app__sectiondata_title_reload">
                                        <img className={`app__sectiondata_title_reload_img ${capacity?.rules?.gpu_data === "fetching" ? "app__sectiondata_title_reload_img_loading" : ""}`} src={ico_reload} alt="" onClick={() => { reloadData("plu") }} />
                                        <div className="app__sectiondata_title_reload_text">Mettre à jour les données externes</div>
                                    </div>
                                }
                            </div>
                            <div className="app__sectiondata_title_bar"></div>
                        </div>
                        <div className="app__sectiondata_body_container_UI">
                            {/* <div className="app__sectiondata_body_container_UI" style={{ minHeight: sectionMinHeight }}> */}
                            {user?.access?.rules === true ?
                                <>
                                    <List capacity={capacity} setCapacity={setCapacity} mapLayers={mapLayers} setMapLayers={setMapLayers} categories={categories} setCategories={setCategories} ruleCatalog_Open={ruleCatalog_Open} setRuleUpdateTracker={setRuleUpdateTracker} highlightedRule={highlightedRule} setHighlightedRule={setHighlightedRule} mapDrawMode={mapDrawMode} setMapDrawMode={setMapDrawMode} hiddenRule={hiddenRule} setHiddenRule={setHiddenRule} />
                                    {window.innerWidth <= 550 &&
                                        <div className="app__sectiondata_widget_spacer_20"></div>
                                    }
                                </>
                                :
                                <div className="app__sectiondata_noaccess_container">
                                    <div className="app__sectiondata_noaccess_container_centered">
                                        <div style={{ fontSize: "26px" }}>😓</div>
                                        <div className="app__sectiondata_noaccess_text">Votre abonnement ne donne pas accès à la fonctionnalité <strong>Urbanisme</strong>.<br /></div>
                                    </div>
                                    <div className="app__sectiondata_noaccess_text_small">Cette fonctionnalité permet :</div>
                                    <div className="app__sectiondata_noaccess_text_small">- un accès direct aux documents d'urbanisme (PLU, PADD, OAP...)</div>
                                    {(capacity?.rules?.gpu_data?.files && Object.keys(capacity?.rules?.gpu_data?.files).length > 0) &&
                                        <div className="app__sectiondata_noaccess_text_note">→ nous avons agrégé {Object.keys(capacity?.rules?.gpu_data?.files).reduce((acc, arr) => acc += capacity.rules.gpu_data.files[arr].length, 0)} documents</div>
                                    }
                                    <div className="app__sectiondata_noaccess_text_small">- l'analyse du zonage PLU du terrain</div>
                                    {(capacity?.rules?.gpu_data?.zoneUrba && capacity?.rules?.gpu_data?.zoneUrba.length > 0) &&
                                        <div className="app__sectiondata_noaccess_text_note">→ nous avons identifié {capacity?.rules?.gpu_data?.zoneUrba.length > 1 ? "les zones" : "la zone"} PLU du terrain</div>
                                    }
                                    <div className="app__sectiondata_noaccess_text_small">- l'utilisation du catalogue de règles PLU permettant d'agir sur le volume constructible</div>
                                    <div className="app__sectiondata_noaccess_text_small">- la création de vos propres règles pour adapter le volume constructible</div>
                                </div>
                            }
                        </div>
                    </div>
                }
                {/* SECTION DATA PART 5 : BUILDABLE */}
                {currentStep >= 1 &&
                    <div id='section_5' className="app__sectiondata_container_UI">
                        {(mapDrawMode?.mode && mapDrawMode.mode !== null) &&
                            <div className="app__section_data_disabled"></div>
                        }
                        <div className="app__sectiondata_title_container_UI">
                            {window.innerWidth > 1050 &&
                                <div className="app__sectiondata_widget_spacer_15"></div>
                            }
                            <div className="app__sectiondata_title_label">{navigation.layout[5]['title_long']}</div>
                            <div className="app__sectiondata_title_bar"></div>
                        </div>
                        <div className="app__sectiondata_body_container_UI">
                            {/* <div className="app__sectiondata_body_container_UI" style={{ minHeight: sectionMinHeight }}> */}
                            {/* <div className="app__sectiondata_loading_container">
                                <div className="app__sectiondata_loading_logo"><img src={logo_card} alt="" /></div>
                                <div className="app__sectiondata_loading_text">Cette section est en cours de développement</div>
                            </div> */}
                            <BuildableDetails capacity={capacity} setCapacity={setCapacity} mapLayers={mapLayers} setMapLayers={setMapLayers} handleToaster={handleToaster} />
                            {window.innerWidth <= 550 &&
                                <div className="app__sectiondata_widget_spacer_5"></div>
                            }
                        </div>
                    </div>
                }
                {/* SECTION DATA PART 6 : COMBINATIONS */}
                {(currentStep >= 1 && capacity?.buildable?.volume?.interpreted && capacity?.buildable?.volume?.interpreted.length > 0 && capacity?.buildable?.volume?.interpreted[0]?.userData) &&
                    <div id='section_6' className="app__sectiondata_container_UI">
                        {(mapDrawMode?.mode && mapDrawMode.mode !== null) &&
                            <div className="app__section_data_disabled"></div>
                        }
                        <div className="app__sectiondata_title_container_UI">
                            {window.innerWidth > 1050 &&
                                <div className="app__sectiondata_widget_spacer_15"></div>
                            }
                            <div className="app__sectiondata_title_label">{navigation.layout[6]['title_long']}</div>
                            <div className="app__sectiondata_title_bar"></div>
                        </div>
                        <div className="app__sectiondata_body_container_UI">
                            {/* <div className="app__sectiondata_body_container_UI" style={{ minHeight: sectionMinHeight }}> */}
                            {user?.access?.simulations === true ?
                                <Combinations capacity={capacity} setCapacity={setCapacity} mapLayers={mapLayers} setMapLayers={setMapLayers} highlightedCombination={highlightedCombination} setHighlightedCombination={setHighlightedCombination} />
                                :
                                <div className="app__sectiondata_noaccess_container">
                                    <div className="app__sectiondata_noaccess_container_centered">
                                        <div style={{ fontSize: "26px" }}>😓</div>
                                        <div className="app__sectiondata_noaccess_text">Votre abonnement ne donne pas accès à la fonctionnalité <strong>Simulation de constructions</strong>.<br /></div>
                                    </div>
                                    <div className="app__sectiondata_noaccess_text_small">Ces données contiennent :</div>
                                    <div className="app__sectiondata_noaccess_text_small">- la génération intelligente de dizaines de constructions dans le volume constructible</div>
                                    <div className="app__sectiondata_noaccess_text_small">- la possibilité de combiner plusieurs simulations</div>
                                    <div className="app__sectiondata_noaccess_text_small">- la possibilité de trier les simulations pour optimiser la surface construite</div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </animated.div>
            {/* SECTION SEPARATOR */}
            <animated.div {...dragSectionSeparator()} style={{ top: sectionSeparatorStyle.top, touchAction: 'none', }} id="section_separator" className="app__sectionseparator">
                <animated.div style={{ height: sectionSeparatorStyle.heightMessageTop }} id="section_separator_messageTop" className={`app__sectionseparator_message ${separatorBounce === "down" ? "app__sectionseparator_message_bounce" : ""}`}><div className="app__sectionseparator_message_anim_top"></div><div>Descendre pour afficher la carte</div></animated.div>
                <div className={`app__sectionseparator_handler ${separatorBounce === "up" ? "app__sectionseparator_handler_bounce_up" : ""} ${separatorBounce === "down" ? "app__sectionseparator_handler_bounce_down" : ""}`}></div>
                <animated.div style={{ height: sectionSeparatorStyle.heightMessageBottom }} id="section_separator_messageBottom" className={`app__sectionseparator_message ${separatorBounce === "up" ? "app__sectionseparator_message_bounce" : ""}`}><div className="app__sectionseparator_message_anim_bottom"></div><div>Monter pour afficher les données</div></animated.div>
            </animated.div>
            {/* SECTION MAP */}
            <animated.div style={{ top: sectionMapStyle.top, height: sectionMapStyle.height, opacity: sectionMapStyle.opacity }} id="section_map" className="app__sectionmap_UI">
                <Geocoder capacity={capacity} setCapacity={setCapacity} isMapExpanded={isMapExpanded} />
                <Map isMapReady={isMapReady} setIsMapReady={setIsMapReady} isMapExpanded={isMapExpanded} setIsMapExpanded={setIsMapExpanded} isHeaderBanner={false} currentSection={currentSection} currentStep={currentStep} capacity={capacity} setCapacity={setCapacity} mapResize={mapResize} setTargetSection={setTargetSection} mapLayers={mapLayers} setMapLayers={setMapLayers} ruleUpdateTracker={ruleUpdateTracker} highlightedRule={highlightedRule} setHighlightedRule={setHighlightedRule} volumeUpdateTracker={volumeUpdateTracker} highlightedCombination={highlightedCombination} setHighlightedCombination={setHighlightedCombination} setErrorPage={setErrorPage} globalUpdateTracker={globalUpdateTracker} mapDrawMode={mapDrawMode} setMapDrawMode={setMapDrawMode} hiddenRule={hiddenRule} setHiddenRule={setHiddenRule} user={user} setHighlightedSitadel={setHighlightedSitadel} handleToaster={handleToaster} />
                <LandsValidation capacity={capacity} setCapacity={setCapacity} isMapExpanded={isMapExpanded} />
            </animated.div>
        </div>
    );
};

export default CapaStudy;