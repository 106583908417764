import React, { useEffect, useState } from 'react';

// Import CSS & assets
import './ExportationAll.css';


const ExportationMain = ({ capacity, setCapacity, handleToaster, setIsExportPDF, setIsExportIFC }) => {

    // SHARE
    const handleShare = () => {
        handleToaster([{ key: "message", status: "info", type: "static", style: "default", title: "Fonctionnalité bientôt disponible", text: "Cette fonctionnalité est ouverte aux utilisateurs bêta, elle sera bientôt disponible publiquement." }]);
        // var newShare = capacity?.share ? { ...capacity?.share } : { isShared: false };
        // newShare.isShared = !newShare.isShared;
        // // Update and save
        // var value = newShare.isShared === true ? "partagée" : "non partagée";
        // var toSaveData = { ...capacity.toSave };
        // if (!toSaveData?.keys) { toSaveData.keys = [] }
        // toSaveData.keys.push("share");
        // toSaveData.autoSave = true;
        // toSaveData.action = "Changement des paramètres de partage de l'étude en ";
        // toSaveData.value = value;
        // setCapacity({ ...capacity, share: newShare, toSave: toSaveData });
    }

    // PDF
    const openExportPDF = () => {
        setIsExportPDF(true);
    }
    
    // IFC
    const openExportIFC = () => {
        setIsExportIFC(true);
    }


    if (capacity?.step > 0) {
        return (
            <>
                <div id="section_0_share" className="app__sectiondata_widget_title">Partage et export
                    <div className="app__sectiondata_beta_tag" style={{ top: '-2px', left: '110px' }} title="Fonctionnalité en mode bêta">beta</div>
                </div>
                <div className="app__exportation_maincontainer">
                    <div className="app__exportation_subcontainer" style={{ opacity: 1 }}>
                        {/* <div className="app__sectiondata_beta_tag">beta</div> */}
                        <div className="app__sectiondata_widget_title">Partager cette étude</div>
                        <div className="app__exportation_toogle_container not_available_clickable">
                            <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="switch_sharing" checked={capacity?.share?.isShared || false} onChange={() => { handleShare() }} /><label className="app__map_layer_checkbox_label" htmlFor="switch_sharing">Toggle</label></div>
                            <div className="app__exportation_toogle_label" title="Seules les personnes avec le lien de partage peuvent consulter l'étude partagée">Partagez cette étude à vos partenaires</div>
                        </div>
                        {capacity?.share?.isShared === true &&
                            <div className="app__exportation_share_box">lien de partage</div>
                        }
                    </div>
                    <div className="app__exportation_submaincontainer">
                        <div className="app__exportation_subcontainer">
                            <div className="app__sectiondata_widget_title">Exporter un rapport PDF</div>
                            <div className="app__exportation_button" onClick={() => { openExportPDF() }}>
                                {/* <img src={ico_file_pdf}></img> */}
                                Export PDF
                            </div>
                        </div>
                        <div className="app__exportation_subcontainer">
                            <div className="app__sectiondata_widget_title">Exporter un modèle IFC</div>
                            <div className="app__exportation_button" onClick={() => { openExportIFC() }}>
                                {/* <img src={ico_file_pdf}></img> */}
                                Export IFC
                            </div>
                        </div>
                    </div>
                </div>
                <div className="app__sectiondata_widget_spacer"></div>
            </>
        );
    }
};

export default ExportationMain;