import React, { useState, useEffect } from 'react';

// Import external functions
import * as ptz_helpers from './zoneABC_helpers';


const ZoneABC = ({ capacity }) => {

    const [zoneDetails, setZonedetails] = useState({ label: "", def: "" });
    useEffect(() => {

        setZonedetails(ptz_helpers.get_zoneabc(capacity?.city?.inseecode));

    }, [capacity]);


    return (
        <>
            {/* ZONE ABC */}
            {(capacity?.city?.inseecode) &&
                <>
                    <div className="app__sectiondata_widget_title">Zone fiscale</div>
                    <div className="app__sectiondata__widget">
                        {/* <div className="app__land_data_detail_value">Défini à l’article D304-1 du code de la construction et de l’habitation, le zonage conventionnellement appelé ABC effectue un « classement des communes du territoire national en zones géographiques en fonction du déséquilibre entre l'offre et de la demande de logements ».</div>
                        <div className="app__sectiondata_widget_spacer_10"></div> */}
                        <div className="app__sectiondata_widget_layout_B" style={{ gap: 10 }}>
                            <div className="app__sectiondata_widget_value_container">
                                <div className="app__sectiondata_widget_value_text_small">Zone {zoneDetails.label}</div>
                                <div className="app__sectiondata_widget_value_legend" style={{ marginTop: '4px' }}>{zoneDetails.def}</div>
                            </div>
                            <div className="app__sectiondata_widget_layout_A_auto_flex1 app__sectiondata_widget_bottom">
                                <div className="app__sectiondata_widget_scale">
                                    <div className={`app__sectiondata_widget_scale_item ${zoneDetails.label === "Abis" ? "app__sectiondata_widget_scale_item_main" : ""}`} style={{ backgroundColor: '#E01B1A', color: '#FFF' }}>
                                        <div className="app__sectiondata_widget_scale_item_title">Zone Abis</div>
                                        <div className="app__sectiondata_widget_scale_item_description">Très tendue</div>
                                    </div>
                                    <div className={`app__sectiondata_widget_scale_item ${zoneDetails.label === "A" ? "app__sectiondata_widget_scale_item_main" : ""}`} style={{ backgroundColor: '#F7A902', color: '#FFF' }}>
                                        <div className="app__sectiondata_widget_scale_item_title">Zone A</div>
                                        <div className="app__sectiondata_widget_scale_item_description">Très tendue</div>
                                    </div>
                                    <div className={`app__sectiondata_widget_scale_item ${zoneDetails.label === "B1" ? "app__sectiondata_widget_scale_item_main" : ""}`} style={{ backgroundColor: '#5E9671', color: '#FFF' }}>
                                        <div className="app__sectiondata_widget_scale_item_title">Zone B1</div>
                                        <div className="app__sectiondata_widget_scale_item_description">Très tendue</div>
                                    </div>
                                    <div className={`app__sectiondata_widget_scale_item ${zoneDetails.label === "B2" ? "app__sectiondata_widget_scale_item_main" : ""}`} style={{ backgroundColor: '#A6CFDF' }}>
                                        <div className="app__sectiondata_widget_scale_item_title">Zone B2</div>
                                        <div className="app__sectiondata_widget_scale_item_description">Tendue</div>
                                    </div>
                                    <div className={`app__sectiondata_widget_scale_item ${zoneDetails.label === "C" ? "app__sectiondata_widget_scale_item_main" : ""}`} style={{ backgroundColor: '#FFF' }}>
                                        <div className="app__sectiondata_widget_scale_item_title">Zone C</div>
                                        <div className="app__sectiondata_widget_scale_item_description">Non tendue</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="app__sectiondata_widget_spacer_10"></div>
                        <div className="app__land_data_detail_value">Défini à l’article D304-1 du code de la construction et de l’habitation, le zonage conventionnellement appelé ABC effectue un « classement des communes du territoire national en zones géographiques en fonction du déséquilibre entre l'offre et de la demande de logements ».</div> */}
                    </div>
                    <div className="app__sectiondata_widget_spacer_10"></div>
                    <div className="app__sectiondata_widget_info_container">
                        <div className="app__sectiondata_widget_info_text">
                            Source : Ministère de la Transition écologique et de la Cohésion des territoires
                        </div>
                        <div className="app__sectiondata_widget_info_icon">i</div>
                    </div>
                    <div className="app__sectiondata_widget_spacer"></div>
                </>
            }
        </>
    );
};

export default ZoneABC;