import React, { useState, useEffect } from 'react';

const TitleAndDescription = ({ capacity, setCapacity }) => {

    // Title
    const handleTitleChange = (e) => {
        // setCapacity({ ...capacity, title: e.target.value, toSave: { autoSave: false, action: "", value: "", keys: [] } });
    }
    const handleTitleBlur = (e) => {
        if (e.target.value !== capacity.title) {
            var toSaveData = { ...capacity.toSave };
            if (!toSaveData?.keys) { toSaveData.keys = [] }
            toSaveData.keys.push("title");
            toSaveData.autoSave = true;
            toSaveData.action = "Modification du titre par";
            toSaveData.value = e.target.value;
            setCapacity({ ...capacity, title: e.target.value, toSave: toSaveData });
        }
    }

    // Description
    const handleDescriptionChange = (e) => {
        // setCapacity({ ...capacity, description: e.target.value, toSave: { autoSave: false, action: "", value: "", keys: [] } });
    }
    const handleDescriptionBlur = (e) => {
        if (e.target.value !== capacity.description) {
            var toSaveData = { ...capacity.toSave };
            if (!toSaveData?.keys) { toSaveData.keys = [] }
            toSaveData.keys.push("description");
            toSaveData.autoSave = true;
            toSaveData.action = "Modification de la description par";
            toSaveData.value = e.target.value;
            setCapacity({ ...capacity, description: e.target.value, toSave: toSaveData });
        }
    }
    const descriptionResize = (element) => {
        element.rows = "1";
        element.rows = Math.ceil(element.scrollHeight / 20);
    }
    useEffect(() => {
        descriptionResize(document.getElementById("description"));
    }, [capacity]);

    return (
        <>
            <div className="app__sectiondata__widget">
                <div className="app__sectiondata_widget_title">Titre</div>
                <input className="app__sectiondata_widget_inputSingleLine app__sectiondata_widget_H1" type="text" id="title" name="title" defaultValue={capacity?.title} placeholder="Veuillez entrer un titre" required onChange={handleTitleChange} onBlur={handleTitleBlur}></input>
                <div className="app__sectiondata_widget_spacer"></div>
                <div className="app__sectiondata_widget_title">Description</div>
                <textarea className="app__sectiondata_widget_inputMultipleLine" id="description" name="description" defaultValue={capacity?.description} placeholder="Vous pouvez entrer une description de l'étude capacitaire" rows="1" onChange={handleDescriptionChange} onBlur={handleDescriptionBlur}></textarea>
            </div>
            <div className="app__sectiondata_widget_spacer"></div>
        </>
    );
};

export default TitleAndDescription;